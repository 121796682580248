export function extractKeywordsFromQuery(
  query: string,
  keywordsToExtract: Array<string>,
): [string[], string] {
  const extractedKeywords = query.match(
    new RegExp(keywordsToExtract.join('|'), 'ig'),
  );
  const keywords = [...new Set(extractedKeywords)];
  const parsedQuery = query
    .replace(new RegExp(keywordsToExtract.join('|'), 'ig'), '')
    .trim();
  return [keywords, parsedQuery];
}
