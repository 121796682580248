import { ReactElement } from 'react';
import { Location, useLocation, useParams } from 'react-router-dom';

// We used this to allow us to upgrade react-router.
// Ideally, we should not use it for new code.
export function WithRouterParams<
  TRouteParams extends Record<string, string>,
  TLocationState,
>({
  render,
}: {
  render: (
    params: TRouteParams,
    location: Location<TLocationState | null>,
  ) => ReactElement;
}): ReactElement {
  const params = useParams<TRouteParams>() as TRouteParams;
  const location = useLocation<TLocationState>();
  return render(params, location);
}
