import styled from 'styled-components';

import {
  colors,
  cssBreakpoints,
  fontWeights,
  fonts,
  transition,
} from 'theme/theme';

export const StyledSelect = styled.select<{
  $small?: boolean;
  $hasError?: boolean;
  $optionCount?: number;
}>`
  width: 100%;
  height: 45px;
  padding-left: 1em;
  padding-right: 36px;
  border: solid 1px ${colors.elementGrey};
  color: ${colors.darkContentGrey};
  background-color: white;
  border-radius: 8px;
  appearance: none;
  font-size: 16px;
  font-family: ${fonts.sans};
  transition: all ${transition};

  &:disabled + div {
    display: none;
  }

  &:focus {
    border-color: ${colors.brandBlue};
    box-shadow: 0 0 0 1px ${colors.brandBlue} inset;
    outline: 0;
  }

  ${(props) =>
    props.$hasError &&
    `
    border-color: ${colors.alertRed};
    box-shadow: 0 0 0 1px ${colors.alertRed};
  `}
  &::-ms-expand {
    display: none;
  }

  option {
    color: ${colors.mediumContentGrey};
  }
  ${(props) =>
    props.$small &&
    `
  height: 36px;
  padding-right: 48px;
  padding-left: 12px;
  background: transparent;
  color: ${colors.lightContentGrey};
  font-size: 16px;
  font-weight: ${fontWeights.semiBold};
  `}

  ${(props) =>
    props.$optionCount === 1
      ? `&[disabled] {
        background-color: ${colors.white};
        opacity: 1;
    }`
      : undefined}

  @media all and (min-width: ${cssBreakpoints.smUp}) {
    font-size: 18px;
  }
`;
