import { levelOptionTitles } from 'modules/search/helpers/sidefilters';
import { SearchFacetData } from 'modules/search/types/SearchFacetData';
import { EDUCATION_LEVELS } from 'utils/constants/general/educationLevels';

export const educationFacet = {
  id: 'educationFacet',
  name: 'education',
  title: getText('Education Level'),
  type: 'array',
  options: EDUCATION_LEVELS.map((educationLevel) => educationLevel.id),
  optionTitles: levelOptionTitles(EDUCATION_LEVELS),
  sort: false,
} as const satisfies SearchFacetData;
