import { ReactNode, useRef } from 'react';
import styled from 'styled-components';
// eslint-disable-next-line import/no-namespace
import * as SS from 'styled-system';

import { useTrackLinks } from 'hooks/useTrackLinks';
import { textStyles } from 'theme/theme';

import { StyleProps } from './StyleProps';
import { cleanTextProps } from './cleanTextProps';
import { useLinksHaveUTMParams } from './useLinksHaveUTMParams';

type Props = {
  id?: string;
  tag?: string;
  styleAs?: keyof typeof textStyles;
  children?: ReactNode;
  analyticsData?: {
    eventName: string;
    eventProperties?: Record<string, unknown>;
  };
  dangerouslySetInnerHTML?: { __html: string };
  className?: string;
  title?: string;
};

export const Text = styled(
  ({ tag = 'div', analyticsData, ...props }: Props) => {
    const ref = useRef<HTMLElement>(null);
    useTrackLinks({
      ref,
      eventName: analyticsData?.eventName,
      eventProperties: analyticsData?.eventProperties,
    });
    useLinksHaveUTMParams({ ref });

    const otherProps = cleanTextProps(props);

    const Tag = tag;
    // TODO: Fix this the next time the file is edited.
    // @ts-expect-error TS(2322): Type '{ ref: MutableRefObject<HTMLElement | null |... Remove this comment to see the full error message
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Tag ref={ref} {...otherProps} />;
  },
)<
  {
    $whiteSpace?: string;
    $textOverflow?: string;
    $textDecoration?: string;
    $textTransform?: string;
    $lineClamp?: number;
  } & StyleProps
>`
  ${(props) =>
    props.$textOverflow ? `text-overflow: ${props.$textOverflow}` : ''};
  ${(props) =>
    props.$textTransform ? `text-transform: ${props.$textTransform}` : ''};
  ${(props) =>
    props.$textDecoration ? `text-decoration: ${props.$textDecoration}` : ''};
  ${(props) => (props.$whiteSpace ? `white-space: ${props.$whiteSpace}` : '')};

  ${(props) => props.styleAs && textStyles[props.styleAs]}
  ${(props) =>
    props.$lineClamp &&
    `
    /* stylelint-disable-next-line value-no-vendor-prefix */
    display: -webkit-box;
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-line-clamp: ${props.$lineClamp};
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-box-orient: vertical;
    overflow: hidden;
`}
  ${SS.space}
  ${SS.overflow}
  ${SS.color}
  ${SS.opacity}
  ${SS.fontFamily}
  ${SS.fontSize}
  ${SS.fontWeight}
  ${SS.fontStyle}
  ${SS.letterSpacing}
  ${SS.textAlign}
  ${SS.lineHeight}
  ${SS.borders}
  ${SS.borderColor}
  ${SS.display}
  ${SS.verticalAlign}
  ${SS.shadow}
`;
