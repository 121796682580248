import { ReactNode } from 'react';
import { useLoaderData } from 'react-router-dom';

type Props<TData> = {
  render: (data: TData) => ReactNode;
};

export function RenderLoaderComponent<TData>({ render }: Props<TData>) {
  const loaderData = useLoaderData() as TData;
  return render(loaderData);
}
