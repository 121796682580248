import { levelOptionTitles } from 'modules/search/helpers/sidefilters';
import { SearchFacetData } from 'modules/search/types/SearchFacetData';
import { ORG_TYPES } from 'utils/constants/general/orgTypes';

export const orgTypeFacet = {
  id: 'orgTypeFacet',
  name: 'orgType',
  title: getText('Org Type'),
  type: 'array',
  options: ORG_TYPES.map((orgType) => orgType.id),
  optionTitles: levelOptionTitles(ORG_TYPES),
  sort: false,
} as const satisfies SearchFacetData;
