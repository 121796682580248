export const ORG_TYPE_URL_SEGMENTS_BY_LOCALE = {
  en: [
    'nonprofit',
    'government',
    'consultant',
    'social-enterprise',
    'recruiter',
    'business',
    'community',
  ],
  es: [
    'ong',
    'gobierno',
    'consultor',
    'empresa-social',
    'agencia-de-contratacion',
    'empresa',
    'colectivo-ciudadano',
  ],
  pt: [
    // 'ong', already in es
    'governo',
    'consultoria',
    'empreendedorismo-social',
    'recrutador',
    // 'empresa', already in es
    'coletivos',
  ],
} as const satisfies Record<UserLocale, readonly string[]>;

export const ORG_TYPE_URL_SEGMENTS = Object.values(
  ORG_TYPE_URL_SEGMENTS_BY_LOCALE,
).flat();
