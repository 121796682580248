import { RefObject, useEffect } from 'react';

type Args = {
  detailsElRef: RefObject<HTMLDetailsElement>;
  onToggle: (open: boolean) => void;
};

export function useDetailsToggleObserver({ detailsElRef, onToggle }: Args) {
  useEffect(() => {
    const detailsEl = detailsElRef.current;
    if (!detailsEl) return;

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName !== 'open') return;
        onToggle(detailsEl.hasAttribute('open'));
      });
    });

    observer.observe(detailsEl, { attributes: true });

    return () => observer.disconnect();
  }, [detailsElRef, onToggle]);
}
