import { ComponentProps } from 'react';

import { textStyles } from 'theme/theme';

import { Text } from './Text';

type Args = {
  styleAs: keyof typeof textStyles;
  tag: string;
};

export function createTextComponent(shortcut: Args) {
  function TextShortcut(props: ComponentProps<typeof Text>) {
    return (
      <Text
        tag={shortcut.tag}
        styleAs={shortcut.styleAs}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    );
  }

  return TextShortcut;
}
