import {
  ApiEnvironment,
  ApiUser,
} from 'api/userEnvironment/types/ApiUserEnvironment';
import { getUserEnvironment } from 'zustand-stores/userEnvironmentStore';

export function renderingGetClientPreloadedUserEnvironment(): {
  user: ApiUser | null;
  environment: ApiEnvironment | null;
} {
  const { initUserEnvironment } = window.Idealist;
  if (initUserEnvironment) return initUserEnvironment;

  const { user, environment } = getUserEnvironment();
  return { user, environment };
}
