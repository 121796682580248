import loadable from '@loadable/component';

export const ViewListingApplicationPageContainerLoadable = loadable(
  () =>
    import(
      /* webpackChunkName: "orgDashboard/ViewListingApplicationPageContainer" */
      './ViewListingApplicationPageContainer'
    ),
  { resolveComponent: (module) => module.ViewListingApplicationPageContainer },
);
