import { createContext, useContext } from 'react';

import { OrgDashboardApiOrg } from 'modules/orgDashboard/api/types/OrgDashboardApiOrg';

export const OrgDashboardOrgContext = createContext<
  OrgDashboardApiOrg | undefined
>(undefined);

export const useOrgDashboardOrgContext = () =>
  useContext(OrgDashboardOrgContext);
