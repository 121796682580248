export function isEmpty<
  T extends object | Array<unknown> | string | null | undefined,
>(value: T): boolean {
  if (!value) return true;

  if (
    typeof value === 'string' ||
    Array.isArray(value) ||
    (typeof value === 'object' && 'length' in value)
  )
    return value.length === 0;

  throw new Error('got unexpected value in isEmpty');
}

export function isNotEmpty<
  T extends object | Array<unknown> | string | null | undefined,
>(value: T): boolean {
  return !isEmpty(value);
}
