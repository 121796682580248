import { SearchFacet } from 'modules/search/types/SearchFacet';

import { internshipKeywordsFacet } from './searchFacets/internshipKeywordsFacet';
import { localeFacet } from './searchFacets/localeFacet';
import { locationTypeFacet } from './searchFacets/locationTypeFacet';
import { orgAreasOfFocusFacet } from './searchFacets/orgAreasOfFocusFacet';
import { orgTypeFacet } from './searchFacets/orgTypeFacet';
import { paymentFacet } from './searchFacets/paymentFacet';
import { recencyFacet } from './searchFacets/recencyFacet';

export const searchFacetsInternship = [
  locationTypeFacet,
  internshipKeywordsFacet,
  orgAreasOfFocusFacet,
  orgTypeFacet,
  paymentFacet,
  localeFacet,
  recencyFacet,
] satisfies SearchFacet[];
