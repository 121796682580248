import queryString from 'query-string';

import { trackUserEvent, utmSourcesToTrack } from 'api/trackedUserEvents';

export function trackReferrals() {
  const queryParams = queryString.parse(window.location.search);
  const utmSource = queryParams.utm_source;
  const utmSources = Array.isArray(utmSource) ? utmSource : [utmSource];
  utmSources.forEach((u) => {
    // @ts-expect-error TS(2538): Type 'null' cannot be used as an index type.
    switch (utmSourcesToTrack[u]) {
      case 'JAZZHR_REFERRAL':
        trackUserEvent({
          eventName: 'JAZZHR_REFERRAL',
          extraData: {
            url: window.location.href,
          },
        });
        break;

      default:
        break;
    }
  });
  const utmCampaign = queryParams.utm_campaign;
  const utmCampaigns = Array.isArray(utmCampaign) ? utmCampaign : [utmCampaign];
  const isCampaignIdealist = typeof queryParams.idealist !== 'undefined';

  utmCampaigns.forEach((campaign) => {
    // TODO: Remove hardcoded campaign after deploy
    if (isCampaignIdealist || campaign === '2021-health-medicine') {
      trackUserEvent({
        eventName: 'CAMPAIGN',
        extraData: {
          utmCampaign: campaign,
          url: window.location.href,
        },
      });
    }
  });
}
