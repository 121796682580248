import { REMOTE_KEYWORDS } from 'utils/constants/search';
import { truncateToUTF8Bytes } from 'utils/string/truncateToUTF8Bytes';

import { extractKeywordsFromQuery } from './extractKeywordsFromQuery/extractKeywordsFromQuery';

type Args = {
  query: string;
};

export function getParsedSearchQuery({ query }: Args): {
  parsedQuery: string;
  locationType: ['REMOTE'] | undefined;
  remoteKeywords: string[];
} {
  const [remoteKeywords, queryWithoutKeywords] = extractKeywordsFromQuery(
    query,
    REMOTE_KEYWORDS[CURRENT_LOCALE],
  );

  const withRemoteLocationTypeFacet = remoteKeywords.length > 0;

  const truncatedQueryWithoutKeywords = truncateToUTF8Bytes(
    queryWithoutKeywords,
    512,
  );

  return {
    parsedQuery: truncatedQueryWithoutKeywords,
    locationType: withRemoteLocationTypeFacet ? ['REMOTE'] : undefined,
    remoteKeywords,
  };
}
