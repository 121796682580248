import { useCallback } from 'react';

import { searchGetTrackingData } from 'modules/search/helpers/analytics/searchGetTrackingData';
import { getParsedSearchQuery } from 'modules/search/helpers/getParsedSearchQuery';
import { SearchPageLocationState } from 'modules/search/pages/Search/types/SearchPageLocationState';
import { SearchSortType } from 'modules/search/types/SearchSortType';
import {
  clearSearchLocation,
  getSearchLocation,
  getSearchPageIndex,
  getSearchQuery,
  getSearchRadius,
  getSearchSort,
} from 'modules/search/zustand-stores/searchStore';
import { trackEvent } from 'utils/analytics/track/trackEvent';

import { useSearchPageActionsHelpers } from './useSearchPageActionsHelpers';

type Args = {
  isInSearchLandingPage: boolean;
};

export function useSearchPageActions({ isInSearchLandingPage }: Args) {
  const { updateQueryParams, resetQueryParams } = useSearchPageActionsHelpers({
    isInSearchLandingPage,
  });

  // Navigate
  const navigateToDerivedUrlFromState = useCallback(() => {
    updateQueryParams({});
  }, [updateQueryParams]);

  // Query
  const setSearchUrlQueryOrLocationChanged = useCallback(
    (
      data: { query?: string; triggeredByRemoteLocationChange: boolean },
      locationState?: { searchFrom?: string },
    ) => {
      const queryParamsToUpdate: {
        q?: string;
        locationType?: ['REMOTE'];
        page: undefined;
        radius?: undefined;
      } = {
        page: undefined,
      };

      const composedLocationState: SearchPageLocationState = {
        ...locationState,
      };

      if (typeof data.query === 'string') {
        const { parsedQuery, locationType, remoteKeywords } =
          getParsedSearchQuery({ query: data.query });

        if (parsedQuery !== getSearchQuery()) {
          trackEvent('Changed Search Query', {
            ...searchGetTrackingData({}),
            new_query: data.query,
          });

          queryParamsToUpdate.q = parsedQuery;
          if (locationType) queryParamsToUpdate.locationType = locationType;

          composedLocationState.remoteKeywords = remoteKeywords;
        }
      }

      if (data.triggeredByRemoteLocationChange)
        queryParamsToUpdate.locationType = ['REMOTE'];

      if (!getSearchLocation()?.text) queryParamsToUpdate.radius = undefined;

      updateQueryParams(queryParamsToUpdate, composedLocationState);
    },
    [updateQueryParams],
  );

  // Page index
  const setSearchUrlPageIndex = useCallback(
    (pageIndex: number) => {
      if (pageIndex === getSearchPageIndex()) return;

      updateQueryParams({ page: pageIndex === 0 ? undefined : pageIndex + 1 });

      trackEvent('Changed Search Page Number', {
        ...searchGetTrackingData({}),
        new_page: pageIndex,
      });
    },
    [updateQueryParams],
  );

  // Sort
  const setSearchUrlSort = useCallback(
    (sortParam: SearchSortType) => {
      if (getSearchSort() === sortParam) return;

      updateQueryParams({
        pageIndex: undefined,
        sort: sortParam === 'relevance' ? undefined : 'newest',
      });

      trackEvent('Changed Search Sort', {
        ...searchGetTrackingData({}),
        sort_order: sortParam,
      });
    },
    [updateQueryParams],
  );

  // Radius
  const setSearchUrlRadius = useCallback(
    (radius: string) => {
      if (getSearchRadius() === radius) return;

      updateQueryParams({ radius: radius === 'auto' ? undefined : radius });

      trackEvent('Changed Search Radius', {
        ...searchGetTrackingData({}),
        radius,
      });
    },
    [updateQueryParams],
  );

  const clearSearchUrl = useCallback(() => {
    clearSearchLocation(true);
    resetQueryParams({
      // Reset everything, except the search query
      q: getSearchQuery(),
    });
  }, [resetQueryParams]);

  return {
    navigateToDerivedUrlFromState,
    setSearchUrlQueryOrLocationChanged,
    setSearchUrlPageIndex,
    setSearchUrlSort,
    setSearchUrlRadius,
    clearSearchUrl,
  };
}
