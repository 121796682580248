import { ORG_TYPE_URL_SEGMENTS } from 'modules/listing/routing/org/org.route-segments';

export const JOB_URL_SEGMENTS_BY_LOCALE = {
  en: 'job',
  es: 'empleo',
  pt: 'emprego',
} as const satisfies Record<UserLocale, string>;

export const JOB_URL_SEGMENTS = Object.values(JOB_URL_SEGMENTS_BY_LOCALE);

export const combinedOrgTypeAndJobUrlSegments = ORG_TYPE_URL_SEGMENTS.flatMap(
  (orgTypeSegment) =>
    Object.values(JOB_URL_SEGMENTS).map(
      (jobUrlSegment) => `${orgTypeSegment}-${jobUrlSegment}`,
    ),
);
