import invariant from 'invariant';

import { getIn } from 'utils/functional/object';

let config: Record<string, unknown>;

export function configValue(...path: string[]): string | null | undefined {
  return getIn(config, path);
}

export function requiredConfig<T>(...path: Array<string>): T {
  const value = getIn(config, path);
  invariant(value, `Config value ${path.toString()} is required`);

  return value as T;
}

export function configString(...path: string[]): string {
  const value = requiredConfig(...path);
  return typeof value === 'string' ? value : '';
}

export function setConfigOnce(newConfig: Record<string, unknown>) {
  if (!config) {
    config = newConfig;
  }
}
