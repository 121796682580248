import queryString from 'query-string';

import { log } from 'utils/logging';

export function getQueryParamStringArray(
  search: string,
  param: string,
): string[] {
  const parsed = queryString.parse(search);
  const parsedParam = parsed[param];
  if (typeof parsedParam === 'string') {
    return [parsedParam];
  }
  if (typeof parsedParam === 'undefined' || parsedParam === null) {
    return [];
  }
  if (Array.isArray(parsedParam)) {
    return parsedParam.filter((value) => value !== null) as string[];
  }
  // Sanity check, should never happen
  log.error(
    'parsedParam was expected to be an Array at that point. ' +
      'Actual value was ',
    JSON.stringify(parsedParam),
  );
  return parsedParam as string[];
}
