import { SearchFacet, SearchFacetId } from 'modules/search/types/SearchFacet';

/**
 * This is a "shortcut" to avoid having to include all the facets in the bundle
 * in places where that's not necessary
 */
export const searchFacetNamesById: {
  [key in SearchFacetId]: Extract<SearchFacet, { id: key }>['name'];
} = {
  locationTypeFacet: 'locationType',
  employmentTypeFacet: 'jobType',
  paymentFacet: 'payment',
  educationFacet: 'education',
  actionTypeFacet: 'actionType',
  professionalLevelFacet: 'professionalLevel',
  localeFacet: 'locale',
  actionDateFacet: 'actionDate',
  orgTypeFacet: 'orgType',
  jobKeywordsFacet: 'functions',
  internshipKeywordsFacet: 'functions',
  orgAreasOfFocusFacet: 'areasOfFocus',
  jobFamilyFacet: 'synonymId',
  recencyFacet: 'recency',
  statusFacet: 'status',
  volopKeywordsFacet: 'functions',
  volopAreasOfFocusFacet: 'areasOfFocus',
  volopSourceFacet: 'source',
  eventAreasOfFocusFacet: 'areasOfFocus',
  eventSourceFacet: 'source',
};
