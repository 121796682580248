import { useNavigation } from 'react-router-dom';

import {
  startRouteLoadingAnimation,
  stopRouteLoadingAnimation,
} from 'utils/ui/routeLoadingAnimation';

export function useTrackNavigationState() {
  const { state } = useNavigation();
  if (typeof window !== 'undefined') {
    const isLoading = state === 'loading';
    window.loadingRoute = isLoading;

    if (isLoading) {
      startRouteLoadingAnimation();
    } else {
      stopRouteLoadingAnimation();
    }
  }
}
