import { Component, ReactNode } from 'react';

import { ErrorPageLoadable } from 'pages/Error/ErrorPageLoadable';
import { NotFoundPageLoadable } from 'pages/NotFound/NotFoundPageLoadable';
import { log } from 'utils/logging';
import { trackError } from 'utils/trackError';
import { useError } from 'zustand-stores/errorStore';

type OwnProps = {
  children: ReactNode;
};

type Props = OwnProps & { error: Error | null };

type State = {
  error: Error | null;
};

class UnboundErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(
    error: Error,
    info: {
      componentStack: string;
    },
  ) {
    this.setState({ error });

    log.error(`stacktrace:\n${info.componentStack}`);
    trackError(error);
  }

  render() {
    const { error: stateError } = this.state;
    const { children, error: propError } = this.props;

    const error = stateError || propError;

    if (error) {
      if ('statusCode' in error && error.statusCode === 404) {
        return <NotFoundPageLoadable />;
      }

      return <ErrorPageLoadable error={error} />;
    }

    return children;
  }
}

export function ErrorBoundary({ children }: OwnProps) {
  const { error } = useError();
  return <UnboundErrorBoundary error={error}>{children}</UnboundErrorBoundary>;
}
