import interpolateComponents from '@automattic/interpolate-components';

import { Box } from 'components/Box/Box';
import { StyledNativeLinkWithRef } from 'components/Link/StyledNativeLinkWithRef';
import { H1 } from 'components/Text/H1';
import { configValue } from 'config/appConfigUtils';

type Props = {
  error: Error;
};

export function ErrorSection({ error }: Props) {
  return (
    <>
      <H1>
        {
          /* TRANSLATORS: error page title */
          getText('Something Went Wrong')
        }
      </H1>

      <Box mt={3}>
        {interpolateComponents({
          mixedString:
            /* TRANSLATORS: error page title */
            getText(
              "We're sorry, something went wrong. Please try again and if the problem persists, please {{link}}contact us{{/link}}.",
            ),
          components: {
            link: (
              <StyledNativeLinkWithRef href={getText('/en/about/contact')} />
            ),
          },
        })}
      </Box>
      {configValue('idealist', 'devStackTrace') && error && (
        <Box mt={3} color="alertRed">
          {error.toString()}
          {error.stack}
        </Box>
      )}
    </>
  );
}
