import { useCallback, useRef } from 'react';

import { Button } from 'components/Button/Button';
import { closeDropdownFromElementWithin } from 'components/Dropdown/closeDropdownFromElementWithin';
import { LocationInput } from 'components/LocationInput/LocationInput';
import { UncontrolledSearchQueryInputContainer } from 'containers/UncontrolledSearchQueryInputContainer';
import { UncontrolledListingTypeSelect } from 'modules/listing/components/ListingTypeSelect/UncontrolledListingTypeSelect';
import { searchTypes } from 'modules/search/constants/searchTypes';
import { usePopularSearches } from 'modules/search/hooks/usePopularSearches';
import { SearchLocation } from 'modules/search/types/SearchLocation';
import { SearchType } from 'modules/search/types/SearchType';
import { scrollToTop } from 'utils/scroll';
import { useUserEnvironment } from 'zustand-stores/userEnvironmentStore';

import {
  PageHeaderSearchDesktopInputsForm,
  PageHeaderSearchInputsDesktopFieldsHolder,
  PageHeaderSearchInputsDesktopListingTypeSelectHolder,
  PageHeaderSearchInputsDesktopLocationFooter,
  PageHeaderSearchInputsDesktopQueryInputHolder,
} from './PageHeaderSearchDesktopInputs.styled';

type Props = {
  defaultSearchType: SearchType;
  initialQuery: string;
  searchLocation: SearchLocation | undefined;
  onChangeSearchLocation: (
    newSearchLocation: SearchLocation | undefined,
  ) => boolean;
  onSearch: (data: {
    listingType: SearchType;
    query: string;
    triggeredByRemoteLocationChange: boolean;
  }) => void;
  searchOnClear: boolean;
};

export function PageHeaderSearchInputsDesktop({
  defaultSearchType,
  initialQuery,
  searchLocation,
  onChangeSearchLocation,
  onSearch,
  searchOnClear,
}: Props) {
  const { isEnvironmentLoaded } = useUserEnvironment();

  const {
    popularSearches,
    listingTypeForPopularSearches,
    setListingTypeForPopularSearches,
  } = usePopularSearches(defaultSearchType);

  const formRef = useRef<HTMLFormElement>(null);
  const submit = useCallback(
    (options: { triggeredByRemoteLocationChange: boolean }) => {
      const form = formRef.current;
      if (!form) return;

      const formData = new FormData(form);
      onSearch({
        query: formData.get('q') as string,
        listingType: formData.get('listing-type') as SearchType,
        triggeredByRemoteLocationChange:
          options.triggeredByRemoteLocationChange,
      });

      closeDropdownFromElementWithin(form);
    },
    [onSearch],
  );

  return (
    <PageHeaderSearchDesktopInputsForm
      ref={formRef}
      onSubmit={(event) => {
        event.preventDefault();
        submit({ triggeredByRemoteLocationChange: false });
      }}
      data-qa-id="search-inputs"
    >
      <PageHeaderSearchInputsDesktopFieldsHolder>
        <PageHeaderSearchInputsDesktopListingTypeSelectHolder>
          <UncontrolledListingTypeSelect
            // Reset component when initial value changes
            key={defaultSearchType}
            name="listing-type"
            defaultValue={defaultSearchType}
            searchTypes={searchTypes}
            variant="grey"
            onChange={(newListingType) =>
              setListingTypeForPopularSearches(newListingType)
            }
          />
        </PageHeaderSearchInputsDesktopListingTypeSelectHolder>

        <PageHeaderSearchInputsDesktopQueryInputHolder>
          <UncontrolledSearchQueryInputContainer
            initialQuery={initialQuery}
            placeholder={getText('Search by keyword, skill, or interest')}
            onClearQuery={
              searchOnClear
                ? () => submit({ triggeredByRemoteLocationChange: false })
                : undefined
            }
            presentation="contrast"
            id="search-query"
            qaId="search-input"
            popularSearches={popularSearches}
            selectedType={listingTypeForPopularSearches}
          />
        </PageHeaderSearchInputsDesktopQueryInputHolder>

        <LocationInput
          affixVariant="transparent"
          styleVariant="contrast"
          hasRemoteOptions
          showLocationIcon
          showClearButton
          placeholder={getText('Everywhere')}
          locationText={searchLocation?.text}
          onChange={(newLocation) => {
            // Avoid submitting twice - the LocationInput has a bug that triggers the onChange twice
            if (!onChangeSearchLocation(newLocation)) return;

            // Trigger search when search location changes
            submit({
              triggeredByRemoteLocationChange: Boolean(newLocation?.isRemote),
            });
          }}
        />
      </PageHeaderSearchInputsDesktopFieldsHolder>

      <PageHeaderSearchInputsDesktopLocationFooter>
        <Button
          type="submit"
          variant="primary"
          icon={{ type: 'normalized', name: 'search' }}
          data-qa-id="search-button"
          data-ready={isEnvironmentLoaded}
          onClick={scrollToTop}
          height={48}
          fullWidth
        >
          {getText('Search')}
        </Button>
      </PageHeaderSearchInputsDesktopLocationFooter>
    </PageHeaderSearchDesktopInputsForm>
  );
}
