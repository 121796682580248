import { onClientHydrated } from './renderingState';

let ssrApiData: Record<string, unknown> | undefined;
const listeners: Array<() => void> = [];

let fired = false;

// Actions

export function setSsrApiData(data: Record<string, unknown>) {
  ssrApiData = data;

  listeners.forEach((listener) => listener());
  fired = true;
}

// Getters

export function onSsrApiDataSet(fn: () => void) {
  if (ssrApiData) {
    fn();
    return;
  }

  if (!fired) listeners.push(fn);
}

export function findSsrApiDataUrl(
  fn: (url: string, data: unknown) => boolean,
): string | undefined {
  const data = ssrApiData;
  if (!data) return;

  const entries = Object.entries(data);

  for (let i = 0; i < entries.length; i++) {
    const [url, dataForUrl] = entries[i];
    if (fn(url, dataForUrl)) return url;
  }
}

export function getSsrApiDataForUrl<TData>(
  url: string | undefined,
): TData | undefined {
  if (!url) return;

  return ssrApiData?.[url] as TData | undefined;
}

// Event listeners

onClientHydrated(() => {
  // Erase data to avoid caching between page changes
  ssrApiData = undefined;
});
