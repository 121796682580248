import 'PublicPath';

import { renderSsrClient } from 'rendering/client/renderSsrClient';

import { App } from './app';
import { routeObjects } from './routing/route-objects';

renderSsrClient({
  Component: App,
  routeObjects,
});
