import { localizedRouteGroup, routeGroup } from 'routing/routes';

export const savedSearchesRoute = routeGroup.route(
  'saved-searches/:savedSearchId',
);

export const searchJobsRouteReference = localizedRouteGroup.route(':segment', [
  'areasOfFocus',
  'functions',
  'locationType',
  'professionalLevel',
  'education',
  'jobType',
  'orgType',
  'locale',
  'radius',
  'recency',
  'synonymId',
  'q',
  'page',
  'sort',
]);

export const searchInternshipsRouteReference = localizedRouteGroup.route(
  ':segment',
  [
    'areasOfFocus',
    'functions',
    'locationType',
    'jobType',
    'orgType',
    'locale',
    'radius',
    'recency',
    'payment',
    'q',
    'synonymId',
    'page',
    'sort',
  ],
);

export const searchVolopsRouteReference = localizedRouteGroup.route(
  ':segment',
  [
    'areasOfFocus',
    'functions',
    'locationType',
    'locale',
    'radius',
    'recency',
    'actionType',
    'source',
    'startsLT',
    'endsGT',
    'q',
    'page',
    'sort',
  ],
);

export const searchEventsRouteReference = localizedRouteGroup.route(
  ':segment',
  [
    'areasOfFocus',
    'functions',
    'locationType',
    'locale',
    'radius',
    'recency',
    'actionType',
    'source',
    'startsLT',
    'endsGT',
    'q',
    'page',
    'sort',
  ],
);

export const searchOrgRouteReference = localizedRouteGroup.route(':segment', [
  'areasOfFocus',
  'orgType',
  'locale',
  'q',
  'page',
  'sort',
]);
