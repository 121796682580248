import type { LoaderFunctionArgs } from '@remix-run/router/utils';
import { ReactNode } from 'react';

import { RenderLoaderComponent } from './RenderLoaderComponent';

type Args<TData, TLoaderArgs extends LoaderFunctionArgs> = {
  loader: (args: TLoaderArgs) => Promise<TData> | TData;
  render: (data: TData) => ReactNode;
};

/**
 * Create a `{ loader, element }` object that ties both together.
 *
 * This ensures that the types returned by the loader are the same being
 * consumed by the page component and provides an easy path to switch(...)
 * when a route can have more than one page (such as published/unpublished/not found)
 */
export function renderLoader<TData, TLoaderArgs extends LoaderFunctionArgs>({
  loader,
  render,
}: Args<TData, TLoaderArgs>) {
  return {
    loader,
    element: <RenderLoaderComponent render={render} />,
  };
}
