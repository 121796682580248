import { useModal } from 'zustand-stores/modalStore';

import { ModalByTypeLoadable } from './ModalByTypeLoadable';

export function ModalRoot() {
  const { modal } = useModal();
  if (!modal) return null;

  return <ModalByTypeLoadable type={modal.type} modalProps={modal.props} />;
}
