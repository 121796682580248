export const LISTING_TYPE_HUMAN_NAME_MAP_SEARCH = {
  ALL: getText('Everything'),
  JOB: getText('Jobs'),
  INTERNSHIP: getText('Internships'),
  VOLOP: getText('Volunteer Opportunities'),
  EVENT: getText('Events'),
  ORG: getText('Organizations'),
  USER: getText('Users'),
  INVOICE: getText('Invoices'),
  PAYMENT: getText('Payments'),
  DONATION: getText('Donations'),
  CMS_PAGE: getText('Articles'),
};
