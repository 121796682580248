import { isNotEmpty } from 'utils/functional/poly';

// http://stackoverflow.com/a/24103596/426790
export function createCookie(
  name: string,
  value: string,
  days: number | null | undefined,
) {
  let expires = '';

  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }

  document.cookie = `${name}=${value}${expires}; path=/; SameSite=Lax`;
}

// http://stackoverflow.com/a/25490531/426790
export function getCookieValue(name: string) {
  if (typeof document !== 'object') return '';
  const b = document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`);
  return b ? b.pop() : '';
}

// http://stackoverflow.com/questions/2144386/javascript-delete-cookie
export const deleteCookie = (name: string) => {
  createCookie(name, '', -1);
};

export function getListCookie<TValue>(name: string): TValue[] {
  const v = getCookieValue(name);
  // @ts-expect-error TS(2769): No overload matches this call.
  return isNotEmpty(v) ? JSON.parse(atob(v)) : [];
}

export function addFlashMessageForNextPageLoad(
  type: string,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: Record<string, any> | null | undefined = null,
) {
  const msg = {
    type,
    data,
  };
  let messages;
  const cookieKey = 'flashMessages';
  const cookieValue = getCookieValue(cookieKey);

  if (!cookieValue) {
    messages = [];
  } else {
    messages = JSON.parse(atob(cookieValue));
  }

  messages.push(msg);
  const newCookieValue = btoa(JSON.stringify(messages));
  createCookie(cookieKey, newCookieValue, 2);
}
