import invariant from 'invariant';

import { colors } from 'theme/theme';
import { matchSize } from 'utils/responsive';

// Adapted from https://plainjs.com/javascript/styles/get-the-position-of-an-element-relative-to-the-document-24/
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function calculateOffset(el: any, container: any) {
  const rect = el.getBoundingClientRect();
  invariant(document.documentElement, 'document.documentElement not defined');
  const scrollLeft = container
    ? container.scrollLeft
    : window.pageXOffset || document.documentElement.scrollLeft;
  const scrollTop = container
    ? container.scrollTop
    : window.pageYOffset || document.documentElement.scrollTop;
  return {
    top: rect.top + scrollTop,
    left: rect.left + scrollLeft,
  };
}

// Scroll element into view. Padding is space above the element
export function scrollIntoView(
  element: HTMLElement,
  {
    padding,
    noTransition,
    container,
    highlight,
  }: {
    padding?: number;
    noTransition?: boolean;
    container?: string;
    highlight?: boolean;
  } = {},
) {
  let prefersReducedMotion = false;

  if (typeof window !== 'undefined') {
    // Don't use smooth scrolling when the user prefers reduced motion
    prefersReducedMotion = window.matchMedia(
      '(prefers-reduced-motion: reduce)',
    ).matches;
  }
  const containerEl = container ? document.getElementById(container) : null;
  const offset = calculateOffset(element, containerEl);
  let offsetAboveElement = padding || 32;

  if (
    matchSize({
      xs: true,
      sm: true,
      md: true,
    })
  ) {
    // compensate for the sticky page header on mobile
    offsetAboveElement += 66;
  } else if (
    matchSize({
      lg: true,
      xl: true,
    }) &&
    offset.top > 0
  ) {
    offsetAboveElement += 114;
  }

  const scrollEl = containerEl || window;

  scrollEl.scrollTo({
    top: offset.top - offsetAboveElement,
    left: 0,
    behavior: noTransition || prefersReducedMotion ? 'auto' : 'smooth',
  });

  if (highlight) {
    const previousColor = element.style.backgroundColor;
    // eslint-disable-next-line no-param-reassign
    element.style.transition = 'background-color 0.5s ease';
    // eslint-disable-next-line no-param-reassign
    element.style.backgroundColor = colors.softBlue;

    setTimeout(() => {
      // eslint-disable-next-line no-param-reassign
      element.style.backgroundColor = previousColor;
    }, 1000);
  }
}

export function scrollToHash(
  hash?: string,
  options?: {
    padding?: number;
    noTransition?: boolean;
    container?: string;
    highlight?: boolean;
  },
) {
  if (hash && hash.length > 0) {
    const id = hash.replace('#', '');
    const el = document.getElementById(id);

    if (el) {
      scrollIntoView(el, options);
    }
  }
}
