import { SearchFacetData } from 'modules/search/types/SearchFacetData';

export const locationTypeFacet = {
  id: 'locationTypeFacet',
  name: 'locationType',
  title: getText('Location'),
  type: 'array',
  options: ['ONSITE', 'REMOTE', 'HYBRID'],
  optionTitles: {
    ONSITE: getText('On-Site'),
    REMOTE: getText('Remote'),
    HYBRID: getText('Hybrid'),
  },
} as const satisfies SearchFacetData;
