import { createLocalStorageStore } from './utils/createLocalStorageStore';

const IDEALIST_RSVP_KEY = 'idealistPreSignupRsvp';

const { useValue, setValue } = createLocalStorageStore<string>({
  key: IDEALIST_RSVP_KEY,
});

// Actions

export function setPreSignupRsvpEventId(
  eventId: string | null,
  answer: string | null,
) {
  if (eventId && answer) {
    setValue(`${eventId}:${answer}`);
  } else {
    setValue(null);
  }
}

// Hooks

export function usePreSignupRsvp() {
  const value = useValue();

  if (value) {
    const [preSignupRsvpEventId, preSignupRsvpAnswer] = value.split(':', 2);
    return { preSignupRsvpEventId, preSignupRsvpAnswer };
  }
  return { preSignupRsvpEventId: null, preSignupRsvpAnswer: null };
}
