import { ReactNode, useRef } from 'react';

import { useOnClickOutside } from 'hooks/useOnClickOutside';

type Props = {
  children: ReactNode;
  onClickOutside: (event: TouchEvent | MouseEvent) => void;
};

export function ClickOutside({ children, onClickOutside }: Props) {
  const elementRef = useRef<HTMLDivElement>(null);
  useOnClickOutside({ elementRef, onClickOutside });

  return <div ref={elementRef}>{children}</div>;
}
