import { css } from 'styled-components';

import { colors } from 'theme/theme';

import { TooltipPosition } from './TooltipPosition';
import { TooltipVariant } from './TooltipVariant';

export const variantStyles = (
  variant: TooltipVariant,
  placement: TooltipPosition,
) => {
  const isBottomVariant = [
    'bottom-left',
    'bottom-center',
    'bottom-right',
  ].includes(placement);

  switch (variant) {
    case 'brand-blue':
      return css`
        > div {
          background-color: white;
          background-image: linear-gradient(
            to left,
            ${colors.skyBlue},
            ${colors.hoverBlue}
          );
          background-size: 100% 7px;
          background-repeat: no-repeat;
          background-position: left ${isBottomVariant ? 'bottom' : 'top'};
          color: ${colors.lightContentGrey};
        }

        &::before {
          background-color: ${colors.white};
        }
      `;
    case 'brand-green':
      return css`
        > div {
          background-color: white;
          background-image: linear-gradient(
            to left,
            ${colors.tagLemonGreen},
            ${colors.brandGreen}
          );
          background-size: 100% 7px;
          background-repeat: no-repeat;
          background-position: left ${isBottomVariant ? 'bottom' : 'top'};
          color: ${colors.lightContentGrey};
        }

        &::before {
          background-color: ${colors.white};
        }
      `;
    case 'filled':
      return css`
        border: 1px solid ${colors.calloutGrey};

        > div {
          background-color: ${colors.calloutGrey};
          color: ${colors.black};
        }

        &::before {
          background-color: ${colors.calloutGrey};
        }
      `;
    case 'outline':
      return css`
        border: 1px solid ${colors.brandBlue};

        > div {
          background-color: ${colors.white};
          color: ${colors.black};
        }

        &::before {
          background-color: ${colors.white};
          border: 1px solid ${colors.brandBlue};
        }
      `;
  }
};
