import { HttpError } from 'utils/HttpError';

import { handleErrors } from './getJsonInternal';
import { parseJsonResponse } from './parseJsonResponse';

export function putJson<TData>(url: string, data: Record<string, unknown>) {
  return new Promise<TData>((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.addEventListener('load', () => {
      handleErrors(
        xhr.status,
        url,
        () => resolve(parseJsonResponse(xhr, reject)),
        () => reject(new HttpError('PUT', url, xhr)),
      );
    });
    xhr.addEventListener('error', () => {
      reject(new HttpError('PUT', url, xhr));
    });
    xhr.addEventListener('abort', () => {
      reject(new HttpError('PUT', url, xhr));
    });
    xhr.open('PUT', url, true);
    xhr.setRequestHeader('Accept', 'application/json');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.send(JSON.stringify(data));
  });
}
