import { ReactNode } from 'react';
import { RenderSuggestionsContainerParams } from 'react-autosuggest';

import { Box } from 'components/Box/Box';
import { Image } from 'components/Image/Image';
import { getStaticImageSrc } from 'utils/getImageSrc';

type Props = {
  containerProps: RenderSuggestionsContainerParams['containerProps'];
  children: ReactNode;
  loading: boolean;
};

export function SuggestionsContainer({
  containerProps: { id, ref, key, className, role },
  children,
  loading,
}: Props) {
  return (
    <div id={id} ref={ref} key={key} className={className} role={role}>
      {children}
      <Box display="flex" justifyContent="flex-end">
        {!loading ? (
          <Box
            component={Image}
            width={150}
            src={getStaticImageSrc(
              'poweredbygoogle/powered_by_google_on_white.png',
            )}
            srcSet={`${getStaticImageSrc(
              'poweredbygoogle/powered_by_google_on_white.png',
            )}, ${getStaticImageSrc(
              'poweredbygoogle/powered_by_google_on_white@2x.png',
            )} 2x, ${getStaticImageSrc(
              'poweredbygoogle/powered_by_google_on_white@3x.png',
            )} 3x`}
            alt="Powered by Google"
          />
        ) : null}
      </Box>
    </div>
  );
}
