import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { scrollToHash } from 'utils/scrollIntoView';

// This hook listens to the hash property of React-Router location and scrolls to the identified element if it exists.
// NOTE: This is a workaround to https://www.npmjs.com/package/react-router-hash-link which was previously used
// but no longer works with react-router v6.
export function useRouterHashScroll() {
  const location = useLocation<{ highlight?: boolean }>();

  useEffect(() => {
    const { hash, state } = location;

    if (!hash) return;

    scrollToHash(hash, { highlight: state?.highlight });
  }, [location]);
}
