import type { ShouldRevalidateFunctionArgs } from '@remix-run/router/utils';

import { cmsRouteRevalidation } from './cmsRouteRevalidation';

// Only revalidate if the pathname changes or `?pq=` is added or removed
export function cmsRouteShouldRevalidate({
  currentUrl,
  nextUrl,
}: ShouldRevalidateFunctionArgs): boolean {
  if (cmsRouteRevalidation.force) return true;

  if (currentUrl.pathname !== nextUrl.pathname) return true;
  if (currentUrl.searchParams.has('pq') !== nextUrl.searchParams.has('pq'))
    return true;

  return false;
}
