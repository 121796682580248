import { VisuallyHidden } from 'components/VisuallyHidden/VisuallyHidden';
import { useSuspendedUIDelay } from 'hooks/useDelay';

import { SpinnerHolder, SpinnerImage } from './LoadingSection.styled';

type Props = {
  noDelay?: boolean;
};

export function LoadingSection({ noDelay }: Props) {
  const { pastDelay } = useSuspendedUIDelay();
  if (!noDelay && !pastDelay) return null;

  return (
    <SpinnerHolder role="alert" aria-live="assertive">
      <SpinnerImage />
      <VisuallyHidden>Loading content...</VisuallyHidden>
    </SpinnerHolder>
  );
}
