import { CmsApiPage } from 'modules/cms/api/types/CmsApiPage';
import { PostSearchType } from 'modules/postSearch/types/PostSearchType';
import { SearchType } from 'modules/search/types/SearchType';

type Args = {
  content: CmsApiPage;
};

export function getCmsApiPageDefaultSearchOrPostSearchType({
  content,
}: Args): SearchType | PostSearchType {
  return (
    content.heroSearch?.defaultSearchFilters?.type ||
    content.subsite.defaultSearchType ||
    'JOB'
  );
}
