import { configValue } from 'config/appConfigUtils';

export function isExternalURL(url: string) {
  if (!url) return;

  // ignore mailto
  if (url.startsWith('mailto:')) {
    return false;
  }

  // ignore links that are relative to the current url, like anchors, query params
  if (url.startsWith('#') || url.startsWith('?')) {
    return false;
  }

  const domain = (u: string) =>
    u.replace('http://', '').replace('https://', '').split('/')[0];

  const appUrl = configValue('idealist', 'appUrl') || '';
  return Boolean(domain(url)) && domain(url) !== domain(appUrl);
}
