import { REMOTE_KEYWORDS } from 'utils/constants/search';

import { createRemotePrediction } from './createRemotePrediction';

type Props = {
  value: string;
  defaultKeyword: string;
};

export function getRemoteOptions({ value, defaultKeyword }: Props) {
  const option = REMOTE_KEYWORDS[CURRENT_LOCALE].find((kw) =>
    kw.toLowerCase().startsWith(value.toLowerCase()),
  );

  return option
    ? createRemotePrediction({ keyword: option, defaultKeyword })
    : undefined;
}
