import loadable from '@loadable/component';
import { ReactNode } from 'react';

import { Dropdown } from 'components/Dropdown/Dropdown';
import { searchGetJobsRoutePath } from 'modules/search/routing/helpers/searchGetJobsRoutePath';
import { hydrateOnDemand } from 'utils/hydrateOnDemand/hydrateOnDemand';

type Props = {
  locale: UserLocale;
  renderTrigger: (data: {
    triggerText: string;
    triggerHref: string;
  }) => ReactNode;
  trackClickedContext: string;
};

const triggerTextByLocale: Record<UserLocale, { text: string; href: string }> =
  {
    en: {
      text: 'Find a Job',
      href: searchGetJobsRoutePath({ lang: 'en' }),
    },
    es: {
      text: 'Encuentra empleo',
      href: searchGetJobsRoutePath({ lang: 'es' }),
    },
    pt: {
      text: 'Buscar Emprego',
      href: searchGetJobsRoutePath({ lang: 'pt' }),
    },
  };

const FindAJobDropdownFoldMenu = hydrateOnDemand(
  loadable(() => import('./FindAJobDropdownMenu'), {
    resolveComponent: (module) => module.FindAJobDropdownMenu,
  }),
);

export function FindAJobDropdown({
  locale,
  renderTrigger,
  trackClickedContext,
}: Props) {
  const { text, href } = triggerTextByLocale[locale];

  return (
    <Dropdown
      trigger={renderTrigger({ triggerText: text, triggerHref: href })}
      desktopTriggersOnHover
    >
      <FindAJobDropdownFoldMenu
        locale={locale}
        trackClickedContext={trackClickedContext}
      />
    </Dropdown>
  );
}
