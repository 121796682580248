import { levelOptionTitles } from 'modules/search/helpers/sidefilters';
import { SearchFacetData } from 'modules/search/types/SearchFacetData';
import { PROFESSIONAL_LEVELS } from 'utils/constants/general/professionalLevels';

export const professionalLevelFacet = {
  id: 'professionalLevelFacet',
  name: 'professionalLevel',
  title: getText('Experience Level'),
  type: 'array',
  options: PROFESSIONAL_LEVELS.map((professionalLevel) => professionalLevel.id),
  optionTitles: levelOptionTitles(PROFESSIONAL_LEVELS),
  sort: false,
} as const satisfies SearchFacetData;
