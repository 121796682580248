// @ts-expect-error TS(7016): Could not find a declaration file for module 'hype... Remove this comment to see the full error message
import { load } from 'hypernova';

import { renderingHypernovaPayloadName } from 'rendering/hypernova/renderingHypernovaPayloadName';
import { RenderingHypernovaPayload } from 'rendering/hypernova/types/RenderingHypernovaPayload';

export function renderingDeserializeHypernovaPayload():
  | RenderingHypernovaPayload
  | undefined {
  const payloads = load(renderingHypernovaPayloadName);
  if (payloads.length === 0) return;

  const payload = payloads[0];
  const { node } = payload;

  if (!node) return;

  return payload as RenderingHypernovaPayload;
}
