export enum LocalDropdownNotificationType {
  OFFICE_HOURS = 'OFFICE_HOURS',
  ORG_ADMIN_USER_DROPDOWN_ATS_CTA = 'ORG_ADMIN_USER_DROPDOWN_ATS_CTA',
  OFFICE_HOURS_07_2024 = 'OFFICE_HOURS_07_2024',
  RESUME_WEBINAR = 'RESUME_WEBINAR',
  ES_SURVEY = 'ES_SURVEY',
  INTERVIEW_WEBINAR_08_2024 = 'INTERVIEW_WEBINAR_08_2024',
  GF_2024_TEXT = 'GF_2024_TEXT',
  GF_2024_CALENDAR = 'GF_2024_CALENDAR',
  ADMIN_SURVEY_2024 = 'ADMIN_SURVEY_2024',
}
