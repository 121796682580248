import { getApplicationContext } from 'utils/analytics/helpers/getApplicationContext';
import { registerMixpanelProperties } from 'utils/analytics/helpers/mixpanel';

export function registerApplicationContext() {
  registerMixpanelProperties({
    application_context: getApplicationContext(),
  });
}

export function bootstrapAnalytics() {
  registerApplicationContext();
}
