import { create } from 'zustand';

import { ApiOrgId } from 'api/types/ApiTypedId';
import {
  orgDashboardApiFetchOrg,
  orgDashboardApiSsrApiDataForFetchOrg,
} from 'modules/orgDashboard/api/orgDashboardApiFetchOrg';
import { OrgDashboardApiOrg } from 'modules/orgDashboard/api/types/OrgDashboardApiOrg';

const orgIdsLoading = new Set<ApiOrgId>();

const orgDashboardOrgStore = create<{
  byId: Record<ApiOrgId, OrgDashboardApiOrg>;
}>(() => ({ byId: {} }));

const useOrgDashboardOrgStore = orgDashboardOrgStore; // The store can be used as a hook

// Actions

export function orgDashboardStoreUpdateOrg(org: OrgDashboardApiOrg) {
  orgDashboardOrgStore.setState((state) => ({
    byId: { ...state.byId, [org.id]: org },
  }));
}

export async function orgDashboardStoreLoadOrg(orgId: ApiOrgId) {
  orgIdsLoading.add(orgId);

  const { org } = await orgDashboardApiFetchOrg(orgId);
  orgDashboardStoreUpdateOrg(org);

  orgIdsLoading.delete(orgId);
}

export async function orgDashboardStoreLoadOrgOnce(orgId: ApiOrgId) {
  const org = orgDashboardOrgStore.getState().byId[orgId];
  if (!org) await orgDashboardStoreLoadOrg(orgId);
}

// Hook

export function useOrgDashboardStoreOrg(
  orgId: ApiOrgId,
): OrgDashboardApiOrg | undefined {
  const org = useOrgDashboardOrgStore((state) => state.byId[orgId]);
  if (org) return org;

  const ssrApiData = orgDashboardApiSsrApiDataForFetchOrg(orgId);
  if (ssrApiData) {
    orgDashboardStoreUpdateOrg(ssrApiData.org);
    return ssrApiData.org;
  }

  orgDashboardStoreLoadOrgOnce(orgId);
}
