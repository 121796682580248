import { ApiInternshipId } from 'api/types/ApiTypedId';
import { ApiInternship } from 'modules/listing/api/internship/types/ApiInternship';
import { ApiUnpublishedInternship } from 'modules/listing/api/internship/types/ApiUnpublishedInternship';
import { FetchEndpoint } from 'utils/endpoint/FetchEndpoint';

type ApiResponse = {
  data: ApiInternship | ApiUnpublishedInternship;
};

function mapResponse(
  response: ApiResponse,
): ApiInternship | ApiUnpublishedInternship {
  return response.data;
}

const endpoint = new FetchEndpoint({
  urlFactory: (internshipId: ApiInternshipId) =>
    `/data/website/internships/${internshipId}`,
  mapResponse,
});

// Exports

export const listingApiFetchInternship = endpoint.createFetchFn();
