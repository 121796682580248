import { css } from 'styled-components';

import { TooltipPosition } from './TooltipPosition';
import { TooltipSize } from './TooltipSize';

export const placementStyles = (
  placement: TooltipPosition,
  targetWidth: number,
  targetHeight: number,
  size: TooltipSize,
) => {
  const arrowSize = size === 'large' ? 17 : 10;
  const arrowHorizontalOffset = targetWidth < arrowSize ? 17 : 0;

  let additionalStyles = {};
  let toTransform;
  let fromTransform;

  switch (placement) {
    case 'bottom-center':
      fromTransform = `translateX(calc(-50% + ${
        targetWidth / 2
      }px)) translateY(calc(${arrowSize}px))`;
      toTransform = `translateX(calc(-50% + ${targetWidth / 2}px)) translateY(${
        targetHeight + arrowSize
      }px)`;
      additionalStyles = css`
        transform-origin: top center;
      `;
      break;
    case 'bottom-left':
      fromTransform = `translateX(calc(-100% + ${targetWidth}px + ${arrowHorizontalOffset}px)) translateY(${arrowSize}px)`;
      toTransform = `translateX(calc(-100% + ${targetWidth}px + ${arrowHorizontalOffset}px)) translateY(${
        targetHeight + arrowSize
      }px)`;
      additionalStyles = css`
        transform-origin: top right;
      `;
      break;
    case 'bottom-right':
      fromTransform = `translateX(-${arrowHorizontalOffset}px) translateY(${arrowSize}px)`;
      toTransform = `translateX(-${arrowHorizontalOffset}px) translateY(${
        targetHeight + arrowSize
      }px)`;
      additionalStyles = css`
        transform-origin: top left;
      `;
      break;
    case 'top-center':
      fromTransform = `translateX(calc(-50% + ${
        targetWidth / 2
      }px)) translateY(-100%)`;
      toTransform = `translateX(calc(-50% + ${
        targetWidth / 2
      }px)) translateY(calc(-100% - ${arrowSize}px))`;
      additionalStyles = css`
        transform-origin: bottom center;
      `;
      break;
    case 'top-left':
      fromTransform = `translateX(calc(-100% + ${targetWidth}px + ${arrowHorizontalOffset}px)) translateY(-100%)`;
      toTransform = `translateX(calc(-100% + ${targetWidth}px + ${arrowHorizontalOffset}px)) translateY(calc(-100% - ${arrowSize}px))`;
      additionalStyles = css`
        transform-origin: bottom right;
      `;
      break;
    case 'top-right':
      fromTransform = `translateX(-${arrowHorizontalOffset}px) translateY(-100%)`;
      toTransform = `translateX(-${arrowHorizontalOffset}px) translateY(calc(-100% - ${arrowSize}px))`;
      additionalStyles = css`
        transform-origin: bottom left;
      `;
      break;
    default:
      toTransform = '';
  }

  return { fromTransform, toTransform, additionalStyles };
};
