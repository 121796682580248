import { AppRouteGroup } from 'routing/classes/AppRouteGroup';

export const orgDashboardRouteGroup = new AppRouteGroup('/admin/:orgId');

export const orgDashboardHomeRoute = orgDashboardRouteGroup.rootRoute();

export const orgDashboardPostAListingRoute =
  orgDashboardRouteGroup.route('post-a-listing');

// Org

export const orgDashboardPreviewOrgRoute =
  orgDashboardRouteGroup.route('preview');

export const orgDashboardEditOrgRoute = orgDashboardRouteGroup.route('edit');

// Admins

export const orgDashboardManageAdminsRoute =
  orgDashboardRouteGroup.route('admins');

// Checkout

export const orgDashboardCartRoute = orgDashboardRouteGroup.route('cart');

export const orgDashboardCheckoutCartRoute = orgDashboardRouteGroup.route(
  'single-checkout/cart',
);

export const orgDashboardCheckoutBillingAddressRoute =
  orgDashboardRouteGroup.route('checkout/billing-address', [
    'pack',
    'invoiceId',
  ]);

export const orgDashboardPayByCreditCartRoute =
  orgDashboardRouteGroup.route('pay-by-credit-card');

// Credits

export const orgDashboardCreditsRoute = orgDashboardRouteGroup.route('credits');

export const orgDashboardBuyCreditPacksRoute =
  orgDashboardRouteGroup.route('buy-credits');

export const orgDashboardViewCreditPackRoute =
  orgDashboardRouteGroup.route('credits/:packId');

// Api

export const orgDashboardApiAccessRoute =
  orgDashboardRouteGroup.route('api-access');

// Integrations

export const orgDashboardIntegrationsRoute =
  orgDashboardRouteGroup.route('integrations');

export const orgDashboardIntegrationRoute =
  orgDashboardRouteGroup.route('integrations/:type');

// Invoice

export const orgDashboardListInvoicesRoute =
  orgDashboardRouteGroup.route('invoices');

export const orgDashboardViewInvoiceRoute = orgDashboardRouteGroup.route(
  'invoices/:invoiceId',
);

export const orgDashboardInvoiceBillingAddressRoute =
  orgDashboardRouteGroup.route('invoices/:invoiceId/billing-address');

// Payments

export const orgDashboardListPaymentsRoute =
  orgDashboardRouteGroup.route('payments');

export const orgDashboardViewPaymentRoute = orgDashboardRouteGroup.route(
  'payments/:paymentId',
);

export const orgDashboardPaymentSummaryRoute =
  orgDashboardRouteGroup.route('payment-summary');

// Listings

export const orgDashboardManageListingsRoute = orgDashboardRouteGroup.route('');

// Job

export const orgDashboardViewJobRoute =
  orgDashboardRouteGroup.route('jobs/:jobId/:tab?');

export const orgDashboardPreviewJobRoute = orgDashboardRouteGroup.route(
  'jobs/:jobId/preview',
);

export const orgDashboardCreateJobRoute =
  orgDashboardRouteGroup.route('jobs/create');

export const orgDashboardEditJobRoute =
  orgDashboardRouteGroup.route('jobs/:jobId/edit');

export const orgDashboardRepostJobRoute =
  orgDashboardRouteGroup.route('jobs/:jobId/repost');

export const orgDashboardViewJobRecommendedCandidatesRoute =
  orgDashboardRouteGroup.route(
    'jobs/:jobId/recommended-candidates/:applicantId',
  );

// Internship

export const orgDashboardViewInternshipRoute = orgDashboardRouteGroup.route(
  'internships/:internshipId',
);

export const orgDashboardPreviewInternshipRoute = orgDashboardRouteGroup.route(
  'internships/:internshipId/preview',
);

export const orgDashboardEditInternshipRoute = orgDashboardRouteGroup.route(
  'internships/:internshipId/edit',
);

export const orgDashboardCreateInternshipRoute =
  orgDashboardRouteGroup.route('internships/create');

export const orgDashboardRepostInternshipRoute = orgDashboardRouteGroup.route(
  'internships/:internshipId/repost',
);

// Volop

export const orgDashboardViewVolopRoute =
  orgDashboardRouteGroup.route('volops/:volopId');

export const orgDashboardPreviewVolopRoute = orgDashboardRouteGroup.route(
  'volops/:volopId/preview',
);

export const orgDashboardCreateVolopRoute =
  orgDashboardRouteGroup.route('volops/create');

export const orgDashboardEditVolopRoute = orgDashboardRouteGroup.route(
  'volops/:volopId/edit',
);

export const orgDashboardRepostVolopRoute = orgDashboardRouteGroup.route(
  'volops/:volopId/repost',
);

// Event

export const orgDashboardViewEventRoute =
  orgDashboardRouteGroup.route('events/:eventId');

export const orgDashboardPreviewEventRoute = orgDashboardRouteGroup.route(
  'events/:eventId/preview',
);

export const orgDashboardCreateEventRoute =
  orgDashboardRouteGroup.route('events/create');

export const orgDashboardEditEventRoute = orgDashboardRouteGroup.route(
  'events/:eventId/edit',
);

export const orgDashboardRepostEventRoute = orgDashboardRouteGroup.route(
  'events/:eventId/repost',
);

// Grad Fairs

export const orgDashboardGradFairsHomeRoute =
  orgDashboardRouteGroup.route('grad-fairs');

export const orgDashboardViewGradFairInviteRoute = orgDashboardRouteGroup.route(
  'grad-fairs/invites/:inviteId',
);

// Applications

export const orgDashboardViewApplicationRoute = orgDashboardRouteGroup.route(
  'applications/:applicationId',
);

// Preferences

export const orgDashboardNotificationPreferencesRoute =
  orgDashboardRouteGroup.route('notification-preferences');
