import { createLocalStorageStore } from './utils/createLocalStorageStore';

const IDEALIST_COMMENT_KEY = 'idealistPreSignupComment';

const { useValue, setValue } = createLocalStorageStore<true>({
  key: IDEALIST_COMMENT_KEY,
});

// Actions

export function setHasPreSignupComment(hasPreSignupComment: true | null) {
  setValue(hasPreSignupComment);
}

// Hooks

export function usePreSignupComment() {
  const value = useValue();
  return { hasPreSignupComment: value };
}
