import { create } from 'zustand';

import { trackEvent } from 'utils/analytics/track/trackEvent';

const gMapsErroredStore = create<{ gMapsErrored: boolean }>(() => ({
  gMapsErrored: false,
}));

const useGMapsErroredStore = gMapsErroredStore; // The store can be used as a hook

// Actions

export function setGMapsError(error: string) {
  gMapsErroredStore.setState({ gMapsErrored: true });
  trackEvent('Google Maps API Error', { error });
}

// Hook

export function useGMapsErrored() {
  const gMapsErrored = useGMapsErroredStore((state) => state.gMapsErrored);
  return { gMapsErrored };
}
