import styled from 'styled-components';

import { navigationTransitionCss } from 'theme/navigationTransitionCss';
import { routeLoadingDisplayNoneUnlessLoadingVar } from 'theme/routeLoading';
import { colors } from 'theme/theme';

export const LayoutHolder = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  @media print {
    min-height: unset;
  }
`;

export const LayoutChildrenHolder = styled.div`
  flex: 1;
  position: relative;

  ${navigationTransitionCss};
`;

export const LayoutProgressBarFixedHolder = styled.div`
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
`;

export const LayoutProgressBarAbsoluteBottomHolder = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
`;

export const LayoutProgressBar = styled.div`
  position: relative;
  width: 100%;
  height: 4px;
  display: var(${routeLoadingDisplayNoneUnlessLoadingVar}, none);
  background: ${colors.backgroundGrey};
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: ${colors.brandBlue};
    animation: indeterminate-animation 1s infinite linear;
    transform-origin: 0 50%;

    @keyframes indeterminate-animation {
      0% {
        transform: translateX(0) scaleX(0);
      }

      40% {
        transform: translateX(0) scaleX(0.4);
      }

      100% {
        transform: translateX(100%) scaleX(0.5);
      }
    }
  }
`;
