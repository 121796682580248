export const REMOTE_KEYWORDS = {
  en: [
    'Remote',
    'Virtual',
    'Work From Home',
    'WFH',
    'Flexible',
    'Telecommute',
    'Tele-commute',
    'Telework',
    'Telecommuting',
  ],
  es: [
    'Remoto',
    'Trabajo remoto',
    'Teletrabajo',
    'Tele-trabajo',
    'Virtual',
    'Desde Casa',
    'Trabajo a distancia',
    'A distancia',
  ],
  pt: [
    'Remoto',
    'Trabalho Remoto',
    'Virtual',
    'Home Office',
    'Trabalho à Distância',
    'Flexível',
    'Nômade Digital',
    'EAD',
  ],
};
