import { AppRoute } from './AppRoute';

export class AppRouteGroup<
  TGroupPath extends string,
  TGroupParentPathWithSlash extends string = '',
> {
  public readonly path: TGroupPath;

  public readonly parentPathWithSlash: TGroupParentPathWithSlash;

  constructor(
    path: TGroupPath,
    parentPathWithSlash?: TGroupParentPathWithSlash,
  ) {
    this.path = path;
    // @ts-expect-error fallback to an empty string
    this.parentPathWithSlash = parentPathWithSlash || '';
  }

  /**
   * Create a route that uses this group's path & params
   */
  route<TRelativePath extends string, TSearchParam extends string = never>(
    relativePath: TRelativePath,
    searchParams?: TSearchParam[],
  ): AppRoute<
    `${TGroupParentPathWithSlash}${TGroupPath}`,
    TRelativePath,
    TSearchParam
  > {
    return new AppRoute({
      groupPath: this.fullPath,
      relativePath,
      searchParams,
    });
  }

  rootRoute(): AppRoute<
    `${TGroupParentPathWithSlash}${TGroupPath}`,
    '',
    never
  > {
    return new AppRoute({ groupPath: this.fullPath, relativePath: '' });
  }

  get fullPath(): `${TGroupParentPathWithSlash}${TGroupPath}` {
    return `${this.parentPathWithSlash}${this.path}`;
  }

  get wrapperPath(): `${TGroupPath}/*` {
    return `${this.path}/*`;
  }

  get fullWrapperPath(): `${TGroupParentPathWithSlash}${TGroupPath}/*` {
    return `${this.fullPath}/*`;
  }

  subgroup<TSubgroupPath extends string>(
    path: TSubgroupPath,
  ): AppRouteGroup<TSubgroupPath, `${TGroupPath}/`> {
    return new AppRouteGroup(path, `${this.path}/`);
  }
}
