import { SearchFacet } from 'modules/search/types/SearchFacet';

import { localeFacet } from './searchFacets/localeFacet';
import { locationTypeFacet } from './searchFacets/locationTypeFacet';
import { orgAreasOfFocusFacet } from './searchFacets/orgAreasOfFocusFacet';
import { orgTypeFacet } from './searchFacets/orgTypeFacet';

export const searchFacetsOrg = [
  locationTypeFacet,
  orgAreasOfFocusFacet,
  orgTypeFacet,
  localeFacet,
] satisfies SearchFacet[];
