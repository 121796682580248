import { HttpError } from 'utils/HttpError';

import { handleErrors } from './getJsonInternal';
import { parseJsonResponse } from './parseJsonResponse';

export function postJson<T = unknown>(
  url: string,
  data: Record<string, unknown> = {},
  options: { headers?: Record<string, string | undefined> } = {},
): Promise<T> {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.addEventListener('load', () => {
      handleErrors(
        xhr.status,
        url,
        () => resolve(parseJsonResponse(xhr, reject)),
        () => reject(new HttpError('POST', url, xhr)),
      );
    });
    xhr.addEventListener('error', () => {
      reject(new HttpError('PUT', url, xhr));
    });
    xhr.addEventListener('abort', () => {
      reject(new HttpError('PUT', url, xhr));
    });
    xhr.open('POST', url, true);
    xhr.setRequestHeader('Accept', 'application/json');
    xhr.setRequestHeader('Content-Type', 'application/json');

    if (options.headers && Object.keys(options.headers).length > 0) {
      Object.entries(options.headers).forEach(([k, v]) => {
        if (typeof v !== 'undefined') xhr.setRequestHeader(k, v);
      });
    }

    xhr.send(JSON.stringify(data));
  });
}
