import queryString from 'query-string';

import { ApiEnvironment } from 'api/userEnvironment/types/ApiUserEnvironment';
import { setABExperimentBucket } from 'zustand-stores/userEnvironmentStore';

// Accept a query param to override an AB experiment bucket:
// ?ab_test[button_color]=red
export function renderingOverrideClientABExperiments(
  environment: ApiEnvironment,
) {
  const queryParams = queryString.parse(window.location.search);
  const overrides = Object.keys(queryParams).reduce((acc, param) => {
    const matches = param.match(/^ab_test\[([^\]]+)\]$/);

    if (matches) {
      // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'never'.
      acc.push({ id: matches[1], bucket: queryParams[param] });
    }

    return acc;
  }, []);
  overrides.forEach(({ id, bucket }) => {
    const experiment = environment.abExperiments.find((e) => e.id === id);

    if (!experiment || experiment.bucket !== bucket) {
      setABExperimentBucket(id, bucket);
    }
  });
}
