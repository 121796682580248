import { SearchLocation } from 'modules/search/types/SearchLocation';
import { getTimezoneFromLatLng } from 'utils/timezones';

import { getCity } from './getCity';

// Maintain a cache of timezone by lat/lng to avoid unnecessary API calls
const timezoneByLatLng = new Map<string, string>();

export const geocoderResultToSearchLocation = async (
  place: google.maps.GeocoderResult,
  {
    includeCity,
    includeFull,
  }: { includeCity?: boolean; includeFull?: boolean },
): Promise<SearchLocation> => {
  const addressComponents: Record<string, string> =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    place.address_components.reduce((hash: any, component: any) => {
      const attributeName = component.types[0];

      return {
        ...hash,
        [attributeName]: ['country', 'administrative_area_level_1'].includes(
          attributeName,
        )
          ? component.short_name
          : component.long_name,
      };
    }, {});

  if (!('country' in addressComponents)) {
    // below: special handling of 'south east asia', 'gaza', etc.
    // i.e. results for which the autocomplete api does not return a country code
    // we will use the iso 3166 user-defined 'XA' code for this situation
    // https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2#User-assigned_code_elements
    addressComponents.country = 'XA';
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line no-param-reassign
    place.types[0] = 'undefined_country_geocodes';
  }

  const countryCode = addressComponents.country;
  const stateCode = addressComponents.administrative_area_level_1;
  let city;
  let full;

  if (includeCity) {
    city = getCity(addressComponents);
  }

  if (includeFull) {
    full = place.formatted_address;
  }

  const lat = place.geometry.location.lat();
  const lng = place.geometry.location.lng();
  const cacheKey = [lat, lng].join('-');

  if (!timezoneByLatLng.has(cacheKey)) {
    const tz = await getTimezoneFromLatLng(lat, lng);
    timezoneByLatLng.set(cacheKey, tz);
  }

  const timezone = timezoneByLatLng.get(cacheKey);

  const boundingBox = place.geometry.viewport.toJSON();
  return {
    text: place.formatted_address,
    stateCode,
    countryCode,
    boundingBoxN: boundingBox.north,
    boundingBoxW: boundingBox.west,
    boundingBoxS: boundingBox.south,
    boundingBoxE: boundingBox.east,
    geoType: place.types[0],
    // @ts-expect-error Can we use full?
    latLongAddress: place.formatted_address,
    latitude: place.geometry.location.lat(),
    longitude: place.geometry.location.lng(),
    full,
    city,
    timezone,
  };
};
