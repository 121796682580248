import { RouteObject } from 'react-router-dom';

import { LayoutHandleProps } from 'layouts/Layout/LayoutHandleProps';
import { SearchOrgsPageLoadable } from 'modules/search/pages/Orgs/SearchOrgsPageLoadable';
import { loadableLoader } from 'routing/utils/loadableLoader';

import { searchGetOrgsRoutePath } from './helpers/searchGetOrgsRoutePath';

const urlByLocale = {
  en: searchGetOrgsRoutePath({ lang: 'en' }),
  es: searchGetOrgsRoutePath({ lang: 'es' }),
  pt: searchGetOrgsRoutePath({ lang: 'pt' }),
} as const satisfies Record<UserLocale, string>;

export const searchOrgsRouteObjects = (['en', 'es', 'pt'] as const).map(
  (locale) => ({
    path: urlByLocale[locale],
    handle: {
      urlByLocale,
      darkBackground: true,
      defaultSearchType: 'ORG',
      hideFooterDivider: true,
      showDesktopSearch: true,
      isSearchPage: true,
    } satisfies LayoutHandleProps,
    loader: loadableLoader(SearchOrgsPageLoadable),
    element: <SearchOrgsPageLoadable />,
  }),
) satisfies RouteObject[];
