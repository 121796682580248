import { ComponentProps, forwardRef } from 'react';
import styled from 'styled-components';

import { linkStyles } from 'theme/theme';

import { UnstyledNativeLink } from './UnstyledNativeLink';

const UnstyledNativeLinkWithRef = forwardRef<
  HTMLAnchorElement,
  Omit<ComponentProps<typeof UnstyledNativeLink>, 'anchorRef'>
>((props, ref) => (
  <UnstyledNativeLink
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    anchorRef={ref}
  />
));

export const StyledNativeLinkWithRef = styled(UnstyledNativeLinkWithRef)`
  ${linkStyles}
`;
