import { RouteObject } from 'react-router-dom';

import { eventRouteObjects } from './event/event.route-objects';
import { internshipRouteObjects } from './internship/internship.route-objects';
import { jobRouteObjects } from './job/job.route-objects';
import { orgRouteObjects } from './org/org.route-objects';
import { volopRouteObjects } from './volop/volop.route-objects';

export const listingRouteObjects = [
  ...eventRouteObjects,
  ...internshipRouteObjects,
  ...jobRouteObjects,
  ...volopRouteObjects,
  ...orgRouteObjects,
] satisfies RouteObject[];
