import { RouteObject } from 'react-router-dom';

import { apiFetchInvite } from 'api/invite/apiFetchInvite';
import { ForgotPasswordPageContainerLoadable } from 'modules/auth/pages/forgotPassword/ForgotPasswordPageContainerLoadable';
import { LoginPageContainerLoadable } from 'modules/auth/pages/login/LoginPageContainerLoadable';
import { ResetPasswordPageContainerLoadable } from 'modules/auth/pages/resetPassword/ResetPasswordPageContainerLoadable';
import { SignupPageLoadable } from 'modules/auth/pages/signup/SignupPageLoadable';
import { AppRouteLoaderArgs } from 'routing/classes/types/AppRouteLoaderArgs';
import { signupRoute } from 'routing/routes';
import { loadableLoader } from 'routing/utils/loadableLoader';
import { renderLoader } from 'routing/utils/renderLoader/renderLoader';
import { WithRouterParams } from 'utils/WithRouterParams';

export const authRouteObjects = [
  {
    path: '/login',
    loader: loadableLoader(LoginPageContainerLoadable),
    Component: LoginPageContainerLoadable,
  },
  {
    path: signupRoute.fullPath,
    ...renderLoader({
      loader: async ({ request }: AppRouteLoaderArgs<typeof signupRoute>) => {
        const { searchParams } = new URL(request.url);

        const inviteId = searchParams.get('invite');
        const [invite] = await Promise.all([
          inviteId ? apiFetchInvite(inviteId) : Promise.resolve(null),
          SignupPageLoadable,
        ]);

        return invite;
      },
      render: (invite) => <SignupPageLoadable invite={invite} />,
    }),
  },
  {
    path: '/forgot-password',
    loader: () => ForgotPasswordPageContainerLoadable.load().then(() => null),
    Component: ForgotPasswordPageContainerLoadable,
  },
  {
    path: '/reset-password/:token',
    element: (
      <WithRouterParams
        render={({ token }) => (
          <ResetPasswordPageContainerLoadable token={token} />
        )}
      />
    ),
  },
] satisfies RouteObject[];
