import type { SearchResponse } from '@algolia/client-search';
import algoliasearch from 'algoliasearch';
import invariant from 'invariant';
import queryString from 'query-string';

import { configValue } from 'config/appConfigUtils';
import { RichError } from 'utils/RichError';
import { postJson } from 'utils/http/postJson';
import { trackError } from 'utils/trackError';

import { AlgoliaGeneralError } from './AlgoliaGeneralError';
import {
  AlgoliaValidUntilError,
  isValidUntilError,
} from './AlgoliaValidUntilError';
import { AlgoliaSearchOptionsWithIndex } from './types/AlgoliaSearchOptionsWithIndex';

type Args = {
  config: Readonly<{ appId: string; apiKey: string }>;
  query: string;
  options: AlgoliaSearchOptionsWithIndex;
  analytics?: boolean;
  onApiKeyTimeout?: () => Promise<string>;
};

export function algoliaSearch<TResponse>({
  config: { appId, apiKey },
  query,
  options: { index, ...options },
  analytics = true,
  onApiKeyTimeout,
}: Args) {
  if (configValue('algolia', 'mock')) {
    return postJson<SearchResponse<TResponse>>(
      `http://algoliamock:6577/${index}/search`,
      {
        query,
        hitsPerPage: options.hitsPerPage,
        page: options.page,
        facets: options.facets,
        filters: options.filters,
        attributesToHighlight: options.attributesToHighlight,
      },
      {
        headers: {
          'x-algolia-api-key': apiKey,
        },
      },
    );
  }
  invariant(appId, 'Search app id must be configured');
  invariant(apiKey, 'Search api key must be configured');

  const params = {
    ...options,
    getRankingInfo: true,
    clickAnalytics: true,
    analytics,
  };

  let client = algoliasearch(appId, apiKey);
  let indexClient = client.initIndex(index);

  let request = indexClient.search<TResponse>(
    query ? query.trim() : '',
    params,
  );

  return request.catch((e) => {
    if (isValidUntilError(e)) {
      if (onApiKeyTimeout) {
        return onApiKeyTimeout().then((newApiKey) => {
          client = algoliasearch(appId, newApiKey);
          indexClient = client.initIndex(index);
          request = indexClient.search(query ? query.trim() : '', params);
          return request;
        });
      }

      throw new AlgoliaValidUntilError();
    }

    const requestInfo = {
      debugData: e.debugData,
      errorMessage: e.message,
      requestParams: queryString.stringify(params),
      statusCode: e.statusCode,
      response: e.response,
    };

    trackError(
      new RichError('Algolia POST request error.', {
        requestInfo: JSON.stringify(requestInfo),
      }),
    );

    throw new AlgoliaGeneralError(
      `Algolia Error: ${JSON.stringify(requestInfo)}`,
    );
  });
}
