import { FetchEndpoint } from 'utils/endpoint/FetchEndpoint';

import { UserDashboardApiSavedSearch } from './types/UserDashboardApiSavedSearch';

type ApiResponse = {
  savedSearches: UserDashboardApiSavedSearch[];
};

function mapResponse(response: ApiResponse) {
  return response.savedSearches;
}

const endpoint = new FetchEndpoint({
  urlFactory: () => '/data/userdashboard/all-saved-searches',
  mapResponse,
});

// Exports

export const userDashboardApiGetFetchAllSavedSearchesSsrApiData =
  endpoint.createSsrApiDataFn();

export const userDashboardApiFetchAllSavedSearches = endpoint.createFetchFn();
