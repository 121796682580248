import { RouteObject } from 'react-router-dom';

import { RouteErrorBoundary } from 'routing/components/RouteErrorBoundary';

export function routeObjectsWithRootErrorBoundary(
  routeObjects: RouteObject[],
): RouteObject[] {
  return [
    {
      errorElement: <RouteErrorBoundary />,
      children: routeObjects,
    },
  ];
}
