import { useState } from 'react';

import { useIsomorphicLayoutEffect } from './useIsomorphicLayoutEffect';

/**
 * Use isClient to render client-side DOM changes in a second pass.
 *
 * See: https://reactjs.org/docs/react-dom.html#hydrate
 */
export function useIsClient() {
  const [isClient, setIsClient] = useState(false);

  useIsomorphicLayoutEffect(() => {
    setIsClient(true);
  }, []);

  return isClient;
}
