import { ApiGuessedLocation } from 'api/types/ApiGuessedLocation';
import { SearchLocation } from 'modules/search/types/SearchLocation';
import { getItem } from 'utils/localStorage';

export function searchGetInitialLocation(
  guessedLocation: ApiGuessedLocation,
): SearchLocation {
  return (
    getItem('searchLocation') || {
      ...guessedLocation,
      geoType: 'ipAddress',
      boundingBoxN: null,
      boundingBoxS: null,
      boundingBoxW: null,
      boundingBoxE: null,
    }
  );
}
