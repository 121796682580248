import { ListingApplicationPayload } from 'api/ats';
import { CmsApiSubscribe } from 'modules/cms/api/types/CmsApiSubscribe';
import { SalariesReportFormData } from 'modules/salaries/types/SalariesReportFormData';
import { ApiSavedSearchUnsaved } from 'modules/userDashboard/api/savedSearch/types/UserDashboardApiSavedSearch';

import { postJson } from './http/postJson';

export type SignupBlockRedirect = {
  redirectUrl: string;
};

export type SaveListingType = {
  id: string;
  type: string;
};

export type SubscribeSubsiteType = {
  subscribedSubsiteId: string;
};

export type StateType =
  | ListingApplicationPayload
  | ApiSavedSearchUnsaved
  | CmsApiSubscribe
  | SignupBlockRedirect
  | SaveListingType
  | SubscribeSubsiteType
  | SalariesReportFormData;

export type ActionType =
  | 'CMS_SUBSCRIBE'
  | 'SURVEY_REPORT_DATA'
  | 'LISTING_APPLICATION_VALUES'
  | 'SAVE_SEARCH'
  | 'SAVE_LISTING'
  | 'SIGNUP_BLOCK_REDIRECT'
  | 'SUBSCRIBE_SUBSITE';

export type TemporaryStorage = Record<ActionType, StateType>;

type ListingApplicationValuesAction = {
  type: 'LISTING_APPLICATION_VALUES';
  data: ListingApplicationPayload;
};

type SaveSearchAction = {
  type: 'SAVE_SEARCH';
  data: ApiSavedSearchUnsaved;
};

type CmsSubscribeAction = {
  type: 'CMS_SUBSCRIBE';
  data: CmsApiSubscribe;
};

type SignupBlockRedirectAction = {
  type: 'SIGNUP_BLOCK_REDIRECT';
  data: SignupBlockRedirect;
};

type SaveListingAction = {
  type: 'SAVE_LISTING';
  data: SaveListingType;
};

type SusbscribeSubsiteAction = {
  type: 'SUBSCRIBE_SUBSITE';
  data: SubscribeSubsiteType;
};

type SurveyReportDataAction = {
  type: 'SURVEY_REPORT_DATA';
  data: SalariesReportFormData;
};

export type Action =
  | ListingApplicationValuesAction
  | SaveSearchAction
  | CmsSubscribeAction
  | SignupBlockRedirectAction
  | SaveListingAction
  | SusbscribeSubsiteAction
  | SurveyReportDataAction;

export async function saveTemporaryStorage(actions: Action[]) {
  return postJson<{ data: { id: string } }>('/data/tmp-storage', {
    data: JSON.stringify(actions),
  }).then(({ data: { id } }) => id);
}

export function generateRedirectUrl(redirectTo: string, id: string | null) {
  if (redirectTo && id) {
    const param = redirectTo.includes('?') ? '&tmpId' : '?tmpId';
    return `${redirectTo}${param}=${id}`;
  }

  return redirectTo;
}
