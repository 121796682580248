import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useIsFirstRenderRef } from 'hooks/useIsFirstRenderRef';
import {
  clearFlashMessages,
  pushFlashMessage,
} from 'zustand-stores/flashMessagesStore';

export function SetFlashMessages() {
  const location = useLocation<{ flashMessage?: FlashMessageType }>();

  const { isFirstRenderRef } = useIsFirstRenderRef();
  useEffect(() => {
    if (isFirstRenderRef.current) return;
    clearFlashMessages();
  }, [isFirstRenderRef, location.pathname]);

  const locationStateFlashMessage = location.state?.flashMessage;
  useEffect(() => {
    if (locationStateFlashMessage) pushFlashMessage(locationStateFlashMessage);
  }, [locationStateFlashMessage]);

  return null;
}
