import { useCallback, useState } from 'react';

import { ViewportBreakpoint } from 'theme/theme';
import { getViewportBreakpoint } from 'utils/viewport/getViewportBreakpoint';

import { useViewportSizeEffect } from './useViewportSizeEffect';

export function useViewportBreakpoint(): ViewportBreakpoint {
  const [viewportBreakpoint, setViewportBreakpoint] =
    useState<ViewportBreakpoint>(ViewportBreakpoint.md);

  useViewportSizeEffect(
    useCallback(() => {
      const newViewportBreakpont = getViewportBreakpoint();

      if (viewportBreakpoint !== newViewportBreakpont) {
        setViewportBreakpoint(newViewportBreakpont);
      }
    }, [viewportBreakpoint]),
  );

  return viewportBreakpoint;
}
