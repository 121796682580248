import { userDashboardApiCreateSavedListing } from 'modules/userDashboard/api/savedListing/userDashboardApiCreateSavedListing';
import { userDashboardApiDeleteSavedListing } from 'modules/userDashboard/api/savedListing/userDashboardApiDeleteSavedListing';
import {
  userDashboardApiFetchAllSavedListings,
  userDashboardApiGetFetchAllSavedListingsSsrApiData,
} from 'modules/userDashboard/api/savedListing/userDashboardApiFetchAllSavedListings';

import { userDashboardCreateThingsStore } from './utils/userDashboardCreateThingsStore';

const { createThing, deleteThing, useIsThingsSaved, useThings } =
  userDashboardCreateThingsStore({
    fetch: userDashboardApiFetchAllSavedListings,
    getSsrApiData: userDashboardApiGetFetchAllSavedListingsSsrApiData,
    create: async (id: string, type: string) => {
      await userDashboardApiCreateSavedListing(id, type);
      return { id, type };
    },
    delete: async (id: string, type: string) => {
      await userDashboardApiDeleteSavedListing(id, type);
      return id;
    },
  });

// Actions

export function userDashboardSaveListing(id: string, type: string) {
  return createThing(id, type);
}

export function userDashboardUnsaveListing(id: string, type: string) {
  return deleteThing(id, type);
}

// Hooks

export function useUserDashboardSavedListings() {
  const savedListings = useThings();
  return { savedListings };
}

export function useUserDashboardListingIsSaved(id: string | null | undefined) {
  const listingIsSaved = useIsThingsSaved(id);
  return { listingIsSaved };
}
