import { useEffect } from 'react';
import { create } from 'zustand';

import { HttpError } from 'utils/HttpError';
import { trackError } from 'utils/trackError';

const errorStore = create<{ error: Error | null }>(() => ({
  error: null,
}));

const useErrorStore = errorStore; // The store can be used as a hook

// Actions

export function setError(error: Error | null) {
  errorStore.setState({ error });

  if (error instanceof HttpError && error.statusCode !== 404) {
    trackError(error);
  }
}

export function useSetError(error: Error | undefined) {
  useEffect(() => {
    if (error) setError(error);
  }, [error]);
}

// Hook

export function useError() {
  const error = useErrorStore((state) => state.error);
  return { error };
}
