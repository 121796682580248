/**
 * @deprecated types are incomplete
 *  Take a function that expects varargs and turn it into a function that
    expects an array
*/
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const gather = (fn: (...args: Array<any>) => any) => (args: unknown) =>
  // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
  fn.apply(this, args);
// Take the complement of a function
export const complement =
  <T>(f: (arg0: T) => boolean): ((arg0: T) => boolean) =>
  (...args) =>
    !f(...args);

/**
 * @deprecated types are incomplete
 */
export const partial =
  (
    f: (...args: Array<any>) => any, // eslint-disable-line @typescript-eslint/no-explicit-any
    ...args: Array<unknown>
  ) =>
  (...otherArgs: Array<unknown>) =>
    f(...args, ...otherArgs);

export const identity = <T>(x: T): T => x;

export function identityFilter<T>(x: T | null | undefined | false): x is T {
  return x !== null && x !== undefined && x !== false;
}
