import { useEffect, useState } from 'react';

import { SearchType } from 'modules/search/types/SearchType';

export function usePopularSearches(defaultSearchType: SearchType) {
  const [listingType, setListingType] = useState<SearchType>(defaultSearchType);

  const POPULAR_SEARCHES_BY_LISTING_TYPE: Record<SearchType, string[]> = {
    JOB: [
      getText('Communications'),
      getText('Education'),
      getText('Marketing'),
      getText('Executive Director'),
      getText('Attorney'),
    ],
    INTERNSHIP: [
      getText('Psychology'),
      getText('Law'),
      getText('Legal'),
      getText('Summer'),
      getText('Policy'),
    ],
    VOLOP: [
      getText('Board member'),
      getText('Data analyst'),
      getText('Research'),
      getText('Health'),
      getText('Art'),
    ],
    // TODO: add popular event search
    EVENT: [],
    ORG: [
      getText('Education'),
      getText('Human Rights'),
      getText('Mental Health'),
      getText('Immigration'),
      getText('Health'),
    ],
  };

  useEffect(() => {
    setListingType(defaultSearchType);
  }, [defaultSearchType]);

  return {
    popularSearches: POPULAR_SEARCHES_BY_LISTING_TYPE[listingType],
    listingTypeForPopularSearches: listingType,
    setListingTypeForPopularSearches: setListingType,
  };
}
