export const LOCALES = [
  {
    id: 'en',
    name: 'English',
  },
  {
    id: 'es',
    name: 'Español',
  },
  {
    id: 'pt',
    name: 'Português',
  },
] as const satisfies Array<{ id: UserLocale; name: string }>;

export const LOCALES_WITH_ALL = [
  {
    id: 'all',
    name: 'All',
  },
  ...LOCALES,
];
