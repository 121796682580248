import { Navigate, RouteObject } from 'react-router-dom';

import { NotFoundContentLoadable } from 'components/NotFoundContent/NotFoundContentLoadable';
import { RequireAuthentication } from 'containers/RequireAuthentication';
import { LayoutHandleProps } from 'layouts/Layout/LayoutHandleProps';
import { userDashboardApiFetchGeneralSettings } from 'modules/userDashboard/api/generalSettings/userDashboardApiFetchGeneralSettings';
import { UserDashboardAccountSettingsPageLoadable } from 'modules/userDashboard/pages/AccountSettings/UserDashboardAccountSettingsPageLoadable';
import { AddPaymentMethodPageContainerLoadable } from 'modules/userDashboard/pages/AddPaymentMethod/AddPaymentMethodPageContainerLoadable';
import { ApplicationHistoryPageContainerLoadable } from 'modules/userDashboard/pages/ApplicationHistory/ApplicationHistoryPageContainerLoadable';
import { UserDashboardCreateEventPageLoadable } from 'modules/userDashboard/pages/CreateEvent/UserDashboardCreateEventPageLoadable';
import { UserDashboardEditEventPageContainerLoadable } from 'modules/userDashboard/pages/EditEvent/UserDashboardEditEventPageContainerLoadable';
import { UserDashboardEmploymentProfileOnboardingPageLoadable } from 'modules/userDashboard/pages/EmploymentProfileOnboarding/UserDashboardEmploymentProfileOnboardingPageLoadable';
import { ManageEventsPageLoadable } from 'modules/userDashboard/pages/ManageEvents/ManageEventsPageLoadable';
import { NotificationPreferencesPageLoadable } from 'modules/userDashboard/pages/NotificationPreferences/NotificationPreferencesLoadable';
import { UserDashboardPostAListingPageLoadable } from 'modules/userDashboard/pages/PostAListing/UserDashboardPostAListingPageLoadable';
import { UserDashboardProfessionalExperiencePageLoadable } from 'modules/userDashboard/pages/ProfessionalExperience/UserDashboardProfessionalExperiencePageLoadable';
import { UserDashboardProfilePageLoadable } from 'modules/userDashboard/pages/Profile/UserDashboardProfilePageLoadable';
import { UserDashboardSavedItemsPageLoadable } from 'modules/userDashboard/pages/SavedItems/UserDashboardSavedItemsPageLoadable';
import { OrgDashboardSelectOrgPageLoadable } from 'modules/userDashboard/pages/SelectOrgDashboard/OrgDashboardSelectOrgPageLoadable';
import { UserDashboardViewEventPageLoadable } from 'modules/userDashboard/pages/ViewEvent/UserDashboardViewEventPageLoadable';
import { UserDashboardSubLayoutLoadable } from 'modules/userDashboard/sub-layout/UserDashboardSubLayoutLoadable';
import { SIGN_UP_TO_POST_URL_FOR_LOCALE } from 'routing/route-constants';
import { loadableLoader } from 'routing/utils/loadableLoader';
import { renderLoader } from 'routing/utils/renderLoader/renderLoader';
import { WithRouterParams } from 'utils/WithRouterParams';

import {
  userDashboardAccountSettingsRoute,
  userDashboardApplicationHistoryRoute,
  userDashboardCreateEventRoute,
  userDashboardEditEventRoute,
  userDashboardManageEventsRoute,
  userDashboardNotificationPreferencesRoute,
  userDashboardOldEmploymentProfileRoute,
  userDashboardPaymentMethodsAddCardRoute,
  userDashboardProfessionalExperienceRoute,
  userDashboardProfileRoute,
  userDashboardRouteGroup,
  userDashboardSavedItemsRoute,
  userDashboardViewEmploymentOnboardingRoute,
  userDashboardViewEventRoute,
} from './userDashboard.routes';

export const userDashboardRouteObjects = [
  {
    handle: {
      variant: 'base',
    } satisfies LayoutHandleProps,
    path: '/post-a-listing',
    element: (
      <RequireAuthentication
        loginPathname={SIGN_UP_TO_POST_URL_FOR_LOCALE[CURRENT_LOCALE]}
      >
        <UserDashboardPostAListingPageLoadable />
      </RequireAuthentication>
    ),
  },
  {
    handle: { variant: 'base' } satisfies LayoutHandleProps,
    path: userDashboardCreateEventRoute.fullPath,
    element: (
      <RequireAuthentication>
        <UserDashboardCreateEventPageLoadable />
      </RequireAuthentication>
    ),
  },
  {
    path: userDashboardViewEmploymentOnboardingRoute.fullPath,
    Component: UserDashboardEmploymentProfileOnboardingPageLoadable,
  },
  {
    handle: {
      darkBackground: true,
      fallbackUrlByLocaleToCurrent: true,
      showDesktopSearch: true,
    } satisfies LayoutHandleProps,
    children: [
      {
        path: userDashboardViewEventRoute.fullPath,
        element: (
          <RequireAuthentication>
            <WithRouterParams
              render={({ eventId }) => (
                <UserDashboardViewEventPageLoadable eventId={eventId} />
              )}
            />
          </RequireAuthentication>
        ),
      },
      {
        ...renderLoader({
          loader: async () => {
            const [initialGeneralSettings] = await Promise.all([
              userDashboardApiFetchGeneralSettings(),
              UserDashboardSubLayoutLoadable.load(),
            ]);

            return initialGeneralSettings;
          },
          render: (data) => (
            <RequireAuthentication>
              <UserDashboardSubLayoutLoadable initialGeneralSettings={data} />
            </RequireAuthentication>
          ),
        }),
        // Don't revalidate when search params change, as they won't influence this loader
        shouldRevalidate: () => false,
        children: [
          {
            path: '/select-org',
            Component: OrgDashboardSelectOrgPageLoadable,
            loader: loadableLoader(OrgDashboardSelectOrgPageLoadable),
          },
          {
            path: userDashboardEditEventRoute.fullPath,
            element: (
              <WithRouterParams
                render={({ eventId }) => (
                  <UserDashboardEditEventPageContainerLoadable
                    eventId={eventId}
                  />
                )}
              />
            ),
          },
          {
            path: userDashboardManageEventsRoute.fullPath,
            Component: ManageEventsPageLoadable,
            loader: loadableLoader(ManageEventsPageLoadable),
          },
          {
            path: userDashboardNotificationPreferencesRoute.fullPath,
            Component: NotificationPreferencesPageLoadable,
            loader: loadableLoader(NotificationPreferencesPageLoadable),
          },
          {
            path: userDashboardProfileRoute.fullPath,
            Component: UserDashboardProfilePageLoadable,
            loader: loadableLoader(UserDashboardProfilePageLoadable),
          },
          {
            path: userDashboardOldEmploymentProfileRoute.fullPath,
            element: (
              <Navigate
                to={userDashboardProfessionalExperienceRoute.with({})}
              />
            ),
          },
          {
            path: userDashboardProfessionalExperienceRoute.fullPath,
            Component: UserDashboardProfessionalExperiencePageLoadable,
            loader: loadableLoader(
              UserDashboardProfessionalExperiencePageLoadable,
            ),
          },
          {
            path: userDashboardAccountSettingsRoute.fullPath,
            Component: UserDashboardAccountSettingsPageLoadable,
            loader: loadableLoader(UserDashboardAccountSettingsPageLoadable),
          },
          {
            path: userDashboardPaymentMethodsAddCardRoute.fullPath,
            Component: AddPaymentMethodPageContainerLoadable,
            loader: loadableLoader(AddPaymentMethodPageContainerLoadable),
          },
          {
            path: userDashboardSavedItemsRoute.fullPath,
            Component: UserDashboardSavedItemsPageLoadable,
            loader: loadableLoader(UserDashboardSavedItemsPageLoadable),
          },
          {
            path: userDashboardApplicationHistoryRoute.fullPath,
            Component: ApplicationHistoryPageContainerLoadable,
            loader: loadableLoader(ApplicationHistoryPageContainerLoadable),
          },
          {
            path: userDashboardRouteGroup.fullWrapperPath,
            element: <NotFoundContentLoadable textOnly />,
          },
        ],
      },
    ],
  },
] satisfies RouteObject[];
