import { RouteObject } from 'react-router-dom';

import { CmsContentPageLoadable } from 'modules/cms/containers/ContentPage/CmsContentPageLoadable';
import { CmsContentPageFullReload } from 'modules/cms/containers/ContentPageFullReload/CmsContentPageFullReload';
import { PostSearchPageLoadable } from 'modules/postSearch/pages/PostSearch/PostSearchPageLoadable';
import { SearchLandingPageLoadable } from 'modules/search/pages/Landing/SearchLandingPageLoadable';
import { NotFoundPageLoadable } from 'pages/NotFound/NotFoundPageLoadable';
import { renderLoader } from 'routing/utils/renderLoader/renderLoader';

import { cmsPageLoader } from './cmsPageLoader';
import { cmsRouteShouldRevalidate } from './cmsRouteShouldRevalidate';

export const cmsRouteId = 'cms-route-';

export const cmsRouteObjects = [
  {
    id: cmsRouteId,
    path: '/*',
    ...renderLoader({
      loader: cmsPageLoader,
      render: function CmsPageSwitch(data) {
        switch (data?.type) {
          case undefined:
            return <NotFoundPageLoadable noLayout />;
          case 'CONTENT_FULL_RELOAD':
            return <CmsContentPageFullReload url={data.url} />;
          case 'CONTENT':
            return <CmsContentPageLoadable content={data.content} />;
          case 'SEARCH_LANDING_PAGE':
            return (
              <SearchLandingPageLoadable
                content={data.content}
                isSsr={data.isSsr}
              />
            );
          case 'POST_SEARCH':
            return <PostSearchPageLoadable content={data.content} />;
        }
      },
    }),
    shouldRevalidate: cmsRouteShouldRevalidate,
  },
] satisfies RouteObject[];
