import { Color, colors } from 'theme/theme';

import {
  MaskImageIconDiv,
  maskImageIconSrcUrlVar,
} from './MaskImageIcon.styled';

type Props = {
  src: string;
  size: number;
  opacity: number | undefined;
  colorName: Color | undefined;
  qaId: string | undefined;
  alt: string;
};

export function MaskImageIcon({
  src,
  size,
  opacity,
  colorName,
  qaId,
  alt,
}: Props) {
  return (
    <MaskImageIconDiv
      data-qa-id={qaId}
      role="img"
      aria-label={alt}
      style={{
        width: size,
        height: size,
        opacity,
        color: colorName ? colors[colorName] : undefined,
        [maskImageIconSrcUrlVar]: `url(${src})`,
      }}
    />
  );
}
