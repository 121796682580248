export const PAYMENT_LEVELS = [
  {
    id: 'HOURLY',
    name:
      /* TRANSLATORS: payment level */
      getText('Hourly'),
  },
  {
    id: 'SALARY',
    name:
      /* TRANSLATORS: payment level */
      getText('Salary'),
  },
  {
    id: 'STIPEND',
    name:
      /* TRANSLATORS: payment level */
      getText('Stipend'),
  },
];

export type PaymentLevel = (typeof PAYMENT_LEVELS)[number]['id'];
