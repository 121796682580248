import styled from 'styled-components';

import { colors, cssBreakpoints } from 'theme/theme';

export const AcceptCookiesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: ${colors.white};
  color: ${colors.mediumContentGrey};
  text-align: center;
  padding: 12px;
  border-top: solid 1px ${colors.elementGrey};

  @media all and (min-width: ${cssBreakpoints.mdUp}) {
    height: 56px;
    flex-direction: row;
    border-top: solid 1px ${colors.elementGrey};
  }
`;
