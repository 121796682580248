import { SearchLocation } from 'modules/search/types/SearchLocation';
import { AlgoliaSearchOptionsWithIndex } from 'utils/algolia/types/AlgoliaSearchOptionsWithIndex';

import { searchAlgoliaOptionsLocationData } from './searchAlgoliaOptionsLocationData';

type Args = {
  options: AlgoliaSearchOptionsWithIndex;
  searchLocation: SearchLocation | undefined;
  radius: string;
};

export function searchAlgoliaOptionsWithSearchLocation({
  options,
  searchLocation,
  radius,
}: Args): AlgoliaSearchOptionsWithIndex {
  if (!searchLocation?.latitude || !searchLocation?.longitude) return options;

  const { locationFilters, locationOptions } = searchAlgoliaOptionsLocationData(
    radius,
    searchLocation,
  );

  return {
    ...options,
    filters: [options.filters, ...locationFilters]
      .filter(Boolean) // Filter options.filters if it's empty
      .join(' AND '),
    ...locationOptions,
  };
}
