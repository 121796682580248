import styled from 'styled-components';

import { buttonAppearance } from 'components/Button/buttonStyles';
import { ButtonOrLink } from 'components/ButtonOrLink/ButtonOrLink';
import { VersionedRouterLink } from 'components/VersionedLink/VersionedRouterLink';
import { colors, cssBreakpoints, gutterWidth, transition } from 'theme/theme';

const pageHeaderTopSectionHeightVar = '--page-header-top-section-height';
const pageHeaderMobileLinksHeight = 64;

export const PageHeaderHolder = styled.div`
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 101;
  font-size: 15px;
  line-height: normal;
  background: ${colors.white};
  box-shadow: 0 2px 4px rgb(0 0 0 / 7.5%);
  transition: ${transition};

  @media print {
    display: none;
  }
`;

export const PageHeaderTopSectionHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${gutterWidth / 2}px;
  height: var(${pageHeaderTopSectionHeightVar});
  position: relative;

  @media screen and (max-width: ${cssBreakpoints.mdDown}) {
    ${pageHeaderTopSectionHeightVar}: 60px;
  }

  @media screen and (min-width: ${cssBreakpoints.mdUp}) {
    ${pageHeaderTopSectionHeightVar}: 58px;
  }
`;

export const PageHeaderLeft = styled.div`
  display: flex;
  align-items: center;
`;

export const PageHeaderLinksHolder = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  @media (max-width: ${cssBreakpoints.mdDown}) {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: ${pageHeaderMobileLinksHeight}px;
    border-top: 1px solid ${colors.selectionGrey};
    justify-content: space-evenly;
    gap: 0;
  }
`;

export const PageHeaderDesktopDonateLinkHolder = styled.div`
  @media (max-width: ${cssBreakpoints.mdDown}) {
    display: none;
  }
`;

export const PageHeaderRight = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;

export const PageHeaderLogoLink = styled(VersionedRouterLink)`
  display: inline-flex;
  margin-right: 24px;
`;

export const PageHeaderCartDropdownButtonHolder = styled.div`
  display: flex;
  align-items: center;
  margin-left: 6px;

  @media (max-width: ${cssBreakpoints.mdDown}) {
    display: none;
  }
`;

export const PageHeaderNotificationsPillHolder = styled.div`
  @media (min-width: ${cssBreakpoints.mdUp}) {
    margin-left: 6px;
  }
`;

export const PageHeaderAuthLink = styled.a`
  padding: 0 4px;
  color: ${colors.mediumContentGrey};
  font-size: inherit;
  transition: color ${transition};

  &,
  &:hover,
  &:focus {
    text-decoration: none;
  }

  &:hover,
  &:focus {
    color: ${colors.brandBlue};
  }
`;

const pageHeaderAuthHolderDesktopGap = 16;
export const PageHeaderAuthHolder = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  gap: ${pageHeaderAuthHolderDesktopGap}px;

  @media (max-width: ${cssBreakpoints.mdUp}) {
    margin-right: ${pageHeaderAuthHolderDesktopGap / 2}px;
  }

  a {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      right: -${pageHeaderAuthHolderDesktopGap / 2}px;
      transform: translateY(-50%);
      width: 1px;
      height: 20px;
      background: ${colors.elementGrey};
      opacity: 0.5;
    }

    @media (min-width: ${cssBreakpoints.mdUp}) {
      &:last-child {
        &::before {
          display: none;
        }
      }
    }
  }
`;

export const PageHeaderMobileSearchHolder = styled.div`
  @media all and (min-width: ${cssBreakpoints.mdUp}) {
    display: none;
  }
`;

export const PageHeaderMyAccountHolder = styled.div`
  &::before {
    display: none;
  }
`;

export const PageHeaderMobileDonateButtonHolder = styled.div`
  font-size: 16px;

  @media all and (min-width: ${cssBreakpoints.smUp}) {
    margin-left: 8px;
  }

  @media all and (min-width: ${cssBreakpoints.mdUp}) {
    display: none;
  }

  @media all and (width <= 380px) {
    display: none;
  }
`;

export const PageHeaderMobileDonateButtonOrLink = styled(ButtonOrLink)`
  display: inline-flex;
  ${buttonAppearance};
  background-color: ${colors.brandBlue};
  border-color: ${colors.brandBlue};
  color: ${colors.white};

  &:hover,
  &:focus {
    background-color: ${colors.hoverBlue};
    border-color: ${colors.hoverBlue};
    text-decoration: none;
    color: ${colors.white};
  }

  font-size: 14px;
  height: 28px;
  padding: 0.4em 0.6em;
  text-decoration: none;
`;

/**
 * The links are place on top of this `div` using `position: absolute`
 */
export const PageHeaderMobileLinksPlaceholder = styled.div`
  @media all and (max-width: ${cssBreakpoints.mdDown}) {
    height: ${pageHeaderMobileLinksHeight}px;
  }

  @media screen and (min-width: ${cssBreakpoints.mdUp}) {
    display: none;
  }
`;

export const PageHeaderDesktopSearchHolder = styled.div`
  padding: 16px ${gutterWidth / 2}px;
  background-color: ${colors.white};
  border-top: 1px solid ${colors.selectionGrey};

  @media all and (max-width: ${cssBreakpoints.mdDown}) {
    display: none;
  }
`;
