import { TrackJS } from 'trackjs';

import { log } from 'utils/logging';

import { RichError } from './RichError';

export function trackError(message: string | Error) {
  const metadata: Record<string, unknown> =
    message instanceof RichError ? message.metadata : {};

  if (!TrackJS.isInstalled()) {
    log.error(message, metadata);
    return;
  }

  Object.keys(metadata).forEach((key) => {
    TrackJS.addMetadata(key, metadata[key] as string);
  });
  TrackJS.track(message);
  Object.keys(metadata).forEach((key) => {
    TrackJS.removeMetadata(key);
  });
}
