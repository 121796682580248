import { apiSetABExperimentBucket } from 'api/abExperiment/apiSetABExperimentBucket';
import {
  ApiABExperimentBucketForId,
  ApiABExperimentId,
} from 'api/abExperiment/types/ApiABExperiment';
import {
  apiFetchUserEnvironment,
  apiGetFetchUserEnvironmentSsrApiData,
} from 'api/userEnvironment/apiFetchUserEnvironment';
import {
  ApiEnvironment,
  ApiUser,
  ApiUserEnvironment,
} from 'api/userEnvironment/types/ApiUserEnvironment';
import { searchGetInitialLocation } from 'modules/search/helpers/searchGetInitialLocation';
import { updateSearchLocation } from 'modules/search/zustand-stores/searchStore';

import { createSsrApiDataStore } from './utils/createSsrApiDataStore';

type UserEnvironmentStoreState = {
  user: ApiUser | null;
  environment: ApiEnvironment | null;
};

const { store: userEnvironmentStore, hook: useUserEnvironmentStore } =
  createSsrApiDataStore<UserEnvironmentStoreState>({
    getSsrState: () => apiGetFetchUserEnvironmentSsrApiData(),
    fallbackState: { user: null, environment: null },
  });

// Actions

export async function fetchUserEnvironment() {
  const { user, environment } = await apiFetchUserEnvironment();

  userEnvironmentStore.setState({ user, environment });

  // Read searchLocation from localstorage
  // @TODO replace fallback to searchLocation with guessedLocation
  updateSearchLocation(
    searchGetInitialLocation(environment.guessedLocation),
    false,
  );

  return { user, environment };
}

export function setUserEnvironment(userEnvironment: ApiUserEnvironment) {
  const { user, environment } = userEnvironment;
  userEnvironmentStore.setState({ user, environment });
}

export function updateUserPartially(partialUser: Partial<ApiUser>) {
  const { user } = userEnvironmentStore.getState();
  if (user)
    userEnvironmentStore.setState({ user: { ...user, ...partialUser } });
}

export function updateEnvironmentPartially(
  partialEnvironment: Partial<ApiEnvironment>,
) {
  const { environment } = userEnvironmentStore.getState();
  if (environment)
    userEnvironmentStore.setState({
      environment: { ...environment, ...partialEnvironment },
    });
}

export async function setABExperimentBucket<
  TExperimentId extends ApiABExperimentId,
>(
  experimentId: TExperimentId,
  bucket: ApiABExperimentBucketForId<TExperimentId>,
) {
  const abExperiments = await apiSetABExperimentBucket(experimentId, bucket);

  const { environment } = userEnvironmentStore.getState();
  if (environment) {
    userEnvironmentStore.setState({
      environment: { ...environment, abExperiments },
    });
  }
}

// Getters

export function getUserEnvironment() {
  const { user, environment } = userEnvironmentStore.getState();
  return { user, environment };
}

export function onUserEnvironmentStoreUpdated(
  listener: (state: UserEnvironmentStoreState) => void,
) {
  userEnvironmentStore.subscribe(listener);
}

// Hooks

export function useIsUserEnvironmentLoaded() {
  const isUserEnvironmentLoaded = useUserEnvironmentStore((state) =>
    Boolean(state.environment),
  );

  return { isUserEnvironmentLoaded };
}

export function useUser(): { user: ApiUser | null } {
  const user = useUserEnvironmentStore((state) => state.user);
  return { user };
}

export function useEnvironment() {
  const environment = useUserEnvironmentStore((state) => state.environment);
  return { environment };
}

/**
 * @deprecated
 *
 * Remove this method and use `useUser` and `useEnvironment` directly
 */
export function useUserEnvironment() {
  const { user } = useUser();
  const { environment } = useEnvironment();

  return {
    user,
    environment,
    isUserLoaded: Boolean(environment),
    isEnvironmentLoaded: Boolean(environment),
    updateUserEnvironment: setUserEnvironment,
  };
}
