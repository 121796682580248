import { MouseEventHandler } from 'react';

import { Button } from 'components/Button/Button';

type Props = {
  ariaLabel?: string;
  onClear?: MouseEventHandler<HTMLButtonElement>;
  qaId?: string;
};

export function InputClearButtonSuffix({ ariaLabel, onClear, qaId }: Props) {
  return (
    <div style={{ display: 'inline-flex', paddingRight: 20 }}>
      <Button
        type="button"
        data-qa-id={qaId}
        variant="tertiary-grey"
        size="tiny"
        icon={{ name: 'close', type: 'normalized', size: 16 }}
        onClick={onClear}
        aria-label={ariaLabel || getText('Clear input')}
        noPadding
      />
    </div>
  );
}
