import { GradFairsBannerLoadable } from 'components/Banners/GradFairsBannerLoadable';
import { ResumeDatabaseBannerLoadable } from 'components/Banners/ResumeDatabaseBannerLoadable';
import { SalarySurveyBannerLoadable } from 'components/Banners/SalarySurveyBannerLoadable';
import { ClientOnly } from 'containers/ClientOnly';
import { AdSection } from 'types/Ads/AdSection';
import { useAdType } from 'zustand-stores/adStore';

import { BannerAdsBox } from './BannerAds.styled';

const adComponents = {
  GRAD_FAIRS: GradFairsBannerLoadable,
  SALARY_SURVEY: SalarySurveyBannerLoadable,
  RESUME_DB: ResumeDatabaseBannerLoadable,
};

export function BannerAds() {
  const adType = useAdType(AdSection.BANNER);
  const CurrentAd = adType ? adComponents[adType] : null;

  return (
    <BannerAdsBox
      data-qa-id="ads-container"
      mt={[60, null, null, 0]}
      mb={[-60, null, null, 0]}
    >
      <ClientOnly>{CurrentAd ? <CurrentAd /> : null}</ClientOnly>
    </BannerAdsBox>
  );
}
