import 'trackjs';

import { bootstrapAnalytics } from 'utils/analytics/setup/bootstrapAnalytics';
import { initXMLHttpRequestActiveCounter } from 'utils/http/initXMLHttpRequestActiveCounter';
import { trackError } from 'utils/trackError';

export function renderingBootstrapClient() {
  bootstrapAnalytics();

  if (window.Idealist.debugXhr) {
    initXMLHttpRequestActiveCounter();
  }

  const { errorsBeforeTrackJsInstalled, trackErrorBeforeTrackJs } =
    window.Idealist;

  if (errorsBeforeTrackJsInstalled && trackErrorBeforeTrackJs) {
    errorsBeforeTrackJsInstalled.forEach((error) => trackError(error));
    window.removeEventListener('error', trackErrorBeforeTrackJs);
  }
}
