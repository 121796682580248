import { ReactNode, useMemo } from 'react';
import { createPortal } from 'react-dom';

import { useIsomorphicLayoutEffect } from 'hooks/useIsomorphicLayoutEffect';

type Props = {
  children: ReactNode;
  disabled?: boolean;
  id?: string;
  className?: string;
  prepend?: boolean;
  container?: HTMLElement | null;
};

export function Portal({
  children,
  disabled,
  id,
  className,
  prepend,
  container,
}: Props) {
  const node = useMemo(
    () =>
      // document does not exist in Hypernova
      global.document?.createElement('div'),
    [],
  );

  if (node) {
    node.id = id || '';
    node.className = className || '';
  }

  useIsomorphicLayoutEffect(() => {
    if (!node) return undefined;

    const containerEl = container || document.body;

    if (prepend) {
      containerEl.prepend(node);
    } else {
      containerEl.appendChild(node);
    }

    return () => {
      containerEl.removeChild(node);
    };
  }, [node, container, prepend]);

  if (disabled) {
    return children;
  }

  return node ? createPortal(children, node) : null;
}
