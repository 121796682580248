import { ApiFilestackImageWithTextData } from 'api/types/ApiFilestackImageData';

export type CmsApiThreeStepCtaType = 'THREE_STEP_CTA';

export type CmsApiThreeStepCtaBlock = {
  id: string;
  type: CmsApiThreeStepCtaType;
  data: CmsApiThreeStepCtaBlockData;
};

export const cmsThreeStepCtaBlockSteps = ['One', 'Two', 'Three'] as const;

export type CmsApiThreeStepCtaBlockData = {
  /**
   * HTML
   */
  mainHeadline: string | null;
  mainText: string | null;
  overlap: boolean;
  buttonText: string | null;
  buttonUrl: string | null;
  imageSize: 'SMALL' | 'LARGE';
  stepOneImage: ApiFilestackImageWithTextData | null;
  stepOneHeadline: string;
  /**
   * HTML
   */
  stepOneText: string;
  stepOneUrl: string | null;
  stepTwoImage: ApiFilestackImageWithTextData | null;
  stepTwoHeadline: string;
  /**
   * HTML
   */
  stepTwoText: string;
  stepTwoUrl: string | null;
  stepThreeImage: ApiFilestackImageWithTextData | null;
  stepThreeHeadline: string;
  /**
   * HTML
   */
  stepThreeText: string;
  stepThreeUrl: string | null;
};
