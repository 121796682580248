import styled from 'styled-components';

import { colors, gutterWidth } from 'theme/theme';

export const PageHeaderMobileSearchMenuHolder = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: ${colors.white};
  z-index: 104;
`;

export const PageHeaderMobileSearchMenuHeader = styled.div`
  align-items: center;
  background: ${colors.white};
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 0 ${gutterWidth / 2}px;
  height: 60px;
  width: 100%;
  border-bottom: 1px solid ${colors.selectionGrey};
`;

export const PageHeaderMobileSearchMenuBody = styled.div`
  padding: 36px ${gutterWidth / 2}px;
`;

export const PageHeaderSearchInputsMobileForm = styled.form<{
  $isTabbing?: boolean;
}>`
  --page-header-search-gap: 12px;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: var(--page-header-search-gap);
  padding: 0;
  font-size: 18px;
`;

export const PageHeaderSearchButtonWrapper = styled.div`
  padding-block-start: calc(40px - var(--page-header-search-gap));
`;
