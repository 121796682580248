import { useRef } from 'react';
import deepEqual from 'react-fast-compare';

/**
 * Keeps a stable reference to the value until it changes, using deep comparison
 */
export function useStableDeepObject<TValue>(value: TValue): TValue {
  const stableValueRef = useRef(value);

  if (
    value !== stableValueRef.current &&
    !deepEqual(value, stableValueRef.current)
  ) {
    stableValueRef.current = value;
  }

  return stableValueRef.current;
}
