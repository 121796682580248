import { useEffect, useRef } from 'react';

/**
 * Used to skip doing something on the first render without re-rendering
 */
export function useIsFirstRenderRef() {
  const isFirstRenderRef = useRef(true);

  useEffect(() => {
    setTimeout(() => {
      isFirstRenderRef.current = false;
    });
  }, []);

  return { isFirstRenderRef };
}
