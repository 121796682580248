export const VOLOP_FUNCTIONS = {
  ACCOUNTING_FINANCE: getText('Accounting & Finance'),
  ADMINISTRATIVE_OFFICE_SUPPORT: getText('Administrative/Office Support'),
  ADVOCACY: getText('Advocacy'),
  ANIMAL_CARE: getText('Animal Care'),
  ARCHIVING_DATA_ENTRY: getText('Archiving/Data Entry'),
  ARTS_CRAFTS: getText('Arts & Crafts'),
  BOARD_COMMITTEE: getText('Board/Committee'),
  CITIZENSHIP: getText('Citizenship'),
  COMMUNICATIONS_MARKETING: getText('Communications & Marketing'),
  COMMUNITY_BELONGING: getText('Community Belonging'),
  CORPORATE_PHILANTHROPY: getText('Corporate Philanthropy'),
  CORPORATE_RESPONSIBILITY: getText('Corporate Responsibility'),
  DATA_ANALYSIS: getText('Data Analysis'),
  DESIGN: getText('Design'),
  DISASTER_RELIEF: getText('Disaster Relief'),
  DIVERSITY_EQUITY_INCLUSION: getText('Diversity, Equity & Inclusion'),
  DONATION_DRIVE: getText('Donation Drive'),
  ELDER_CARE: getText('Elder Care'),
  EMPLOYEE_ENGAGEMENT: getText('Employee Engagement'),
  EVENT_SUPPORT: getText('Event Support'),
  FOOD_SERVICE: getText('Food Service'),
  FUNDRAISING: getText('Fundraising'),
  HEALTH_WELLNESS: getText('Health & Wellness'),
  LANGUAGES: getText('Languages'),
  LEGAL: getText('Legal'),
  MENTOR_TUTOR: getText('Mentor/Tutor'),
  MUSIC: getText('Music'),
  PARTNERSHIPS: getText('Partnerships'),
  PHOTOGRAPHY_VIDEOGRAPHY: getText('Photography/Videography'),
  PHYSICAL_SERVICE: getText('Physical Service (Painting, Farming, etc.)'),
  PROJECT_RESEARCH_STUDY: getText('Project or Research Study'),
  PUBLIC_SPEAKING: getText('Public Speaking'),
  SPORTS_FITNESS_COACHING: getText('Sports & Fitness/Coaching'),
  SUSTAINABILITY: getText('Sustainability'),
  TECHNOlOGY_SUPPORT_WEB_DESIGN: getText('Technology Support & Web Design'),
} as const satisfies Record<string, string>;

export const VOLOP_FUNCTIONS_OPTIONS = Object.entries(VOLOP_FUNCTIONS).map(
  ([key, value]) => ({ value: key, label: value }),
);
