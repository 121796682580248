import { localizedRouteGroup } from 'routing/routes';

import {
  JOB_URL_SEGMENTS,
  combinedOrgTypeAndJobUrlSegments,
} from './job.route-segments';

export const jobSegmentedRoutes = [
  ...JOB_URL_SEGMENTS,
  ...combinedOrgTypeAndJobUrlSegments,
].map((jobUrlSegment) =>
  localizedRouteGroup.route(`${jobUrlSegment}/:jobIdDashSlug`, [
    'noSuggestions',
  ]),
);
