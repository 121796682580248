import { getUTF8TextBytes } from './getUTF8TextBytes';

export function truncateToUTF8Bytes(text: string, maxBytes: number): string {
  let totalLength = text.length;
  let totalSize = getUTF8TextBytes(text);

  while (totalSize > maxBytes) {
    totalLength -= 1;
    totalSize -= getUTF8TextBytes(text[totalLength]);
  }

  return text.slice(0, totalLength);
}
