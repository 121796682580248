import { ApiOrgId } from 'api/types/ApiTypedId';
import { FetchEndpoint } from 'utils/endpoint/FetchEndpoint';

import { ApiOrg } from './types/ApiOrg';
import { ApiUnpublishedOrg } from './types/ApiUnpublishedOrg';

type ApiResponse = {
  type: 'ORG' | 'UNPUBLISHED_ORG';
  data: ApiOrg | ApiUnpublishedOrg;
};

function mapResponse(response: ApiResponse): ApiOrg | ApiUnpublishedOrg {
  return response.data;
}

const endpoint = new FetchEndpoint({
  urlFactory: (orgId: ApiOrgId) => `/data/website/organizations/${orgId}`,
  mapResponse,
});

// Exports

export const listingApiFetchOrg = endpoint.createFetchFn();
