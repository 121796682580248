import { searchAlgoliaOptionsFiltersForFacets } from 'modules/search/algolia/options/searchAlgoliaOptionsFiltersForFacets';
import { searchAlgoliaVolopsIndex } from 'modules/search/algolia/searchAlgoliaVolopsIndex';
import { ActionDateFacetValue } from 'modules/search/constants/searchFacets/actionDateFacet';
import { searchFacetsVolop } from 'modules/search/constants/searchFacetsVolop';
import { SearchFiltersById } from 'modules/search/types/SearchFiltersById';
import { SearchLocation } from 'modules/search/types/SearchLocation';
import { SearchSortType } from 'modules/search/types/SearchSortType';
import { algoliaQueryStringFilter } from 'utils/algolia/helpers/algoliaQueryStringFilter';
import { AlgoliaSearchOptionsWithIndex } from 'utils/algolia/types/AlgoliaSearchOptionsWithIndex';
import { SEARCH_PAGE_SIZE } from 'utils/constants/general/searchPageSize';

const attributesToRetrieve = ['*'];

type Args = {
  filtersById: SearchFiltersById;
  sort: SearchSortType;
  searchLocation: SearchLocation | undefined;
};

export function searchAlgoliaVolopsOptions({
  filtersById,
  sort,
  searchLocation,
}: Args): AlgoliaSearchOptionsWithIndex {
  const actionDate = filtersById.actionDateFacet as
    | ActionDateFacetValue
    | null
    | undefined;

  return {
    index: searchAlgoliaVolopsIndex({ actionDate, sort }),
    facets: ['*'],
    hitsPerPage: SEARCH_PAGE_SIZE,
    attributesToSnippet: ['description:20'],
    attributesToRetrieve,
    filters: [
      `NOT ${algoliaQueryStringFilter('actionType', 'EVENT')}`,
      ...searchAlgoliaOptionsFiltersForFacets({
        filtersById,
        searchFacets: searchFacetsVolop,
        searchLocation,
      }),
    ].join(' AND '),
    removeStopWords: true,
    ignorePlurals: true,
    advancedSyntax: true,
    queryLanguages: ['en', 'es', 'pt'],
  };
}
