/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/prefer-optional-chain */

if (typeof window !== 'undefined' && window.__webpack_public_path__) {
  // @ts-expect-error TS(2304): Cannot find name '__webpack_public_path__'.
  __webpack_public_path__ = window.__webpack_public_path__;
}

// We must treat this file as an export because isolateModules is set
export {};
/* eslint-enable no-underscore-dangle */
/* eslint-enable @typescript-eslint/prefer-optional-chain */
