import { useEffect } from 'react';

import { setDocumentTitle } from 'utils/meta/setDocumentTitle';

export function useSetDocumentTitle(
  title: string | undefined,
  suffix?: string,
) {
  useEffect(() => {
    if (title) setDocumentTitle(title, suffix);
  }, [suffix, title]);
}
