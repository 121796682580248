import { Outlet, useParams } from 'react-router-dom';

import { ApiOrgId } from 'api/types/ApiTypedId';
import { OrgDashboardOrgContext } from 'modules/orgDashboard/contexts/OrgDashboardOrgContext';
import { useOrgDashboardStoreOrg } from 'modules/orgDashboard/zustand-stores/orgDashboardOrgStore';

export function OrgDashboardOrgContextProviderContainer() {
  const { orgId } = useParams() as unknown as { orgId: ApiOrgId };
  const org = useOrgDashboardStoreOrg(orgId);

  return (
    <OrgDashboardOrgContext.Provider value={org}>
      <Outlet key={orgId} />
    </OrgDashboardOrgContext.Provider>
  );
}
