export type ViewportSize = {
  viewportWidth: number;
  viewportHeight: number;
};

export function getViewportSize(): ViewportSize {
  return {
    viewportWidth: window.innerWidth,
    viewportHeight: window.innerHeight,
  };
}
