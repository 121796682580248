import { SearchLocation } from 'modules/search/types/SearchLocation';

export const EMPTY_SEARCH_LOCATION: SearchLocation = {
  boundingBoxE: null,
  boundingBoxN: null,
  boundingBoxS: null,
  boundingBoxW: null,
  countryCode: null,
  geoType: null,
  latitude: null,
  longitude: null,
  stateCode: null,
  text: null,
  hasGeo: false,
};
