type Props = {
  keyword: string;
  defaultKeyword: string;
};

export function createRemotePrediction({ keyword, defaultKeyword }: Props) {
  return {
    description:
      keyword.toLowerCase() === defaultKeyword.toLowerCase()
        ? keyword
        : `${keyword} (${defaultKeyword})`,
    structured_formatting: {
      main_text:
        keyword.toLowerCase() === defaultKeyword.toLowerCase()
          ? `${keyword}`
          : `${keyword} (${defaultKeyword})`,
      secondary_text: '',
    },
    isRemoteOption: true,
  };
}
