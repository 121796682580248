import { UserDashboardApiGeneralSettings } from 'modules/userDashboard/api/types/UserDashboardApiGeneralSettings';
import { FetchEndpoint } from 'utils/endpoint/FetchEndpoint';

type ApiResponse = {
  generalSettings: UserDashboardApiGeneralSettings;
};

function mapResponse(data: ApiResponse) {
  return data.generalSettings;
}

const endpoint = new FetchEndpoint({
  urlFactory: () => '/data/userdashboard/general-settings',
  mapResponse,
});

// Exports

export const userDashboardApiFetchGeneralSettings = endpoint.createFetchFn();

export const useUserDashboardApiFetchGeneralSettings =
  endpoint.createUseFetchHook();
