import { TrackJS } from 'trackjs';

export function configureTrackJs(userId: string | null | undefined) {
  if (TrackJS.isInstalled()) {
    if (userId) {
      TrackJS.configure({
        userId,
      });
    }
    const applicationContext = window.navigator.userAgent.includes(
      'Idealist Mobile App iOS',
    )
      ? 'ios'
      : 'web';
    TrackJS.addMetadata('applicationContext', applicationContext);
  }
}
