export const JOB_FUNCTIONS = {
  ACCOUNTING_FINANCE: getText('Accounting & Finance'),
  ADMINISTRATIVE: getText('Administrative'),
  ADVOCACY: getText('Advocacy'),
  ARTS_PHOTOGRAPHY: getText('Arts & Photography'),
  BUSINESS_DEVELOPMENT: getText('Business Development'),
  CASE_SOCIAL_WORK: getText('Case/Social Work'),
  CITIZENSHIP: getText('Citizenship'),
  COMMUNICATIONS: getText('Communications'),
  COMMUNITY_BELONGING: getText('Community Belonging'),
  COMMUNITY_ENGAGEMENT_OUTREACH: getText('Community Engagement/Outreach'),
  COMMUNITY_ORGANIZING: getText('Community Organizing'),
  CONSERVATION: getText('Conservation'),
  CORPORATE_PHILANTHROPY: getText('Corporate Philanthropy'),
  CORPORATE_RESPONSIBILITY: getText('Corporate Responsibility'),
  COUNSELING: getText('Counseling'),
  CUSTOMER_SERVICE: getText('Customer Service'),
  DATABASE_ADMINISTRATION: getText('Database Administration'),
  DATA_EVALUATION_ANALYSIS: getText('Data Evaluation/Analysis'),
  DEVELOPMENT_FUNDRAISING: getText('Development/Fundraising'),
  DIRECT_SERVICES: getText('Direct Services'),
  DIVERSITY_EQUITY_INCLUSION: getText('Diversity, Equity & Inclusion'),
  EMPLOYEE_ENGAGEMENT: getText('Employee Engagement'),
  EQUIPMENT_FACILITIES: getText('Equipment & Facilities'),
  EVENTS: getText('Events'),
  PUBLIC_RELATIONS: getText('Public Relations'),
  GRANTS_ADMINISTRATION: getText('Grants Administration'),
  GRAPHIC_DESIGN: getText('Graphic Design'),
  HEALTHCARE_PROVIDER_PRACTITIONER: getText('Healthcare Provider/Practitioner'),
  HUMAN_RESOURCES: getText('Human Resources'),
  INVESTIGATIONS: getText('Investigations'),
  LEGAL: getText('Legal'),
  MARKETING: getText('Marketing'),
  OPERATIONS: getText('Operations'),
  PARTNERSHIPS: getText('Partnerships'),
  PROGRAM_MANAGEMENT: getText('Program Management'),
  RESEARCH: getText('Research'),
  SAFETY_RESOURCE_PROTECTION: getText('Safety & Resource Protection'),
  SOCIAL_MEDIA: getText('Social Media'),
  STRATEGY_PLANNING: getText('Strategy & Planning'),
  SUPPLIES_PROCUREMENT_DISTRIBUTION: getText(
    'Supplies Procurement/Distribution',
  ),
  SUSTAINABILITY: getText('Sustainability'),
  TEACHING_INSTRUCTION: getText('Teaching & Instruction'),
  TECHNOLOGY_IT: getText('Technology/IT'),
  WRITING_EDITORIAL: getText('Writing/Editorial'),
} as const satisfies Record<string, string>;

export const JOB_FUNCTIONS_OPTIONS = Object.entries(JOB_FUNCTIONS).map(
  ([key, value]) => ({ value: key, label: value }),
);
