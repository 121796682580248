const kmText =
  /* TRANSLATORS: abbreviation for kilometers */
  getText('km');

const autoSearchRadius = {
  id: 'auto',
  name:
    /* TRANSLATORS: option in search radius selector to automatically determine the radius */
    getText('Default Radius'),
};

const entireStateSearchRadius = {
  id: 'state',
  name: getText('Entire State'),
};

const entireCountrySearchRadius = {
  id: 'country',
  name: getText('Entire Country'),
};

export const SEARCH_RADIUSES = {
  en: [
    autoSearchRadius,
    {
      id: '8000',
      name: '5 miles',
    },
    {
      id: '16000',
      name: '10 miles',
    },
    {
      id: '24000',
      name: '15 miles',
    },
    {
      id: '40000',
      name: '25 miles',
    },
    {
      id: '100000',
      name: '60 miles',
    },
    {
      id: '160000',
      name: '100 miles',
    },
    entireStateSearchRadius,
    entireCountrySearchRadius,
  ],
  es: [
    autoSearchRadius,
    {
      id: '5000',
      name: `5 ${kmText}`,
    },
    {
      id: '10000',
      name: `10 ${kmText}`,
    },
    {
      id: '15000',
      name: `15 ${kmText}`,
    },
    {
      id: '25000',
      name: `25 ${kmText}`,
    },
    {
      id: '60000',
      name: `60 ${kmText}`,
    },
    {
      id: '100000',
      name: `100 ${kmText}`,
    },
    entireStateSearchRadius,
    entireCountrySearchRadius,
  ],
  pt: [
    autoSearchRadius,
    {
      id: '5000',
      name: `5 ${kmText}`,
    },
    {
      id: '10000',
      name: `10 ${kmText}`,
    },
    {
      id: '15000',
      name: `15 ${kmText}`,
    },
    {
      id: '25000',
      name: `25 ${kmText}`,
    },
    {
      id: '60000',
      name: `60 ${kmText}`,
    },
    {
      id: '100000',
      name: `100 ${kmText}`,
    },
    entireStateSearchRadius,
    entireCountrySearchRadius,
  ],
};
