import { ApiActionType } from 'modules/listing/api/volop/types/ApiActionType';

export const ACTION_TYPE_PLURAL = [
  {
    id: 'VOLOP',
    name:
      /* TRANSLATORS: "Volunteer +" type */
      getText('Volunteering'),
  },
  {
    id: 'LOAN',
    name:
      /* TRANSLATORS: "Volunteer +" type */
      getText('Loans'),
  },
  {
    id: 'DONATION',
    name:
      /* TRANSLATORS: "Volunteer +" type */
      getText('Donations'),
  },
  {
    id: 'PETITION',
    name:
      /* TRANSLATORS: Volunteer +" type */
      getText('Petitions'),
  },
] as const satisfies Array<{ id: ApiActionType; name: string }>;
