import { ApiInvoice } from 'api/invoice/types/ApiInvoice';
import { ApiInvoiceId, ApiOrgId } from 'api/types/ApiTypedId';
import { FetchEndpoint } from 'utils/endpoint/FetchEndpoint';

type ApiResponse = {
  invoice: ApiInvoice;
  org: { id: ApiOrgId; name: string } | undefined;
};

function mapResponse(response: ApiResponse) {
  return response;
}

const endpoint = new FetchEndpoint({
  urlFactory: (invoiceId: ApiInvoiceId) => `/data/invoices/${invoiceId}`,
  mapResponse,
});

// Exports

export const apiFetchInvoice = endpoint.createFetchFn();
