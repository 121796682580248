import { PureComponent, ReactNode, createContext } from 'react';

import { trackEvent } from 'utils/analytics/track/trackEvent';

type Props = {
  children: ReactNode;
};

type State = {
  updateRequired: boolean;
};

export const UpdateRequiredContext = createContext<boolean>(false);

export class UpdateRequiredContextProvider extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      updateRequired: false,
    };
  }

  componentDidMount() {
    window.Idealist.forceUpdate = ({
      oldVersion,
      newVersion,
    }: {
      oldVersion: number;
      newVersion: number;
    }) => {
      const { updateRequired } = this.state;
      if (!updateRequired) {
        trackEvent('New App Version Detected', {
          old_version: oldVersion,
          new_version: newVersion,
        });
        this.setState({
          updateRequired: true,
        });
      }
    };
  }

  render() {
    const { updateRequired } = this.state;
    const { children } = this.props;
    return (
      <UpdateRequiredContext.Provider value={updateRequired}>
        {children}
      </UpdateRequiredContext.Provider>
    );
  }
}
