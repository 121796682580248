import { resetMixpanelUser } from 'utils/analytics/helpers/mixpanel';
import { deleteCookie } from 'utils/cookies';

import { trackEvent } from './trackEvent';

export function trackLogout() {
  resetMixpanelUser();
  trackEvent('User Logged Out');
  deleteCookie('justLoggedOut');
}
