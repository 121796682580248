import { getDistanceFromLatLng } from './getDistanceFromLatLng';

export function isLocationWithinRadius(
  point: { latitude: number; longitude: number },
  center: { latitude: number; longitude: number },
  radiusInMiles: number,
): boolean {
  const { miles } = getDistanceFromLatLng({
    from: [point.latitude, point.longitude],
    to: [center.latitude, center.longitude],
  });

  return miles <= radiusInMiles;
}
