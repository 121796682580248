import {
  ApiEnvironment,
  ApiUser,
} from 'api/userEnvironment/types/ApiUserEnvironment';
import { searchGetInitialLocation } from 'modules/search/helpers/searchGetInitialLocation';
import {
  getSearchLocation,
  updateSearchLocation,
} from 'modules/search/zustand-stores/searchStore';
import { assignAnalyticsAbExperiments } from 'utils/analytics/abExperiments/analyticsAbExperiments';
import { configureAnalyticsUserData } from 'utils/analytics/setup/configureAnalyticsUserData';
import { configureTrackJs } from 'utils/configureTrackJs';
import { trackReferrals } from 'utils/trackReferral';
import { onUserEnvironmentStoreUpdated } from 'zustand-stores/userEnvironmentStore';

import { renderingClientPerformanceMark } from './renderingClientPerformanceMark';
import { renderingOverrideClientABExperiments } from './renderingOverrideClientABExperiments';

type Args = {
  user: ApiUser | null;
  environment: ApiEnvironment;
};

export function onRenderingClientUserEnvironmentLoaded({
  user,
  environment,
}: Args) {
  const searchLocation = getSearchLocation();

  if (!searchLocation) {
    updateSearchLocation(
      searchGetInitialLocation(environment.guessedLocation),
      false,
    );
  }

  renderingClientPerformanceMark('Done setting login state');

  // Tracking
  configureTrackJs(user ? user.id : null);
  configureAnalyticsUserData({ user, environment });
  trackReferrals();

  // Configure AB Experiments
  onUserEnvironmentStoreUpdated((updatedUserEnvironment) => {
    if (updatedUserEnvironment.environment)
      assignAnalyticsAbExperiments(
        updatedUserEnvironment.environment.abExperiments,
      );
  });

  renderingOverrideClientABExperiments(environment);
  renderingClientPerformanceMark('User data first load');
}
