import { useEffect } from 'react';

import { setGMapsError } from 'zustand-stores/gMapsErrorStore';

export function SetGMapsError() {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.Idealist.setGMapsError = (errorType: string) => {
        setGMapsError(errorType);
      };
    }

    const errors = window.Idealist.gmapsErrors;
    if (Array.isArray(errors)) {
      errors.forEach((errorType) => setGMapsError(errorType));
    }
  }, []);

  return null;
}
