import { trackMixpanelEvent } from 'utils/analytics/helpers/mixpanel';
import { validateEvent } from 'utils/analytics/helpers/validateEvent';
import { windowDataLayerPush } from 'utils/analytics/helpers/windowDataLayerPush';
import { mapObject } from 'utils/functional/object';
import { log } from 'utils/logging';

export function trackEvent(
  eventName: string,
  properties: Record<string, unknown> = {},
) {
  const transformedPropertiesForAnalytics = mapObject<string, unknown, unknown>(
    properties,
    (k, v) => [k.replace('.', '_') as string, v],
  );

  validateEvent(eventName, transformedPropertiesForAnalytics);

  windowDataLayerPush({
    event: eventName,
    ...transformedPropertiesForAnalytics,
  });

  trackMixpanelEvent(eventName, properties);
  log.info('Triggering analytics event', {
    event: eventName,
    ...properties,
  });
}
