import { RichError } from 'utils/RichError';

import { checkVersion } from './checkVersion';

export function parseJsonResponse(
  xhr: XMLHttpRequest,
  reject: (error: Error) => void,
) {
  if (!xhr.responseText) {
    return null;
  }

  const contentType = xhr.getResponseHeader('Content-Type');
  if (contentType && contentType.indexOf('json') < 0) {
    reject(
      new RichError('Invalid Content-Type for JSON response', {
        responseURL: xhr.responseURL,
        contentType,
      }),
    );
  }

  try {
    const json = JSON.parse(xhr.responseText);
    // eslint-disable-next-line no-underscore-dangle
    checkVersion(json._version);

    return json;
  } catch (err) {
    reject(
      new RichError('Error parsing JSON response', {
        responseURL: xhr.responseURL,
        errorMessage: (err as Error).message,
      }),
    );
  }
}
