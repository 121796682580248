import { SearchFacetData } from 'modules/search/types/SearchFacetData';
import { SearchFiltersById } from 'modules/search/types/SearchFiltersById';
import { VOLOP_SOURCE_FOR_ACTION_TYPE } from 'utils/constants/general/volopSourceForActionType';
import { VOLOP_SOURCES } from 'utils/constants/general/volopSources';

import { actionTypeFacet } from './actionTypeFacet';

export const volopSourceFacet = {
  id: 'volopSourceFacet',
  name: 'source',
  title: getText('Source'),
  type: 'array',
  options: Object.keys(VOLOP_SOURCES),
  optionTitles: VOLOP_SOURCES,
} as const satisfies SearchFacetData;

export function volopSourceFacetCullFn(filters: SearchFiltersById) {
  const actionTypeFacetFilter = filters.actionTypeFacet || ['VOLOP'];

  const allFiltersSelected =
    actionTypeFacetFilter instanceof Array &&
    actionTypeFacetFilter.length === actionTypeFacet.options.length;

  if (!allFiltersSelected) {
    const sources = new Set<string>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (actionTypeFacetFilter as string[]).forEach((facet: any) => {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      VOLOP_SOURCE_FOR_ACTION_TYPE[facet].forEach((source: any) => {
        sources.add(source);
      });
    });
    return Array.from(sources).filter((source) => source !== 'NONE');
  }

  return Object.keys(VOLOP_SOURCES);
}
