import { ApiOrgType } from 'modules/listing/api/org/types/ApiOrgType';

type OrgTypeType = Readonly<{
  id: ApiOrgType;
  name: string;
  description?: string;
}>;

type OrgTypeDict = {
  [key: string]: OrgTypeType;
};

export const ORG_TYPES = [
  {
    id: 'NONPROFIT',
    name:
      /* TRANSLATORS: org type */
      getText('Nonprofit'),
  },
  {
    id: 'GOVERNMENT',
    name:
      /* TRANSLATORS: org type */
      getText('Government'),
  },
  {
    id: 'CORPORATION',
    name:
      /* TRANSLATORS: org type */
      getText('Business'),
    description:
      /* TRANSLATORS: Tooltip next to 'Business' label in organization type when creating new non-501c organization */
      getText(
        `Social enterprises and approved for-profit companies are allowed to post jobs on Idealist that adhere to our guidelines.`,
      ),
  },
  {
    id: 'CONSULTANT',
    name:
      /* TRANSLATORS: org type */
      getText('Consultant'),
  },
  {
    id: 'RECRUITER',
    name:
      /* TRANSLATORS: org type */
      getText('Recruiter'),
  },
  {
    id: 'COMMUNITY',
    name:
      /* TRANSLATORS: org type */
      getText('Community Group'),
    description:
      /* TRANSLATORS: Tooltip next to 'Community Group' label in organization type when creating new non-501c organization */
      getText(
        'Common interest and other mission-driven, noncommercial groups may now post listings that adhere to the Idealist guidelines.',
      ),
  },
] as const satisfies Array<OrgTypeType>;

export type OrgType = (typeof ORG_TYPES)[number]['id'];

const ORG_TYPE_OBJECTS: OrgTypeDict = ORG_TYPES.reduce(
  (obj, item) => Object.assign(obj, { [item.id]: item }),
  {},
);

export const ORG_TYPES_FOR_FORM: ReadonlyArray<OrgTypeType> = [
  ORG_TYPE_OBJECTS.NONPROFIT,
  ORG_TYPE_OBJECTS.COMMUNITY,
  ORG_TYPE_OBJECTS.RECRUITER,
  ORG_TYPE_OBJECTS.GOVERNMENT,
  ORG_TYPE_OBJECTS.CORPORATION,
  ORG_TYPE_OBJECTS.CONSULTANT,
];
