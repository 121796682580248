import { Color } from 'theme/theme';
import { getStaticImageSrc } from 'utils/getImageSrc';

import { IconName } from './IconName';
import { MaskImageIcon } from './MaskImageIcon';

export type IconProps = {
  name: IconName;
  size: number;
  opacity?: number;
  colorName?: Color;
  qaId?: string;
};

export function Icon({ name, size, opacity, colorName, qaId }: IconProps) {
  return (
    <MaskImageIcon
      src={getStaticImageSrc(`icons/normalized/${name}.svg`)}
      size={size}
      opacity={opacity}
      colorName={colorName}
      qaId={qaId}
      alt={`${name} icon`}
    />
  );
}
