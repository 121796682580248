import {
  CmsApiBlock,
  CmsApiBlockType,
} from 'modules/cms/api/types/CmsApiBlock';
import { cmsThreeStepCtaBlockSteps } from 'modules/cms/api/types/blocks/CmsApiThreeStepCtaBlock';

const blocksWithBackground: CmsApiBlockType[] = [
  'FLASH_CARD_QUIZ',
  'TWO_TILES',
  'RECOMMENDED_ACTIONS',
  'RECOMMENDED_JOBS',
];

type Props = {
  block: CmsApiBlock;
  fullWidthLayout?: boolean;
};

export function getCmsBlockHasBackground({ block, fullWidthLayout }: Props) {
  if (!fullWidthLayout) return false;

  if (blocksWithBackground.includes(block.type)) return true;

  if (block.type === 'SPLIT_CONTENT' && block.data.appearance === 'OVERLAPPING')
    return true;

  if (block.type === 'THREE_STEP_CTA') {
    return cmsThreeStepCtaBlockSteps.some(
      (step) =>
        block.data[`step${step}Url`] && block.data.imageSize === 'SMALL',
    );
  }

  return false;
}
