import invariant from 'invariant';

import { configValue } from 'config/appConfigUtils';

export function validateEvent(
  eventName: string,
  properties: Record<string, unknown>,
) {
  if (configValue('idealist', 'analytics', 'enforceValidEvents')) {
    invariant(
      !eventName.includes('_') && /^[A-Z0-9]/.test(eventName),
      `Invalid Analytics event name: "${eventName}"`,
    );
    Object.keys(properties).forEach((name) => {
      invariant(
        /^[a-z0-9_]+$/.test(name),
        `Invalid Analytics property name: "${name}". Property names must be snake case`,
      );
    });
  }
}
