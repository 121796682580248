import invariant from 'invariant';

import { getUrlWithoutOrigin } from './getUrlWithoutOrigin';
import { isUrlWithProtocol } from './isUrlWithProtocol';

export function getRelativeURL(url: string) {
  invariant(typeof url === 'string', 'url should be a string');

  if (url.startsWith('mailto:') || url.startsWith('#') || url.startsWith('?'))
    return url;

  if (isUrlWithProtocol(url)) return getUrlWithoutOrigin(url);

  return url.replace(/^(?:\/\/|[^/]+)*/, '');
}
