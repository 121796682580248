import { ApiJobId } from 'api/types/ApiTypedId';
import { ApiJob } from 'modules/listing/api/job/types/ApiJob';
import { ApiUnpublishedJob } from 'modules/listing/api/job/types/ApiUnpublishedJob';
import { FetchEndpoint } from 'utils/endpoint/FetchEndpoint';

type ApiResponse = {
  data: ApiJob | ApiUnpublishedJob;
};

function mapResponse(response: ApiResponse): ApiJob | ApiUnpublishedJob {
  return response.data;
}

const endpoint = new FetchEndpoint({
  urlFactory: (jobId: ApiJobId) => `/data/website/jobs/${jobId}`,
  mapResponse,
});

// Exports

export const listingApiFetchJob = endpoint.createFetchFn();
