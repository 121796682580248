import { CSSProperties, useEffect, useRef } from 'react';
import styled from 'styled-components';

import { colors } from 'theme/theme';

type Props = {
  src: string;
  srcSet?: string;
  sourcesData?:
    | { media: string; srcSet: string }[]
    | readonly { media: string; srcSet: string }[];
  alt: string;
  height?: number;
  width?: number;
  expandSize?: boolean;
  'data-qa-id'?: string;
  objectFit?: 'cover' | 'contain';
  objectPosition?: CSSProperties['objectPosition'];
  aspectRatio?: { width: number; height: number };
  round?: boolean;
  eagerLoad?: boolean;
  skeleton?: boolean;
  style?: CSSProperties;
};

const StyledImage = styled.img`
  &.skeleton {
    background-color: ${colors.calloutGrey};
    background-position: center;
    background-repeat: no-repeat;
    background-size: object-fit;
  }
`;

export function Image({
  src,
  srcSet,
  sourcesData,
  alt,
  width,
  height,
  expandSize,
  objectFit = 'contain',
  objectPosition,
  aspectRatio,
  round,
  eagerLoad,
  skeleton,
  'data-qa-id': dataQaId,
  style,
}: Props) {
  const ref = useRef<HTMLImageElement>(null);

  useEffect(() => {
    const el = ref.current;
    if (!el) return;

    if (el.complete) {
      el.classList.remove('skeleton');
    } else {
      el.addEventListener('load', () => {
        el.classList.remove('skeleton');
      });
    }
  }, []);

  const imgTag = (
    <StyledImage
      ref={ref}
      className={skeleton ? 'skeleton' : undefined}
      src={src}
      srcSet={srcSet && srcSet !== src ? srcSet : undefined}
      alt={alt}
      width={expandSize ? '100%' : width}
      height={expandSize ? '100%' : height}
      data-qa-id={dataQaId}
      style={{
        maxWidth: '100%',
        maxHeight: '100%',
        objectFit,
        objectPosition,
        borderRadius: round ? '100%' : undefined,
        aspectRatio: aspectRatio
          ? `${aspectRatio.width} / ${aspectRatio.height}`
          : undefined,
        ...style,
      }}
      // Eager is the default browser loading strategy
      loading={eagerLoad ? undefined : 'lazy'}
    />
  );

  if (!sourcesData || sourcesData.length < 2) return imgTag;

  return (
    <picture
      style={{
        width: expandSize ? '100%' : undefined,
        height: expandSize ? '100%' : undefined,
      }}
    >
      {sourcesData.map((sourceData) => (
        <source
          key={sourceData.media}
          srcSet={sourceData.srcSet}
          media={sourceData.media}
        />
      ))}

      {imgTag}
    </picture>
  );
}
