import { CmsApiPage } from 'modules/cms/api/types/CmsApiPage';

import { getCmsBlockHasBackground } from './getCmsBlockHasBackground';
import { getIsContentFullWidthLayout } from './getIsContentFullWidthLayout';

type Props = {
  content: CmsApiPage;
};

export function getHideFooterDivider({ content }: Props) {
  const lastBlock = content.contentBlocks
    .filter((contentBlock) => contentBlock.type !== 'SCROLL_TRACKING')
    .pop();

  const fullWidthLayout = getIsContentFullWidthLayout({ content });

  if (!lastBlock) return false;

  if (
    fullWidthLayout &&
    lastBlock.type === 'SPLIT_CONTENT' &&
    lastBlock.data.appearance === 'FULL_WIDTH'
  ) {
    return true;
  }

  if (['CTA_HERO', 'STICKY_HEADER_LINKS'].includes(lastBlock.type)) {
    return true;
  }

  return getCmsBlockHasBackground({
    block: lastBlock,
    fullWidthLayout,
  });
}
