import { putJson } from 'utils/http/putJson';

import { UserDashboardApiSavedSearch } from './types/UserDashboardApiSavedSearch';

export async function userDashboardApiEditSavedSearch(
  id: string,
  { name, notifyByEmail }: { name: string; notifyByEmail: boolean },
): Promise<UserDashboardApiSavedSearch> {
  return putJson<{ savedSearch: UserDashboardApiSavedSearch }>(
    `/data/saved-searches/${id}`,
    {
      name,
      notifyByEmail,
    },
  ).then(({ savedSearch }) => savedSearch);
}
