import { postJson } from 'utils/http/postJson';

export type TrackedUserEventName =
  | 'JAZZHR_REFERRAL'
  | 'LISTING_REFERRAL'
  | 'CAMPAIGN'
  | 'CONTACT_SUGGESTED_APPLICANT_CLICK';

export const utmSourcesToTrack: Record<string, TrackedUserEventName> = {
  'jazzhr-ref': 'JAZZHR_REFERRAL',
};

export type TrackedUserEventPayload = {
  eventName: TrackedUserEventName;
  extraData: Record<string, unknown>;
};

export async function trackUserEvent(
  eventInfo: TrackedUserEventPayload,
): Promise<void> {
  return postJson('/data/tracked-events', eventInfo);
}
