import { ReactNode } from 'react';

import { LoadingSection } from 'components/LoadingSection/LoadingSection';
import { OrgDashboardApiOrg } from 'modules/orgDashboard/api/types/OrgDashboardApiOrg';
import { useOrgDashboardOrgContext } from 'modules/orgDashboard/contexts/OrgDashboardOrgContext';

type Props = {
  children: (org: OrgDashboardApiOrg) => ReactNode;
};

export function OrgDashboardOrgContextConsumerWaitUntilLoaded({
  children,
}: Props) {
  const org = useOrgDashboardOrgContext();

  if (!org) return <LoadingSection />;

  return children(org);
}
