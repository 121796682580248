import type { LoadableComponent } from '@loadable/component';
import { LoaderFunction } from 'react-router-dom';

/**
 * This is just a shortcut for waiting for a Loadable to load before resolving
 * the react-router route
 *
 * react-router expects the return to be null or data - we don't want to keep
 * the Component data as that's not relevant to us so we return null instead
 *
 * @param loadable
 */
export function loadableLoader(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  loadable: LoadableComponent<any>,
): LoaderFunction {
  return () => loadable.load().then(() => null);
}
