import { tz } from '@date-fns/tz';
import {
  endOfDay,
  getUnixTime,
  parseISO,
  startOfDay,
  startOfToday,
  sub,
  transpose,
} from 'date-fns';

import { ActionDateFacetValue } from 'modules/search/constants/searchFacets/actionDateFacet';
import { SearchFacet } from 'modules/search/types/SearchFacet';
import { SearchLocation } from 'modules/search/types/SearchLocation';
import { algoliaQueryStringFilter } from 'utils/algolia/helpers/algoliaQueryStringFilter';
import { setEquals } from 'utils/functional/array';
import { getUserTimezone } from 'utils/timezones';

function searchAlgoliaOptionsFilterForRecency(recency: string) {
  if (recency === 'PAST_24_HOURS') {
    const yesterday = sub(new Date(), { days: 1 });
    return Math.floor(yesterday.getTime() / 1000).toString();
  }

  if (recency === 'PAST_WEEK') {
    const oneWeekAgo = sub(startOfToday(), { weeks: 1 });
    return Math.floor(oneWeekAgo.getTime() / 1000).toString();
  }

  if (recency === 'PAST_MONTH') {
    const oneMonthAgo = sub(startOfToday(), { months: 1 });
    return Math.floor(oneMonthAgo.getTime() / 1000).toString();
  }

  throw Error(`Invalid value for recency facet: ${recency}`);
}

function searchAlgoliaOptionsFilterForActionDate(
  actionDate: ActionDateFacetValue,
  searchLocation?: SearchLocation,
) {
  // When the search location is active, use that location's timezone, otherwise rely on the user's timezone
  const timezone = searchLocation
    ? searchLocation.timezone
    : (getUserTimezone() as string);

  const timezoneAwareActionDate = {
    endsGT: actionDate.endsGT
      ? getUnixTime(
          transpose(startOfDay(parseISO(actionDate.endsGT)), tz(timezone!)),
        )
      : null,
    startsLT: actionDate.startsLT
      ? getUnixTime(
          transpose(endOfDay(parseISO(actionDate.startsLT)), tz(timezone!)),
        )
      : null,
  };

  return Object.entries(timezoneAwareActionDate)
    .filter((v) => v[1])
    .map(
      (v) =>
        `${v[0].substring(0, v[0].length - 2)}${
          v[0].endsWith('GT') ? '>' : '<'
        }${v[1]}`,
    )
    .join(' AND ');
}

type Args = {
  searchFacet: SearchFacet;
  rawValue: string | number | string[] | ActionDateFacetValue;
  searchLocation?: SearchLocation;
};

export function searchAlgoliaOptionsFilterForFacet({
  searchFacet,
  rawValue,
  searchLocation,
}: Args): string {
  const filterKey =
    'filterKey' in searchFacet ? searchFacet.filterKey : undefined;

  if (searchFacet.name === 'recency') {
    return `published > ${searchAlgoliaOptionsFilterForRecency(rawValue as string)}`;
  }

  if (
    searchFacet.type === 'date' ||
    // TODO: remove this check once we have proper types - it's here just to provide type-safety for the actionDate facet value
    (typeof rawValue === 'object' &&
      'endsGT' in rawValue &&
      'startsLT' in rawValue)
  ) {
    return typeof rawValue === 'object'
      ? searchAlgoliaOptionsFilterForActionDate(
          rawValue as ActionDateFacetValue,
          searchLocation,
        )
      : '';
  }

  if (searchFacet.type === 'string') {
    return algoliaQueryStringFilter(
      filterKey || searchFacet.name,
      typeof rawValue === 'string' || typeof rawValue === 'number'
        ? rawValue.toString()
        : rawValue[0],
    );
  }

  if (typeof rawValue === 'string') {
    if (rawValue === 'ALL') return '';
    return algoliaQueryStringFilter(searchFacet.name, rawValue);
  }

  if (Array.isArray(rawValue) && searchFacet.type === 'array') {
    const noneSelected = rawValue.length === 0;
    const allSelected = setEquals(rawValue, searchFacet.options);

    if (noneSelected || allSelected) return '';
    return `(${rawValue.map((o) => algoliaQueryStringFilter(searchFacet.name, o)).join(' OR ')})`;
  }

  return '';
}
