import { levelOptionTitles } from 'modules/search/helpers/sidefilters';
import { SearchFacetData } from 'modules/search/types/SearchFacetData';
import { RECENCY_PERIODS } from 'utils/constants/general/recency';

export const recencyFacet = {
  id: 'recencyFacet',
  name: 'recency',
  title: getText('Date Posted'),
  type: 'string',
  options: RECENCY_PERIODS.map((period) => period.id),
  optionTitles: levelOptionTitles(RECENCY_PERIODS),
} as const satisfies SearchFacetData;
