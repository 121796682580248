import { RefObject } from 'react';

import { useIsomorphicLayoutEffect } from 'hooks/useIsomorphicLayoutEffect';

export type InViewportStatus = {
  inViewport: boolean;
  fullyInViewport: boolean;
  hasScrolledPast: boolean;
};

type Props<T extends HTMLElement> = {
  ref: RefObject<T>;
  threshold?: number;
  offset?: {
    left?: number;
    right?: number;
    top?: number;
    bottom?: number;
  };
  /**
   * `visibleOnViewport` and `fullyVisibleOnViewport` never go back to false once they become true
   */
  freezeOnceVisible?: boolean;
  callback: (inViewportStatus: InViewportStatus) => void | boolean;
};

export function useInViewportEffect<T extends HTMLElement>({
  ref,
  threshold,
  offset = {},
  freezeOnceVisible,
  callback,
}: Props<T>): void {
  useIsomorphicLayoutEffect(() => {
    let previousStatus: InViewportStatus = {
      inViewport: false,
      fullyInViewport: false,
      hasScrolledPast: false,
    };

    let hasBeenTriggered = false;

    const offsetTop = `${offset.top || 0}px`;
    const offsetRight = `${offset.right || 0}px`;
    const offsetBottom = `${offset.bottom || 0}px`;
    const offsetLeft = `${offset.left || 0}px`;
    const rootMargin = `${offsetTop} ${offsetRight} ${offsetBottom} ${offsetLeft}`;

    const observer = new IntersectionObserver(
      ([entry]) => {
        const inViewport = entry.isIntersecting;
        const fullyInViewport = entry.intersectionRatio === 1;

        const hasScrolledPast = entry.boundingClientRect.top < 0;

        const isDifferent =
          previousStatus.inViewport !== inViewport ||
          previousStatus.fullyInViewport !== fullyInViewport ||
          previousStatus.hasScrolledPast !== hasScrolledPast;

        const shouldTrigger =
          !hasBeenTriggered ||
          (isDifferent &&
            (!freezeOnceVisible || inViewport || fullyInViewport));

        if (shouldTrigger) {
          const newStatus: InViewportStatus = {
            inViewport,
            fullyInViewport,
            hasScrolledPast,
          };

          if (callback(newStatus) === false) return;

          hasBeenTriggered = true;
          previousStatus = newStatus;

          if (inViewport && fullyInViewport && freezeOnceVisible) {
            observer.disconnect();
          }
        }
      },
      { rootMargin, threshold },
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [
    ref,
    offset.left,
    offset.right,
    offset.top,
    offset.bottom,
    freezeOnceVisible,
    callback,
  ]);
}
