export function renderingClientPerformanceMark(
  name: string,
  options?: PerformanceMarkOptions,
) {
  if (
    window &&
    window.performance &&
    typeof window.performance.mark === 'function'
  ) {
    window.performance.mark(`[Client] ${name}`, options);
  }
}
