import invariant from 'invariant';

/*
 * styled-components mixins
 */
// Generates a media query to target HiDPI devices.
export function hiDPI(ratio = 2) {
  return `
    @media only screen and (min-resolution: ${96 * ratio}dpi),
    only screen and (min-resolution: ${ratio}dppx)
  `;
}

/**
 * @deprecated Use `<FilestackImage>` instead and position it to look like a background
 *
 * The `FilestackImage` component does a series of calculations to optimize
 * the image size and quality delivered each client. We cannot do the same optimizations
 * with background images.
 */
export function retinaBackgroundImage(
  filename: string,
  {
    include3x = true,
    filename2x,
    filename3x,
  }: {
    include3x?: boolean;
    filename2x?: string;
    filename3x?: string;
  } = {},
) {
  if (!filename2x) {
    const match = filename.match(/\.(png|jpg)$/);
    invariant(
      match,
      `Invalid filename "${filename}" File must be a PNG or JPG.`,
    );
    const suffix = match[1];
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line no-param-reassign
    filename2x = filename.replace(`.${suffix}`, `@2x.${suffix}`);

    if (include3x && !filename3x) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line no-param-reassign
      filename3x = filename.replace(`.${suffix}`, `@3x.${suffix}`);
    }
  }

  let css = `
    background-image: url(${filename});
    ${hiDPI()} {
      background-image: url(${filename2x});
    }
  `;

  if (filename3x) {
    css += `
      ${hiDPI(3)} {
        background-image: url(${filename3x});
      }
    `;
  }

  return css;
}

// @NOTE - We are converting literal CSS var strings
// into hex values - if a new color is needed in this
// function, add it to this map.
const hexCodes = {
  black: '#000',
  white: '#FFF',
  'var(--color-skyBlue)': '#5dc2f1',
  'var(--color-brandBlue)': '#0d73d9',
  'var(--color-brandGreen)': '#68bd53',
  'var(--color-black)': '#000',
  'var(--color-backgroundGrey)': '#fbfbfb',
  'var(--color-alertRed)': '#d0021b',
};

export function getRgbaString(hex: string, opacity: number | string) {
  const hexCode = hex.replace('#', '');
  const fullHex =
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line no-nested-ternary
    hexCode.length === 3
      ? Array.prototype.map.call(hex, (chr) => chr + chr).join('')
      : // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        hexCodes[hexCode]
        ? // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          hexCodes[hexCode]
        : `${hexCode}000000`.slice(0, 6);
  const color = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(fullHex);
  const rgb = color
    ? [parseInt(color[1], 16), parseInt(color[2], 16), parseInt(color[3], 16)]
    : [0, 0, 0];
  const a =
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line no-nested-ternary
    typeof opacity === 'number'
      ? opacity > 1
        ? opacity / 100
        : opacity
      : opacity.includes('%')
        ? parseInt(opacity.replace('%', ''), 10)
        : parseInt(opacity, 10);
  return `${rgb.join(',')},${a}`;
}
