import { ComponentType, ForwardedRef } from 'react';
import styled from 'styled-components';
// eslint-disable-next-line import/no-namespace
import * as ss from 'styled-system';

import { getClassName } from 'theme/theme';

type ComponentProps = {
  className?: string;
};

type Props = {
  component?: ComponentType<ComponentProps>;
  forwardedRef?: ForwardedRef<unknown>;
  classes?: Array<UtilityClass>;
  className?: string;
  position: string;
  top: string;
  right: string;
  bottom: string;
  left: string;
  zIndex: string;
  transform: string;
  m: string;
  margin: string;
  mr: string;
  marginRight: string;
  ml: string;
  marginLeft: string;
  mt: string;
  marginTop: string;
  mb: string;
  marginBottom: string;
  mx: string;
  my: string;
  p: string;
  padding: string;
  pr: string;
  paddingRight: string;
  pl: string;
  paddingLeft: string;
  pt: string;
  paddingTop: string;
  pb: string;
  paddingBottom: string;
  px: string;
  py: string;
  display: string;
  width: string;
  height: string;
  maxWidth: string;
  maxHeight: string;
  minWidth: string;
  minHeight: string;
  flex: string;
  order: string;
  alignSelf: string;
  justifySelf: string;
  alignItems: string;
  alignContent: string;
  justifyContent: string;
  flexBasis: string;
  flexDirection: string;
  flexGrow: string;
  flexShrink: string;
  flexWrap: string;
  columnStart: string;
  columnEnd: string;
  overflow: string;
  color: string;
  opacity: string;
  visibility: string;
  bg: string;
  background: string;
  backgroundImage: string;
  backgroundSize: string;
  backgroundPosition: string;
  backgroundRepeat: string;
  backgroundColor: string;
  borderBottom: string;
  borderTop: string;
  borderLeft: string;
  borderRight: string;
  border: string;
  borderColor: string;
  borderWidth: string;
  borderStyle: string;
  borderRadius: string;
  boxShadow: string;
  cursor: string;
  fontSize: string;
  fontFamily: string;
  gridGap: string;
  gridAutoFlow: string;
  gridArea: string;
  gridTemplateAreas: string;
  gridColumn: string;
  gridColumnGap: string;
  gridAutoColumns: string;
  gridTemplateColumns: string;
  gridRow: string;
  gridRowGap: string;
  gridAutoRows: string;
  gridTemplateRows: string;
  lineHeight: string;
  fontWeight: string;
  letterSpacing: string;
  textAlign: string;
  verticalAlign: string;
  textOverflow: string;
  textTransform: string;
  textDecoration: string;
  whiteSpace: string;
  wordBreak: string;
  transition: string;
  willChange: string;
  pointerEvents: string;
};

export const BaseBox = styled(
  ({
    forwardedRef,
    position: _position,
    top: _top,
    right: _right,
    bottom: _bottom,
    left: _left,
    zIndex: _zIndex,
    transform: _transform,
    m: _m,
    margin: _margin,
    mr: _mr,
    marginRight: _marginRight,
    ml: _ml,
    marginLeft: _marginLeft,
    mt: _mt,
    marginTop: _marginTop,
    mb: _mb,
    marginBottom: _marginBottom,
    mx: _mx,
    my: _my,
    p: _p,
    padding: _padding,
    pr: _pr,
    paddingRight: _paddingRight,
    pl: _pl,
    paddingLeft: _paddingLeft,
    pt: _pt,
    paddingTop: _paddingTop,
    pb: _pb,
    paddingBottom: _paddingBottom,
    px: _px,
    py: _py,
    display: _display,
    width: _width,
    height: _height,
    maxWidth: _maxWidth,
    maxHeight: _maxHeight,
    minWidth: _minWidth,
    minHeight: _minHeight,
    flex: _flex,
    order: _order,
    alignSelf: _alignSelf,
    justifySelf: _justifySelf,
    alignItems: _alignItems,
    alignContent: _alignContent,
    justifyContent: _justifyContent,
    flexBasis: _flexBasis,
    flexDirection: _flexDirection,
    flexGrow: _flexGrow,
    flexShrink: _flexShrink,
    flexWrap: _flexWrap,
    gridGap: _gridGap,
    gridAutoFlow: _gridAutoFlow,
    gridArea: _gridArea,
    gridTemplateAreas: _gridTemplateAreas,
    gridColumn: _gridColumn,
    gridColumnGap: _gridColumnGap,
    gridAutoColumns: _gridAutoColumns,
    gridTemplateColumns: _gridTemplateColumns,
    gridRow: _gridRow,
    gridRowGap: _gridRowGap,
    gridAutoRows: _gridAutoRows,
    gridTemplateRows: _gridTemplateRows,
    columnStart: _columnStart,
    columnEnd: _columnEnd,
    overflow: _overflow,
    color: _color,
    opacity: _opacity,
    visibility: _visibility,
    bg: _bg,
    background: _background,
    backgroundImage: _backgroundImage,
    backgroundSize: _backgroundSize,
    backgroundPosition: _backgroundPosition,
    backgroundRepeat: _backgroundRepeat,
    backgroundColor: _backgroundColor,
    borderBottom: _borderBottom,
    borderTop: _borderTop,
    borderLeft: _borderLeft,
    borderRight: _borderRight,
    border: _border,
    borderColor: _borderColor,
    borderWidth: _borderWidth,
    borderStyle: _borderStyle,
    borderRadius: _borderRadius,
    boxShadow: _boxShadow,
    cursor: _cursor,
    fontSize: _fontSize,
    fontFamily: _fontFamily,
    lineHeight: _lineHeight,
    fontWeight: _fontWeight,
    letterSpacing: _letterSpacing,
    textAlign: _textAlign,
    verticalAlign: _verticalAlign,
    textOverflow: _textOverflow,
    textTransform: _textTransform,
    textDecoration: _textDecoration,
    whiteSpace: _whiteSpace,
    wordBreak: _wordBreak,
    transition: _transition,
    willChange: _willChange,
    pointerEvents: _pointerEvents,
    component,
    classes,
    className,
    ...props
  }: Props) => {
    const BoxComponent = component || 'div';
    return (
      <BoxComponent
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        // @ts-expect-error fix types
        ref={forwardedRef}
        className={getClassName(className, classes)}
      />
    );
  },
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
).attrs<any>(
  ({
    cursor,
    transition,
    zIndex,
    justifySelf,
    alignSelf,
    alignContent,
    filter,
    opacity,
    background,
    backgroundImage,
    backgroundRepeat,
    backgroundColor,
    backgroundPosition,
    backgroundSize,
    textOverflow,
    textTransform,
    textDecoration,
    whiteSpace,
    willChange,
    wordBreak,
    visibility,
    verticalAlign,
    pointerEvents,
    letterSpacing,
    lineHeight,
  }) => ({
    style: {
      cursor,
      transition,
      zIndex,
      justifySelf,
      alignSelf,
      alignContent,
      filter,
      opacity,
      background,
      backgroundImage,
      backgroundRepeat,
      backgroundColor,
      backgroundPosition,
      backgroundSize,
      textOverflow,
      textTransform,
      textDecoration,
      whiteSpace,
      willChange,
      wordBreak,
      visibility,
      verticalAlign,
      pointerEvents,
      letterSpacing,
      lineHeight,
    },
  }),
)<{ $printHidden: boolean }>`
  ${ss.position}
  ${ss.top}
  ${ss.right}
  ${ss.bottom}
  ${ss.left}
  ${ss.space}
  ${ss.display}
  ${ss.width}
  ${ss.height}
  ${ss.maxWidth}
  ${ss.maxHeight}
  ${ss.minWidth}
  ${ss.minHeight}
  ${ss.flex}
  ${ss.order}
  ${ss.alignItems}
  ${ss.justifyContent}
  ${ss.flexBasis}
  ${ss.flexWrap}
  ${ss.flexDirection}
  ${ss.flexGrow}
  ${ss.flexShrink}
  ${ss.gridGap}
  ${ss.gridAutoFlow}
  ${ss.gridArea}
  ${ss.gridTemplateAreas}
  ${ss.gridColumn}
  ${ss.gridColumnGap}
  ${ss.gridAutoColumns}
  ${ss.gridTemplateColumns}
  ${ss.gridRow}
  ${ss.gridRowGap}
  ${ss.gridAutoRows}
  ${ss.gridTemplateRows}
  ${ss.color}
  ${ss.borders}
  ${ss.borderRadius}
  ${ss.boxShadow}
  ${ss.fontSize}
  ${ss.fontWeight}
  ${ss.textAlign}
  ${ss.background}
  ${ss.system({
    transform: true,
    overflow: true,
  })}
  ${(props) =>
    props.$printHidden &&
    `
    @media print {
      display: none !important;
    }
  `}
`;
