import { ApiUserEnvironment } from 'api/userEnvironment/types/ApiUserEnvironment';

export function renderingWaitForClientUserEnvironmentToLoad() {
  // Instead of fetching the user here, we're making the request in the
  // HTML template directly. Otherwise, the response from the Link HTTP header is
  // not used, and we're making a second request.
  return new Promise<ApiUserEnvironment>((resolve) => {
    const interval = setInterval(() => {
      if (window.Idealist.initUserEnvironment) {
        resolve(window.Idealist.initUserEnvironment);
        clearInterval(interval);
      }
    }, 50);
  });
}
