export function checkVersion(version: number) {
  // eslint-disable-next-line no-underscore-dangle
  if (version > window.Idealist._version) {
    window.Idealist.forceUpdate({
      // eslint-disable-next-line no-underscore-dangle
      oldVersion: window.Idealist._version,
      newVersion: version,
    });
  }
}
