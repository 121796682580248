import { NotFoundPageLoadable } from 'pages/NotFound/NotFoundPageLoadable';

// FREE_CODE blocks with <script> tags won't be properly executed in dynamically
// loaded pages due to browser security restrictions, so we need to reload the
// page in that case. Previous discussion:
// https://idealist.slack.com/archives/CHJ9JK7TP/p1730383641821849
export function CmsContentPageFullReload({ url }: { url: string }) {
  if (typeof window === 'undefined') return <NotFoundPageLoadable noLayout />;

  window.location.href = url;
  return null;
}
