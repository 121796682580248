import { RefObject, useEffect } from 'react';

import { useLatest } from 'hooks/useLatest';
import { trackClicked } from 'utils/analytics/track/trackClicked';

type Args = {
  ref: RefObject<HTMLElement>;
  eventName: string | undefined;
  eventProperties: Record<string, unknown> | undefined;
  onClick?: (anchor: HTMLAnchorElement) => void;
};

export function useTrackLinks({
  ref,
  eventName,
  eventProperties = {},
  onClick,
}: Args) {
  const eventNameRef = useLatest(eventName);
  const eventPropertiesRef = useLatest(eventProperties);
  const onClickRef = useLatest(onClick);

  useEffect(() => {
    const el = ref.current;
    if (!el) return;

    const linkEls = Array.from(el.getElementsByTagName('a')).filter(
      (link) => link.hasAttribute('href') && link.getAttribute('href') !== '#',
    );

    function clickHandler(this: HTMLAnchorElement) {
      const name = eventNameRef.current;
      if (name) {
        trackClicked(name as string, {
          ...eventPropertiesRef.current,
          url: this.href,
        });
      }

      onClickRef.current?.(this);
    }

    linkEls.forEach((link) => link.addEventListener('click', clickHandler));

    return () => {
      linkEls.forEach((link) =>
        link.removeEventListener('click', clickHandler),
      );
    };
  }, [eventNameRef, eventPropertiesRef, onClickRef, ref]);
}
