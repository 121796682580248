import invariant from 'invariant';

import { configString } from 'config/appConfigUtils';
import { isNotEmpty } from 'utils/functional/poly';

export function searchAlgoliaConfig(passedApiKey?: string | null | undefined): {
  appId: string;
  apiKey: string;
} {
  const appId = configString('algolia', 'appId');
  const apiKey = passedApiKey || configString('algolia', 'searchApiKey');

  invariant(isNotEmpty(appId), 'appId cannot be empty');
  invariant(isNotEmpty(apiKey), 'apiKey cannot be empty');

  return { appId, apiKey };
}
