import { useEffect } from 'react';

import { trackEvent } from 'utils/analytics/track/trackEvent';
import { deleteCookie, getListCookie } from 'utils/cookies';

export function useQueuedTrackers() {
  const queuedTrackers = getListCookie<{ type: string }>('trackEvents');

  useEffect(() => {
    queuedTrackers.forEach((e) => trackEvent(e.type));
    deleteCookie('trackEvents');
  }, [queuedTrackers]);
}
