import { isNotEmpty } from 'utils/functional/poly';

export function camelCaseToDash(string: string) {
  return string.replace(/([a-zA-Z])(?=[A-Z])/g, '$1-').toLowerCase();
}

export function camelCaseToSnakeCase(string: string) {
  return string.replace(/([a-zA-Z])(?=[A-Z])/g, '$1_').toLowerCase();
}

export function snakeCaseToCamelCase(string: string) {
  return string.replace(/_([a-zA-Z])/g, (m) => m[1].toUpperCase());
}

export function titleCaseToCamelCase(string: string) {
  return string.toLowerCase().replace(/(_\w)/g, (m) => m[1].toUpperCase());
}

export function minLengthFn(n: number) {
  return (s: string) => isNotEmpty(s) && s.toString().trim().length >= n;
}

export function convertToSlug(text: string) {
  return text
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/^-+/, '')
    .replace(/--+/g, '-')
    .replace(/-+$/, '');
}
