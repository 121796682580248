import { useEffect, useState } from 'react';

export const useAnimation = (
  isElementVisible: boolean,
  animationDuration: number = 1000,
) => {
  const [stage, setStage] = useState(isElementVisible ? 'enter' : 'idle');
  const [shouldRender, setShouldRender] = useState(isElementVisible);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;

    if (isElementVisible) {
      setShouldRender(true);
      setStage('enter');
    } else {
      setStage('exit');
      timer = setTimeout(() => {
        setShouldRender(false);
      }, animationDuration);
    }

    return () => clearTimeout(timer);
  }, [isElementVisible, animationDuration]);

  return { stage, shouldRender };
};
