import { useEffect, useRef } from 'react';

/**
 * Track whether the hook/component has been unmounted
 *
 * Useful to avoid updating state after unmounted
 *
 * A ref is returned instead of the value because the component is not supposed to updated through `setState`
 * just to mark the `hasUnmounted` value
 */
export function useHasUnmountedRef() {
  const hasUnmountedRef = useRef(false);

  useEffect(
    () =>
      function unmount() {
        hasUnmountedRef.current = true;
      },
    [],
  );

  return hasUnmountedRef;
}
