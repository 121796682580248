export function closeDropdownFromElementWithin(element: HTMLElement) {
  let parent = element.parentElement;

  while (parent && parent.tagName !== 'DETAILS') {
    parent = parent.parentElement;
  }

  if (parent instanceof HTMLDetailsElement) {
    // Defer to the next event loop
    //
    // There's a bug on Chrome Mobile (also reproducible on Chrome desktop simulating mobile)
    // where clicking the `search` button on the page header mobile search menu
    // causes Chrome to crash.
    setTimeout(() => {
      parent.open = false;
    });
  }
}
