import { loadableReady } from '@loadable/component';
import { ComponentType } from 'react';
import { hydrateRoot } from 'react-dom/client';
import {
  RouteObject,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';

import { setConfigOnce } from 'config/appConfigUtils';
import { RenderingClientInnerWrapper } from 'rendering/client/components/RenderingClientInnerWrapper';
import { renderingDeserializeHypernovaPayload } from 'rendering/hypernova/client/renderingDeserializeHypernovaPayload';
import { setClientHydrated } from 'rendering/state/renderingState';
import { setSsrApiData } from 'rendering/state/ssrApiData';
import { routeObjectsWithRootErrorBoundary } from 'routing/utils/routeObjectsWithRootErrorBoundary';
import { getCookieValue } from 'utils/cookies';

import { renderingBootstrapClient } from './utils/renderingBootstrapClient';
import { renderingClientPerformanceMark } from './utils/renderingClientPerformanceMark';
import { renderingLoadClientUserEnvironment } from './utils/renderingLoadClientUserEnvironment';

type Args = {
  Component: ComponentType<Record<never, never>>;
  routeObjects: RouteObject[];
};

export async function renderSsrClient({ Component, routeObjects }: Args) {
  const payload = renderingDeserializeHypernovaPayload();
  if (!payload) return;

  renderingClientPerformanceMark('Starting app');

  const {
    node,
    data: { config, ssrApiData },
  } = payload;

  setSsrApiData(ssrApiData);

  // Set config as soon as possible
  setConfigOnce(config);

  // Setup @loadable
  renderingClientPerformanceMark('Before waiting for loadables');
  await loadableReady();
  renderingClientPerformanceMark('Done waiting for loadables');

  // Bootstrap analytics & miscellaneous
  renderingBootstrapClient();

  // Setup react-router & Idealist wrappers
  const wrappedRoutes = [
    {
      element: (
        <RenderingClientInnerWrapper>
          <Component />
        </RenderingClientInnerWrapper>
      ),
      children: routeObjectsWithRootErrorBoundary(routeObjects),
    },
  ];

  const router = createBrowserRouter(wrappedRoutes);

  // Hydrate
  renderingClientPerformanceMark('Starting hydration');
  hydrateRoot(node, <RouterProvider router={router} />);
  renderingClientPerformanceMark('App ready');

  const hasNoCacheCookie = getCookieValue('nc');
  if (hasNoCacheCookie) renderingClientPerformanceMark('App ready (no cache)');
  else renderingClientPerformanceMark('App ready (cachable)');

  // We need to wait for the next "tick" before setting the user environment.
  //
  // If we don't do that we create a mismatch between the server and client
  // contents, causing a hydration issue and components skip the first render
  // after setting the user environment
  setTimeout(() => {
    setClientHydrated();
    renderingLoadClientUserEnvironment();
  }, 1);
}
