import { localizedRouteGroup } from 'routing/routes';

import {
  INTERNSHIP_URL_SEGMENTS,
  combinedOrgTypeAndInternshipUrlSegments,
} from './internship.route-segments';

export const internshipSegmentedRoutes = [
  ...Object.values(INTERNSHIP_URL_SEGMENTS),
  ...combinedOrgTypeAndInternshipUrlSegments,
].map((internshipUrlSegment) =>
  localizedRouteGroup.route(`${internshipUrlSegment}/:internshipIdDashSlug`),
);
