import { create } from 'zustand';

import { ApiSavedSearchId } from 'api/types/ApiTypedId';
import { ApiSavedSearchUnsaved } from 'modules/userDashboard/api/savedSearch/types/UserDashboardApiSavedSearch';
import { userDashboardApiCreateSavedSearch } from 'modules/userDashboard/api/savedSearch/userDashboardApiCreateSavedSearch';
import { userDashboardApiDeleteSavedSearch } from 'modules/userDashboard/api/savedSearch/userDashboardApiDeleteSavedSearch';
import { userDashboardApiEditSavedSearch } from 'modules/userDashboard/api/savedSearch/userDashboardApiEditSavedSearch';
import {
  userDashboardApiFetchAllSavedSearches,
  userDashboardApiGetFetchAllSavedSearchesSsrApiData,
} from 'modules/userDashboard/api/savedSearch/userDashboardApiFetchAllSavedSearches';
import { trackEvent } from 'utils/analytics/track/trackEvent';
import { pushFlashMessage } from 'zustand-stores/flashMessagesStore';

import { userDashboardCreateThingsStore } from './utils/userDashboardCreateThingsStore';

const recentlySavedSearchStore = create<{
  recentlySavedSearchId: ApiSavedSearchId | undefined;
}>(() => ({ recentlySavedSearchId: undefined }));

const useRecentlySavedSearchStore = recentlySavedSearchStore; // The store can be used as a hook

const { useThings, createThing, updateThing, deleteThing } =
  userDashboardCreateThingsStore({
    fetch: userDashboardApiFetchAllSavedSearches,
    getSsrApiData: userDashboardApiGetFetchAllSavedSearchesSsrApiData,
    create: userDashboardApiCreateSavedSearch,
    update: userDashboardApiEditSavedSearch,
    delete: async (id: string) => {
      await userDashboardApiDeleteSavedSearch(id);
      return id;
    },
  });

// Actions

export async function userDashboardSaveSearch(
  unsavedSearch: ApiSavedSearchUnsaved,
): Promise<void> {
  const newSavedSearch = await createThing(unsavedSearch);

  recentlySavedSearchStore.setState({
    recentlySavedSearchId: newSavedSearch.id,
  });

  pushFlashMessage({ type: 'SAVED_SEARCH' });
  trackEvent('Created Email Alert');
}

export function userDashboardEditSavedSearch(
  id: ApiSavedSearchId,
  values: { name: string; notifyByEmail: boolean },
): Promise<void> {
  return updateThing(id, values);
}

export async function userDashboardUnsaveSearch(
  id: ApiSavedSearchId,
): Promise<void> {
  await deleteThing(id);
  pushFlashMessage({ type: 'UNSAVED_SEARCH' });
}

export function userDashboardDismissRecentlySavedSearch() {
  recentlySavedSearchStore.setState({
    recentlySavedSearchId: undefined,
  });
}

// Hooks

export function useRecentlySavedSearchId() {
  const recentlySavedSearchId = useRecentlySavedSearchStore(
    (state) => state.recentlySavedSearchId,
  );

  return { recentlySavedSearchId };
}

export function useUserDashboardSavedSearches() {
  const savedSearches = useThings();
  return { savedSearches };
}
