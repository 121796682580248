import { useCallback, useState } from 'react';

import { AcceptCookies } from 'components/AcceptCookies/AcceptCookies';
import { useIsClient } from 'hooks/useIsClient';
import { postJson } from 'utils/http/postJson';
import {
  updateEnvironmentPartially,
  useUserEnvironment,
} from 'zustand-stores/userEnvironmentStore';

export function AcceptCookiesContainer() {
  const [submitting, setSubmitting] = useState(false);

  const { isEnvironmentLoaded, environment } = useUserEnvironment();
  const isClient = useIsClient();

  const acceptCookies = useCallback(async () => {
    setSubmitting(true);

    await postJson('/data/website/accept-cookies');
    updateEnvironmentPartially({ showAcceptCookies: false });
    setSubmitting(false);
  }, []);

  if (!isEnvironmentLoaded || !environment?.showAcceptCookies || !isClient)
    return null;

  return (
    <div
      style={{
        position: 'sticky',
        bottom: 0,
        left: 0,
        width: '100%',
        zIndex: 9999,
      }}
    >
      <AcceptCookies onAcceptCookies={acceptCookies} submitting={submitting} />
    </div>
  );
}
