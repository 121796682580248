import { SearchFacet } from 'modules/search/types/SearchFacet';

import { educationFacet } from './searchFacets/educationFacet';
import { employmentTypeFacet } from './searchFacets/employmentTypeFacet';
import { jobFamilyFacet } from './searchFacets/jobFamilyFacet';
import { jobKeywordsFacet } from './searchFacets/jobKeywordsFacet';
import { localeFacet } from './searchFacets/localeFacet';
import { locationTypeFacet } from './searchFacets/locationTypeFacet';
import { orgAreasOfFocusFacet } from './searchFacets/orgAreasOfFocusFacet';
import { orgTypeFacet } from './searchFacets/orgTypeFacet';
import { professionalLevelFacet } from './searchFacets/professionalLevelFacet';
import { recencyFacet } from './searchFacets/recencyFacet';

export const searchFacetsJob = [
  locationTypeFacet,
  jobKeywordsFacet,
  orgAreasOfFocusFacet,
  educationFacet,
  professionalLevelFacet,
  employmentTypeFacet,
  orgTypeFacet,
  localeFacet,
  recencyFacet,
  jobFamilyFacet, // not shown in sidebar
] satisfies SearchFacet[];
