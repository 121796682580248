import queryString from 'query-string';
import { MouseEvent, useCallback } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { showModal } from 'zustand-stores/modalStore';

export function useLogin() {
  const location = useLocation();
  const [params] = useSearchParams();

  const defautlRedirectTo = location
    ? `${location.pathname}${location.search}${location.hash}`
    : '';
  const redirectTo = params.get('to') || defautlRedirectTo;

  const onClickLogin = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();

      showModal('LOGIN', {
        redirectTo,
        manualLogin: true,
      });
    },
    [redirectTo],
  );

  const loginLink = queryString.stringifyUrl({
    url: 'login',
    query: { to: redirectTo },
  });

  return { onClickLogin, loginLink };
}
