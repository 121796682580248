import type { SearchResponse } from '@algolia/client-search';
import queryString from 'query-string';

import { ApiJobSynonymFamilyId } from 'api/types/ApiTypedId';
import { cmsApiGetFetchLandingPageSsrApiData } from 'modules/cms/api/cmsApiFetchPage';
import { SalariesJobSynonym } from 'modules/salaries/types/SalariesJobSynonym';
import { searchAlgoliaOptionsWithSearchLocation } from 'modules/search/algolia/options/searchAlgoliaOptionsWithSearchLocation';
import { searchAlgoliaOptionsSwitch } from 'modules/search/algolia/options/switch/searchAlgoliaOptionsSwitch';
import { searchAlgoliaConfig } from 'modules/search/algolia/searchAlgoliaConfig';
import { SearchAlgoliaHit } from 'modules/search/algolia/types/SearchAlgoliaHit';
import { extractLocationAnalyticsProperties } from 'modules/search/helpers/analytics/extractLocationAnalyticsProperties';
import { isLocationEmpty } from 'modules/search/helpers/sidefilters';
import { SearchFacetId } from 'modules/search/types/SearchFacet';
import { SearchFiltersById } from 'modules/search/types/SearchFiltersById';
import { SearchLocation } from 'modules/search/types/SearchLocation';
import { SearchSortType } from 'modules/search/types/SearchSortType';
import { SearchType } from 'modules/search/types/SearchType';
import { algoliaSearch } from 'utils/algolia/algoliaSearch';
import { trackEvent } from 'utils/analytics/track/trackEvent';
import { getDefaultSearchRadius } from 'utils/constants/general/defaultSearchRadius';
import { EMPTY_SEARCH_LOCATION } from 'utils/constants/general/emptySearchLocation';
import { SEARCH_RADIUSES } from 'utils/constants/general/searchRadiuses';
import { setItem } from 'utils/localStorage';
import { createSsrApiDataStore } from 'zustand-stores/utils/createSsrApiDataStore';

const queryParams =
  typeof window === 'object' ? queryString.parse(window.location.search) : {};

function parseRadius(radius: string) {
  const isValidRadius = SEARCH_RADIUSES[CURRENT_LOCALE].find(
    (option) => option.id === radius,
  );

  if (isValidRadius) return radius;
}

type SearchStoreState = {
  query: string;
  pageIndex: number;
  resultsByPage: Record<string, SearchResponse<SearchAlgoliaHit>>;
  isSearching: boolean;
  sort: SearchSortType;
  jobFamilyFacetData:
    | { familyId: ApiJobSynonymFamilyId; jobSynonym: SalariesJobSynonym }
    | undefined;
  filters: SearchFiltersById;
  searchLocation: SearchLocation | undefined;
  radius: string | undefined;
};

const initialState: SearchStoreState = {
  query: '',
  pageIndex: 0,
  resultsByPage: {},
  isSearching: false,
  sort: 'relevance',
  jobFamilyFacetData: undefined,
  filters: { type: 'JOB' },
  searchLocation: undefined,
  radius:
    typeof queryParams.radius === 'string'
      ? parseRadius(queryParams.radius)
      : undefined,
};

const { store: searchStore, hook: useSearchStore } =
  createSsrApiDataStore<SearchStoreState>({
    getSsrState: () => {
      const ssrApiDataForSearchLandingPage =
        cmsApiGetFetchLandingPageSsrApiData();
      if (!ssrApiDataForSearchLandingPage) return;

      const { data: content, ssrLandingPageResults } =
        ssrApiDataForSearchLandingPage;

      const pageIndex = ssrLandingPageResults.page;

      return {
        query: content.searchText,
        pageIndex,
        resultsByPage: { [pageIndex]: ssrLandingPageResults },
        isSearching: false,
        sort: 'relevance',
        jobFamilyFacetData: undefined,
        filters: content.filters,
        searchLocation: content.location.searchLocation,
        radius: content.location.radius
          ? parseRadius(content.location.radius.toString())
          : undefined,
      };
    },
    fallbackState: initialState,
  });

// Hooks & getters

export function getSearchFilterType(): SearchType {
  return searchStore.getState().filters.type;
}

export function useSearchFilterType(): SearchType {
  return useSearchStore((state) => state.filters.type);
}

export function getSearchCurrentPageResults():
  | SearchResponse<SearchAlgoliaHit>
  | undefined {
  const { pageIndex, resultsByPage } = searchStore.getState();
  return resultsByPage[pageIndex];
}

export function useSearchResultsByPage(): Record<
  number,
  SearchResponse<SearchAlgoliaHit>
> {
  return useSearchStore((state) => state.resultsByPage);
}

export function useSearchCurrentPageResults():
  | SearchResponse<SearchAlgoliaHit>
  | undefined {
  return useSearchStore((state) => state.resultsByPage[state.pageIndex]);
}

export function getSearchQuery(): string {
  return searchStore.getState().query;
}

export function useSearchQuery(): string {
  return useSearchStore((state) => state.query);
}

export function getSearchLocation(): SearchLocation | undefined {
  return searchStore.getState().searchLocation;
}

export function useSearchLocation(): SearchLocation | undefined {
  return useSearchStore((state) => state.searchLocation);
}

export function useSearchLocationOrEmpty(): SearchLocation {
  return useSearchStore(
    (state) => state.searchLocation || EMPTY_SEARCH_LOCATION,
  );
}

export function getSearchFiltersById(): SearchFiltersById {
  return searchStore.getState().filters;
}

export function useSearchFiltersById(): SearchFiltersById {
  return useSearchStore((state) => state.filters);
}

export function getSearchSort(): SearchSortType {
  return searchStore.getState().sort;
}

export function useSearchSort(): SearchSortType {
  return useSearchStore((state) => state.sort);
}

export function useIsSearching(): boolean {
  return useSearchStore((state) => state.isSearching);
}

export function getSearchRadius(): string {
  const { radius, sort } = searchStore.getState();
  return radius || getDefaultSearchRadius(sort);
}

export function useSearchRadius(): string {
  return useSearchStore(
    ({ radius, sort }) => radius || getDefaultSearchRadius(sort),
  );
}

export function getSearchPageIndex(): number {
  return searchStore.getState().pageIndex;
}

export function useSearchPageIndex(): number {
  return useSearchStore((state) => state.pageIndex);
}

export function getJobFamilyFacetData():
  | {
      familyId: ApiJobSynonymFamilyId;
      jobSynonym: SalariesJobSynonym;
    }
  | undefined {
  return searchStore.getState().jobFamilyFacetData;
}

export function useJobFamilyFacetData():
  | {
      familyId: ApiJobSynonymFamilyId;
      jobSynonym: SalariesJobSynonym;
    }
  | undefined {
  return useSearchStore((state) => state.jobFamilyFacetData);
}

// Helpers

export function selectedOption(facetId: SearchFacetId) {
  const options = getSearchFiltersById()[facetId];
  return Array.isArray(options) ? options[0] : options;
}

// Actions

export function updateSearchLocation(
  searchLocation: SearchLocation,
  didUserChange: boolean,
) {
  if (didUserChange) {
    trackEvent(
      'Changed Search Location',
      extractLocationAnalyticsProperties(searchLocation),
    );
  }

  setItem('searchLocation', searchLocation);
  searchStore.setState({
    searchLocation: {
      ...EMPTY_SEARCH_LOCATION,
      ...searchLocation,
      hasGeo: !isLocationEmpty(searchLocation),
    },
  });
}

export function setSearchRadius(radius: string | undefined) {
  return searchStore.setState({ radius });
}

export function clearSearchLocation(didUserChange: boolean): void {
  if (didUserChange) {
    trackEvent('Cleared Search Location');
  }

  setItem('searchLocation', EMPTY_SEARCH_LOCATION);
  searchStore.setState({ searchLocation: EMPTY_SEARCH_LOCATION });
}

export function setSearchFilters(filtersById: SearchFiltersById) {
  searchStore.setState({ filters: filtersById });
}

export function setSearchQuery(query: string) {
  searchStore.setState({ query, pageIndex: 0 });
}

export function setSearchSort(sort: SearchSortType) {
  searchStore.setState({ sort, pageIndex: 0 });
}

export function setSearchPageIndex(pageIndex: number) {
  searchStore.setState({ pageIndex });
}

export function setJobFamilyFacetData(jobFamilyFacetData: {
  familyId: ApiJobSynonymFamilyId;
  jobSynonym: SalariesJobSynonym;
}) {
  const { filters } = searchStore.getState();

  searchStore.setState({
    jobFamilyFacetData,
    filters: { ...filters, jobFamilyFacet: [jobFamilyFacetData.familyId] },
  });
}

let lastSearchTimestamp: number | undefined;
export async function fetchSearchPageResults(pageIndex?: number) {
  searchStore.setState({ isSearching: true });

  const timestamp = new Date().getTime();
  lastSearchTimestamp = timestamp;

  const results = await algoliaSearch<SearchAlgoliaHit>({
    config: searchAlgoliaConfig(),
    query: getSearchQuery(),
    options: {
      ...searchAlgoliaOptionsWithSearchLocation({
        options: searchAlgoliaOptionsSwitch({
          filtersById: getSearchFiltersById(),
          sort: getSearchSort(),
          searchLocation: getSearchLocation(),
        }),
        searchLocation: getSearchLocation(),
        radius: getSearchRadius(),
      }),
      page: typeof pageIndex === 'undefined' ? getSearchPageIndex() : pageIndex,
    },
  });

  // Check if a different search ran afterwords
  if (lastSearchTimestamp === timestamp) {
    const { resultsByPage } = searchStore.getState();
    searchStore.setState({
      resultsByPage: { ...resultsByPage, [results.page]: results },
      isSearching: false,
    });
  }

  return results;
}
