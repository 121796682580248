import { Ref } from 'react';
import styled from 'styled-components';

import { colors, fontWeights } from 'theme/theme';

export const DefaultTooltipButton = styled.button<{
  ref?: Ref<HTMLButtonElement>;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  box-shadow: 0 0 0 1px currentcolor inset;
  border-radius: 50%;
  color: ${colors.brandBlue};
  font-size: 10px;
  font-weight: ${fontWeights.semiBold};
  transition: all 0.3s ease;

  &::after {
    content: '?';
  }
`;
