import { useMatches } from 'react-router-dom';

import { CmsApiPage } from 'modules/cms/api/types/CmsApiPage';
import { CmsPageLoaderData } from 'modules/cms/routing/CmsPageLoaderData';
import { cmsRouteId } from 'modules/cms/routing/cms.route-objects';

export function useRouteIsCmsApiPage(): CmsApiPage | undefined {
  const matches = useMatches();
  const lastMatch = matches[matches.length - 1];

  if (lastMatch.id !== cmsRouteId) return;

  const loaderData = lastMatch.data as CmsPageLoaderData;
  if (loaderData?.type !== 'CONTENT') return;

  return loaderData.content;
}
