import styled from 'styled-components';

import { cssBreakpoints, gutterWidth, maxWidth } from 'theme/theme';

export const LayoutCenteredBody = styled.div`
  width: 100%;
  max-width: ${maxWidth[2]}px;
  padding: 0 ${gutterWidth / 2}px;
  margin: 0 auto;

  @media all and (min-width: ${cssBreakpoints.mdUp}) {
    padding: 0 ${gutterWidth}px;
  }
`;
