import { levelOptionTitles } from 'modules/search/helpers/sidefilters';
import { SearchFacetData } from 'modules/search/types/SearchFacetData';
import { PAYMENT_LEVELS } from 'utils/constants/general/paymentLevels';

export const paymentFacet = {
  id: 'paymentFacet',
  name: 'payment',
  title: getText('Compensation'),
  type: 'array',
  options: PAYMENT_LEVELS.map((paymentLevel) => paymentLevel.id),
  optionTitles: levelOptionTitles(PAYMENT_LEVELS),
} as const satisfies SearchFacetData;
