export const RECENCY_PERIODS = [
  {
    id: 'ANYTIME',
    name: getText('Anytime'),
  },
  {
    id: 'PAST_24_HOURS',
    name: getText('Past 24 hours'),
  },
  {
    id: 'PAST_WEEK',
    name: getText('Past week'),
  },
  {
    id: 'PAST_MONTH',
    name: getText('Past month'),
  },
];

export type RecencyPeriod = (typeof RECENCY_PERIODS)[number]['id'];
