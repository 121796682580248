import { searchJobsSegments } from 'modules/search/routing/search.route-segments';
import { searchJobsRouteReference } from 'modules/search/routing/search.routes';
import { SearchJobFiltersByName } from 'modules/search/types/FiltersByName/SearchJobFiltersByName';
import { SearchSortType } from 'modules/search/types/SearchSortType';

import { searchGetQueryParamsFromFiltersByName } from './searchGetQueryParamsFromFiltersByName';

type Args = {
  lang: UserLocale;
  q?: string;
  radius?: 'state' | 'country' | 'auto' | string | null;
  filtersByName?: Omit<SearchJobFiltersByName, 'type'>;
  sort?: SearchSortType;
  pageIndex?: number;
};

export function searchGetJobsRoutePath({
  lang,
  filtersByName,
  q,
  radius,
  sort,
  pageIndex,
}: Args) {
  const segment = searchJobsSegments[lang];

  const queryParams = searchGetQueryParamsFromFiltersByName(filtersByName);

  if (q) queryParams.q = q;
  if (radius) queryParams.radius = radius;
  if (sort && sort !== 'relevance') queryParams.sort = sort;
  if (pageIndex) queryParams.page = pageIndex + 1;

  return searchJobsRouteReference.with({ lang, segment, ...queryParams });
}
