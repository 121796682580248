import { cmsSubscribedSubsitesStorePush } from 'modules/cms/zustand-stores/cmsSubscribedSubsitesStore';
import { postJson } from 'utils/http/postJson';

import { CmsApiSubsiteId } from './types/CmsApiTypedId';

export async function cmsApiSubscribeToSubsite(
  subsiteId: CmsApiSubsiteId,
): Promise<void> {
  return postJson(`/data/website/subsite-subscribe-email/${subsiteId}`).then(
    () => {
      cmsSubscribedSubsitesStorePush(subsiteId);
    },
  );
}
