import { create } from 'zustand';

import { onClientHydrated } from 'rendering/state/renderingState';
import { log } from 'utils/logging';

type Args<TData> = {
  key: string;
  getInitializiationValue?: (localStorageDta: TData | null) => TData;
};

export function createLocalStorageStore<TData>({
  key,
  getInitializiationValue,
}: Args<TData>) {
  const store = create<{ value: TData | null }>(() => ({ value: null }));

  const getValue = () => store.getState().value;

  const useStore = store; // The store can be used as a hook
  const useValue = () => useStore((state) => state.value);

  const getDataFromLocalStorage = () => {
    const rawInitialValue = localStorage.getItem(key);
    if (rawInitialValue === null) return null;

    try {
      return JSON.parse(rawInitialValue) as TData;
    } catch {
      return null;
    }
  };

  onClientHydrated(() => {
    const dataFromLocalStorage = getDataFromLocalStorage();

    const transformedData = getInitializiationValue
      ? getInitializiationValue(dataFromLocalStorage)
      : dataFromLocalStorage;

    store.setState({ value: transformedData });
  });

  const setValue = (newValue: TData | null) => {
    if (typeof newValue === 'undefined' || newValue === null) {
      localStorage.removeItem(key);
      store.setState({ value: null });
      return null;
    }

    try {
      localStorage.setItem(key, JSON.stringify(newValue));
      store.setState({ value: newValue });
    } catch (e) {
      log.error(e);
    }
  };

  return { getValue, useValue, setValue };
}
