import { TrackJS } from 'trackjs';

export const log = {
  // ...args: any because console.info(...) receives ...args: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  info: (...args: any) => {
    if (TrackJS.isInstalled()) {
      TrackJS.console.info(...args);
    } else {
      // eslint-disable-next-line no-console
      console.info(...args);
    }
  },
  // ...args: any because console.error(...) receives ...args: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: (...args: any) => {
    if (TrackJS.isInstalled()) {
      TrackJS.console.error(...args);
    } else {
      // eslint-disable-next-line no-console
      console.error(...args);
    }
  },
};
