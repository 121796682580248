export function setDocumentTitle(
  title: string,
  suffix: string | null = 'Idealist',
) {
  if (typeof document !== 'object') return;

  document.title = `${title}${suffix ? ` - ${suffix}` : ''}`;
  const metaOg = document.querySelector('meta[property="og:title"]');

  if (metaOg) metaOg.setAttribute('content', title);
}
