import { CmsApiPage } from 'modules/cms/api/types/CmsApiPage';
import { SearchType } from 'modules/search/types/SearchType';

import { getCmsApiPageDefaultSearchOrPostSearchType } from './getCmsApiPageDefaultSearchOrPostSearchType';

type Args = {
  content: CmsApiPage;
};

export function getCmsApiPageDefaultSearchType({ content }: Args): SearchType {
  const contentDefaultSearchType = getCmsApiPageDefaultSearchOrPostSearchType({
    content,
  });

  if (contentDefaultSearchType === 'CMS_PAGE') return 'JOB';

  return contentDefaultSearchType || 'JOB';
}
