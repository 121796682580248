import { ApiUserId } from 'api/types/ApiTypedId';

import { createLocalStorageStore } from './utils/createLocalStorageStore';

const IDEALIST_FOLLOW_KEY = 'idealistPreSignupFollow';

const { useValue, setValue } = createLocalStorageStore<ApiUserId>({
  key: IDEALIST_FOLLOW_KEY,
});

// Actions

export function setPreSignupFollowUserId(userId: ApiUserId | null) {
  setValue(userId);
}

// Hooks

export function usePreSignupFollow() {
  const value = useValue();
  return { preSignupFollowUserId: value };
}
