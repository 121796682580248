import { getAnalyticsAbExperiments } from 'utils/analytics/abExperiments/analyticsAbExperiments';
import { getAnalyticsAbExperimentsConcatenatedString } from 'utils/analytics/abExperiments/getAnalyticsAbExperimentsConcatenatedString';
import { getCookieValue } from 'utils/cookies';
import { getItem, setItem } from 'utils/localStorage';

import { getApplicationContext } from './getApplicationContext';

export function windowDataLayerPush(baseEventData: Record<string, unknown>) {
  const eventData = {
    ...baseEventData,
    application_context: getApplicationContext(),
    experiments: getAnalyticsAbExperimentsConcatenatedString(
      getAnalyticsAbExperiments(),
    ),
  };

  if (getCookieValue('_traceAnalytics')) {
    const trace = getItem('_analyticsTrace') || [];
    trace.push(eventData);
    setItem('_analyticsTrace', trace);
  }

  window.dataLayer.push(eventData);
}
