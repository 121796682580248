import { useEffect, useRef } from 'react';

import { onClientHydrated } from 'rendering/state/renderingState';
import { initializeAds } from 'zustand-stores/adStore';
import { useUserEnvironment } from 'zustand-stores/userEnvironmentStore';

export function useAdManager(noAds: boolean = false) {
  const { user, environment } = useUserEnvironment();

  const adManagerLoadedRef = useRef(false);

  useEffect(() => {
    onClientHydrated(() => {
      // @TODO: Can the environment be `null` before hydration?
      if (!noAds && environment && !adManagerLoadedRef.current) {
        initializeAds({ user, environment });
        adManagerLoadedRef.current = true;
      }
    });
  }, [environment, noAds, user]);
}
