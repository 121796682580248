import { ViewportBreakpoint, breakpointValues } from 'theme/theme';

import { getViewportSize } from './getViewportSize';

export const viewportBreakpointArray: ViewportBreakpoint[] = [
  ViewportBreakpoint.xs,
  ViewportBreakpoint.sm,
  ViewportBreakpoint.md,
  ViewportBreakpoint.lg,
];

export const viewportBreakpointValues = [
  breakpointValues.xs,
  breakpointValues.sm,
  breakpointValues.md,
  breakpointValues.lg,
] as const;

export function getViewportBreakpoint() {
  const { viewportWidth } = getViewportSize();

  const breakpointIndex = viewportBreakpointValues.reduce(
    (acc, point, index) => (viewportWidth >= point ? index : acc),
    0,
  );

  return viewportBreakpointArray[breakpointIndex];
}
