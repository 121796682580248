import { RouteObject } from 'react-router-dom';

import { ViewUserProfilePageLoadable } from 'modules/userProfile/pages/ViewUserProfile/ViewUserProfilePageLoadable';
import { loadableLoader } from 'routing/utils/loadableLoader';

import { viewUserProfileRoute } from './userProfile.routes';

export const userProfileRouteObjects = [
  {
    path: viewUserProfileRoute.fullPath,
    loader: loadableLoader(ViewUserProfilePageLoadable),
    Component: ViewUserProfilePageLoadable,
  },
] satisfies RouteObject[];
