import { useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { useElementReachedEffect } from 'hooks/viewport/useElementReachedEffect';
import { trackEvent } from 'utils/analytics/track/trackEvent';

export function LayoutBottomOfPageElement() {
  const location = useLocation();

  const bottomOfPageRef = useRef<HTMLDivElement>(null);
  useElementReachedEffect({
    ref: bottomOfPageRef,
    onReach: useCallback(
      () => {
        // Prevent event from triggering when going from one page to another
        // and the contents of the page is loading
        if (document.documentElement.scrollTop === 0) return false;

        trackEvent('Scroll: Bottom of Page', {
          reached_through: 'Scrolling',
        });

        return true;
      },
      // Reset reached effect after page change
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [location.pathname],
    ),
  });

  return <div ref={bottomOfPageRef} />;
}
