import { levelOptionTitles } from 'modules/search/helpers/sidefilters';
import { SearchFacetData } from 'modules/search/types/SearchFacetData';
import { LOCALES } from 'utils/constants/general/locales';

export const localeFacet = {
  id: 'localeFacet',
  name: 'locale',
  title: getText('Listing Language'),
  type: 'array',
  options: LOCALES.map((locale) => locale.id),
  optionTitles: levelOptionTitles(LOCALES),
} as const satisfies SearchFacetData;
