import { css } from 'styled-components';

import { cssBreakpoints } from 'theme/theme';

const mediaQueries = {
  xs: `(max-width: ${cssBreakpoints.xsUp})`,
  sm: `(min-width: ${cssBreakpoints.xsUp}) and (max-width: ${cssBreakpoints.mdDown})`,
  md: `(min-width: ${cssBreakpoints.mdUp}) and (max-width: ${cssBreakpoints.mdDown})`,
  lg: `(min-width: ${cssBreakpoints.mdUp}) and (max-width: ${cssBreakpoints.lgDown})`,
  xl: `(min-width: ${cssBreakpoints.lgUp})`,
};

export const media = Object.keys(mediaQueries).reduce((acc, label) => {
  // TODO: Fix this the next time the file is edited.
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  // eslint-disable-next-line no-param-reassign
  acc[label] = (...args: never) => css`
    @media ${
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        mediaQueries[label]
      } {
      /* block-no-empty */
      ${
        // @ts-expect-error TS(2556): A spread argument must either have a tuple type or... Remove this comment to see the full error message
        css(...args)
      };
    }
  `;

  return acc;
}, {});

function generateMediaQuery({
  xs,
  sm,
  md,
  lg,
  xl,
}: {
  xs?: boolean;
  sm?: boolean;
  md?: boolean;
  lg?: boolean;
  xl?: boolean;
}) {
  return [
    xs && `(max-width: ${cssBreakpoints.xsUp})`,
    sm &&
      `(min-width: ${cssBreakpoints.xsUp}) and (max-width: ${cssBreakpoints.smDown})`,
    md &&
      `(min-width: ${cssBreakpoints.smUp}) and (max-width: ${cssBreakpoints.mdDown})`,
    lg &&
      `(min-width: ${cssBreakpoints.mdUp}) and (max-width: ${cssBreakpoints.lgDown})`,
    xl && `(min-width: ${cssBreakpoints.lgUp})`,
  ]
    .filter(Boolean)
    .join();
}

export function matchSize(sizes: {
  xs?: boolean;
  sm?: boolean;
  md?: boolean;
  lg?: boolean;
  xl?: boolean;
}) {
  return typeof window === 'object'
    ? window.matchMedia(generateMediaQuery(sizes)).matches
    : false;
}
