import styled from 'styled-components';

import { colors, cssBreakpoints, transition } from 'theme/theme';

import {
  InputAffixVariant,
  InputStatus,
  InputStyleVariant,
} from './Input.types';

export const InputContainer = styled.div<{
  $status: InputStatus;
  $styleVariant: InputStyleVariant;
  $affixVariant: InputAffixVariant;
  $fullHeight: boolean;
  $fullWidth: boolean;
  $hideSuffixWhenEmpty: boolean;
  $disabled?: boolean;
}>`
  position: relative;
  display: flex;
  font-size: 16px;
  height: ${({ $fullHeight }) => ($fullHeight ? '100%' : '45px')};
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'auto')};
  background: var(--input-background-color);
  border-radius: 8px;
  color: ${colors.darkContentGrey};
  transition: all ${transition};
  box-shadow: 0 0 0 var(--input-shadow-size, 0) var(--input-highlight-color);
  border: solid 1px var(--input-border-color);

  input:placeholder-shown + label {
    display: ${(props) => (props.$hideSuffixWhenEmpty ? 'none' : '')};
  }

  &:focus-within {
    --input-highlight-color: ${colors.brandBlue};
    --input-shadow-size: 1px;
  }
  ${({ $disabled }) => {
    if ($disabled) {
      return `
      /* Input without prefix or suffix */
      > input:only-child {
       border-radius: 8px;
      }

      /* Input with prefix */
       > label {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      label + input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }

      /* Input with suffix */
      > input {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      input + label {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }


      `;
    }
  }}

  ${({ $affixVariant }) => {
    switch ($affixVariant) {
      case 'default':
        return `
            --input-affix-text-color: ${colors.lightContentGrey};
            --input-affix-background-color: ${colors.selectionGrey};

            &:focus-within {
              --input-affix-text-color: ${colors.white};
              --input-affix-background-color: ${colors.brandBlue};
            }
        `;
      case 'contrast':
        return `
            --input-affix-background-color: ${colors.inputGrey};
            --input-affix-text-color: ${colors.lightContentGrey};
          `;
      case 'transparent':
        return `
            --input-affix-text-color: ${colors.lightContentGrey};
        `;
    }
  }}

  ${({ $styleVariant }) => {
    switch ($styleVariant) {
      case 'default':
        return `
            --input-background-color: ${colors.white};
            --input-highlight-color: ${colors.elementGrey};
            --input-border-color: var(--input-highlight-color);
          `;
      case 'contrast':
        return `
            --input-background-color: ${colors.inputGrey};
            --input-highlight-color: ${colors.elementGrey};
            --input-border-color: var(--input-background-color);

            &:focus-within {
              --input-border-color: ${colors.brandBlue};
            }
          `;
      case `light-border`:
        return `
            --input-background-color: ${colors.white};
            --input-highlight-color: ${colors.selectionGrey};
            --input-border-color: var(--input-highlight-color);
          `;
    }
  }}

  ${({ $status }) => {
    switch ($status) {
      case 'neutral':
        return `
            --input-highlight-color: ${colors.elementGrey};
          `;
      case 'success':
        return `
            --input-highlight-color: ${colors.brandGreen};
            --input-affix-background-color: ${colors.brandGreen};
            --input-affix-text-color: ${colors.white};
            --input-shadow-size: 1px;
          `;
      case 'error':
        return `
            --input-highlight-color: ${colors.alertRed};
            --input-affix-background-color: ${colors.lightRed};
            --input-affix-text-color: ${colors.alertRed};
            --input-shadow-size: 1px;
          `;
    }
  }}


  @media all and (min-width: ${cssBreakpoints.smUp}) {
    font-size: 18px;
  }
`;

export const InputAffixLabel = styled.label`
  display: inline-flex;
  align-items: center;
  color: var(--input-affix-text-color);
  background: var(--input-affix-background-color);
  transition: all var(--transition);
`;

/* The input itself doesn't have many styles, most are applied to the container */
export const InputElement = styled.input`
  padding: 11px 12px;
  flex: 1;
  width: 100%;
  appearance: none;
  box-shadow: none;
  border: none;
  outline: none;
  font: inherit;
  background: transparent;

  &::placeholder {
    color: ${colors.lightContentGrey};
  }
`;
