import { FetchEndpoint } from 'utils/endpoint/FetchEndpoint';

type ApiResponse = {
  savedListings: Array<{ id: string; type: string }>;
};

function mapResponse(response: ApiResponse) {
  return response.savedListings;
}

const endpoint = new FetchEndpoint({
  urlFactory: () => '/data/userdashboard/all-saved-listings',
  mapResponse,
});

// Exports

export const userDashboardApiGetFetchAllSavedListingsSsrApiData =
  endpoint.createSsrApiDataFn();

export const userDashboardApiFetchAllSavedListings = endpoint.createFetchFn();
