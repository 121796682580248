import { useEffect, useState } from 'react';

type Args = {
  delayMs: number;
};

export function useDelay({ delayMs }: Args) {
  const [pastDelay, setPastDelay] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setPastDelay(true), delayMs);
    return () => clearTimeout(timeout);
  }, [delayMs]);

  return { pastDelay };
}

/**
 * Alias to useDelay with default 200ms delay
 */
export function useSuspendedUIDelay() {
  return useDelay({ delayMs: 200 });
}
