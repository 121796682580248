import { configString } from 'config/appConfigUtils';
import { SearchSortType } from 'modules/search/types/SearchSortType';

export function searchAlgoliaIndex(sort: SearchSortType) {
  switch (sort) {
    case 'relevance':
      return configString('algolia', 'siteIndexName');
    case 'newest':
      return configString('algolia', 'sitePublishedDescIndexName');
  }
}
