import { ORG_TYPE_URL_SEGMENTS } from 'modules/listing/routing/org/org.route-segments';

export const INTERNSHIP_URL_SEGMENTS_BY_LOCALE = {
  en: 'internship',
  es: 'pasantia',
  pt: 'estagio',
} as const satisfies Record<UserLocale, string>;

export const INTERNSHIP_URL_SEGMENTS = Object.values(
  INTERNSHIP_URL_SEGMENTS_BY_LOCALE,
);

export const combinedOrgTypeAndInternshipUrlSegments =
  ORG_TYPE_URL_SEGMENTS.flatMap((orgTypeSegment) =>
    Object.values(INTERNSHIP_URL_SEGMENTS).map(
      (internshipUrlSegment) => `${orgTypeSegment}-${internshipUrlSegment}`,
    ),
  );
