import { LoadableComponent } from '@loadable/component';

/**
 * The initial implementation of hydrateOnDemand did not work
 *
 * However, there are plans to implement it using Suspense in the near future.
 */
export function hydrateOnDemand<TProps extends Record<string, unknown>>(
  Component: LoadableComponent<TProps>,
): LoadableComponent<TProps> {
  return Component;
}
