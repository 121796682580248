import queryString from 'query-string';

import { RouteComponentProps } from 'utils/withRouter';

export function getFirstParam(
  l: Location | RouteComponentProps['location'],
  param: string,
) {
  const q = queryString.parse(l.search)[param];
  return Array.isArray(q) ? q[0] : q;
}
