import { FetchEndpoint } from 'utils/endpoint/FetchEndpoint';

import { ApiUserEnvironment } from './types/ApiUserEnvironment';

type ApiResponse = ApiUserEnvironment;

function mapResponse(response: ApiResponse) {
  return response;
}

const endpoint = new FetchEndpoint({
  urlFactory: () => '/data/environment',
  mapResponse,
});

// Exports

export const apiGetFetchUserEnvironmentSsrApiData =
  endpoint.createSsrApiDataFn();

export const apiFetchUserEnvironment = endpoint.createFetchFn();
