import { UserDashboardApiGeneralSettings } from 'modules/userDashboard/api/types/UserDashboardApiGeneralSettings';
import { postJson } from 'utils/http/postJson';

export async function userDashboardApiEnableProfileFeatures(): Promise<UserDashboardApiGeneralSettings> {
  return postJson<{
    generalSettings: UserDashboardApiGeneralSettings;
  }>('/data/userdashboard/profile/enable').then(
    ({ generalSettings }) => generalSettings,
  );
}
