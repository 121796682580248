import { useRef } from 'react';

/**
 * Different from `useRef` that takes `initialValue`, this hook immediately updates
 * the value of the ref.
 *
 * This hook is used to avoid triggering a change `useCallback` or `useEffect`
 * while also being able to consume the up-to-date value.
 *
 * @see https://github.com/streamich/react-use/blob/master/docs/useLatest.md (similar implementation)
 */
export function useLatest<TValue>(value: TValue) {
  const ref = useRef(value);
  ref.current = value;

  return ref;
}
