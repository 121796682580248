import { AppRouteGroup } from 'routing/classes/AppRouteGroup';

export const salariesRouteGroup = new AppRouteGroup(
  '/nonprofit-salary-explorer',
);

export const salarySurveyRoute = salariesRouteGroup.route('survey');

export const salarySurveyFaqRoute = salariesRouteGroup.route('faq');

export const salarySurveyLearnMoreRoute =
  salariesRouteGroup.route('learn-more');

export const exploreSalaryRoute = salariesRouteGroup.route('explore');
