type Props = {
  autocompleteService: google.maps.places.AutocompleteService;
  input: string;
  componentRestrictions?: google.maps.places.ComponentRestrictions;
  addressTypes?: Array<string>;
  sessionToken: google.maps.places.AutocompleteSessionToken;
};

export function getPlacePredictions({
  autocompleteService,
  input,
  componentRestrictions,
  addressTypes,
  sessionToken,
}: Props): Promise<google.maps.places.AutocompletePrediction[] | null> {
  return new Promise((resolve, reject) => {
    if (!autocompleteService) {
      reject();
      return;
    }

    autocompleteService.getPlacePredictions(
      {
        input,
        componentRestrictions,
        types: addressTypes || ['geocode'],
        sessionToken,
      },
      (results, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          resolve(results);
        } else if (
          status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS
        ) {
          resolve([]);
        } else {
          reject(new Error(status));
        }
      },
    );
  });
}
