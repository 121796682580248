import { useEffect, useRef } from 'react';
import { useLocation, useNavigationType } from 'react-router-dom';

import { trackEvent } from 'utils/analytics/track/trackEvent';

export function RenderingClientLocationHooks() {
  const location = useLocation();
  const navType = useNavigationType();
  const hasMounted = useRef(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    if (hasMounted.current) {
      if (navType !== 'REPLACE') {
        trackEvent('JS Page View');
      }
    } else {
      hasMounted.current = true;
    }
  }, [location, navType]);

  return null;
}
