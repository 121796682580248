import { setUserEnvironment } from 'zustand-stores/userEnvironmentStore';

import { onRenderingClientUserEnvironmentLoaded } from './onRenderingClientUserEnvironmentLoaded';
import { renderingClientPerformanceMark } from './renderingClientPerformanceMark';
import { renderingGetClientPreloadedUserEnvironment } from './renderingGetClientPreloadedUserEnvironment';
import { renderingWaitForClientUserEnvironmentToLoad } from './renderingWaitForClientUserEnvironmentToLoad';

export async function renderingLoadClientUserEnvironment() {
  renderingClientPerformanceMark('Before setting login state');

  const { user, environment } = renderingGetClientPreloadedUserEnvironment();

  if (environment) {
    setUserEnvironment({ user, environment });
    onRenderingClientUserEnvironmentLoaded({ user, environment });
  } else {
    const userEnvironment = await renderingWaitForClientUserEnvironmentToLoad();

    onRenderingClientUserEnvironmentLoaded({
      user: userEnvironment.user,
      environment: userEnvironment.environment,
    });
  }
}
