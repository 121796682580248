const FORBIDDEN_PROPS = [
  'm',
  'margin',
  'mt',
  'marginTop',
  'mb',
  'marginBottom',
  'ml',
  'marginLeft',
  'mr',
  'marginRight',
  'mx',
  'my',
  'p',
  'padding',
  'pt',
  'paddingTop',
  'pb',
  'paddingBottom',
  'pl',
  'paddingLeft',
  'pr',
  'paddingRight',
  'px',
  'py',
  'overflow',
  'color',
  'fontFamily',
  'fontSize',
  'fontWeight',
  'letterSpacing',
  'textAlign',
  'lineHeight',
  'border',
  'borderLeft',
  'borderRight',
  'borderTop',
  'borderBottom',
  'borderColor',
  'opacity',
  'display',
  'verticalAlign',
  'styleAs',
];

export function cleanTextProps(props: Record<string, unknown>) {
  return Object.keys(props).reduce(
    (acc, key) =>
      FORBIDDEN_PROPS.includes(key) ? acc : { ...acc, [key]: props[key] },
    {},
  );
}
