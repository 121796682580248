import { ApiIntakeIntents } from 'api/types/ApiIntakeIntents';
import { ApiISODateTimeString } from 'api/types/ApiTypedDate';
import { postJson } from 'utils/http/postJson';

export type SaveUserDashboardIntakeIntentsResponse = {
  intakeIntents: Array<ApiIntakeIntents>;
  intakeIntentsDatetimeSet: ApiISODateTimeString;
};

type Args = {
  intakeIntents: ApiIntakeIntents[];
};

export function userDashboardApiSavePostingIntention({
  intakeIntents,
}: Args): Promise<SaveUserDashboardIntakeIntentsResponse> {
  return postJson<SaveUserDashboardIntakeIntentsResponse>(
    '/data/userdashboard/intake-intents',
    {
      intakeIntents,
    },
  );
}
