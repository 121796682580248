import { create } from 'zustand';

import {
  ApiEnvironment,
  ApiUser,
} from 'api/userEnvironment/types/ApiUserEnvironment';
import { AdSection } from 'types/Ads/AdSection';
import { AdType } from 'types/Ads/AdType';

import {
  adTypeTargetingConditions,
  computeAdTypeTargetingRules,
} from './utils/adTypeTargetingConditions';

type AdState = {
  adTypes: Record<AdSection, AdType | null>;
};

const initialAdState = {
  adTypes: {
    [AdSection.BANNER]: null,
    [AdSection.SIDEBAR]: null,
    [AdSection.INTERSTITIAL]: null,
  },
};

const adStore = create<AdState>(() => initialAdState);

// Actions

export function clearAdStore() {
  adStore.setState(initialAdState);
}

export function initializeAds({
  user,
  environment,
}: {
  user: ApiUser | null;
  environment: ApiEnvironment;
}) {
  const targetingRules = computeAdTypeTargetingRules({ user, environment });

  const selectedAdType = Object.values(AdType).find((adType) =>
    adTypeTargetingConditions[adType].every((rule) => targetingRules[rule]),
  );

  if (selectedAdType) {
    adStore.setState({
      adTypes: {
        [AdSection.BANNER]: selectedAdType,
        [AdSection.SIDEBAR]: selectedAdType,
        [AdSection.INTERSTITIAL]: selectedAdType,
      },
    });
  }
}

// Hooks

export const useAdStore = adStore;

export function useAdType(section: AdSection) {
  const adType = useAdStore((state) => state.adTypes[section]);
  return adType;
}
