export const INTERNSHIP_FUNCTIONS = {
  ACCOUNTING_FINANCE: getText('Accounting & Finance'),
  ADMINISTRATIVE: getText('Administrative'),
  ADVOCACY: getText('Advocacy'),
  BUSINESS_DEVELOPMENT: getText('Business Development'),
  CITIZENSHIP: getText('Citizenship'),
  COMMUNITY_BELONGING: getText('Community Belonging'),
  COMMUNITY_ENGAGEMENT_OUTREACH: getText('Community Engagement/Outreach'),
  CONSERVATION: getText('Conservation'),
  CORPORATE_PHILANTHROPY: getText('Corporate Philanthropy'),
  CORPORATE_RESPONSIBILITY: getText('Corporate Responsibility'),
  DATA_ENTRY_ANALYSIS: getText('Data Entry & Analysis'),
  DEVELOPMENT_FUNDRAISING: getText('Development/Fundraising'),
  DIVERSITY_EQUITY_INCLUSION: getText('Diversity, Equity & Inclusion'),
  EMPLOYEE_ENGAGEMENT: getText('Employee Engagement'),
  EVENTS: getText('Events'),
  GRANT_WRITING: getText('Grant Writing'),
  HUMAN_RESOURCES: getText('Human Resources'),
  LEGAL: getText('Legal'),
  MARKETING_COMMUNICATIONS: getText('Marketing & Communications'),
  OPERATIONS: getText('Operations'),
  PARTNERSHIPS: getText('Partnerships'),
  PHOTOGRAPHY_MEDIA_DESIGN: getText('Photography, Media & Design'),
  PROGRAM_MANAGEMENT: getText('Program Management'),
  PUBLIC_RELATIONS: getText('Public Relations'),
  RESEARCH: getText('Research'),
  SOCIAL_MEDIA: getText('Social Media'),
  STRATEGY: getText('Strategy'),
  SUSTAINABILITY: getText('Sustainability'),
  TEACHING_INSTRUCTION: getText('Teaching & Instruction'),
  TECHNOLOGY_IT: getText('Technology/IT'),
  WRITING_JOURNALISM: getText('Writing or Journalism'),
} as const satisfies Record<string, string>;

export const INTERNSHIP_FUNCTIONS_OPTIONS = Object.entries(
  INTERNSHIP_FUNCTIONS,
).map(([key, value]) => ({ value: key, label: value }));
