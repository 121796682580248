import { ReactNode } from 'react';

import { RenderingClientLocationHooks } from './RenderingClientLocationHooks';

type Props = {
  children: ReactNode;
};

export function RenderingClientInnerWrapper({ children }: Props) {
  return (
    <>
      {children}
      <RenderingClientLocationHooks />
    </>
  );
}
