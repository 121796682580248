export const searchJobsSegments = {
  en: 'jobs',
  es: 'empleos',
  pt: 'empregos',
} satisfies Record<UserLocale, string>;

export const searchInternshipsSegments = {
  en: 'internships',
  es: 'pasantias',
  pt: 'estagios',
} satisfies Record<UserLocale, string>;

export const searchVolopsSegments = {
  en: 'volunteer',
  es: 'voluntariado',
  pt: 'voluntariado',
} satisfies Record<UserLocale, string>;

export const searchEventsSegments = {
  en: 'events',
  es: 'eventos',
  pt: 'eventos',
} satisfies Record<UserLocale, string>;

export const searchOrgsSegments = {
  en: 'organizations',
  es: 'organizaciones',
  pt: 'organizacoes',
} satisfies Record<UserLocale, string>;
