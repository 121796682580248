import { Icon } from 'components/Icon/Icon';

import { DropdownChevronHolder } from './DropdownChevron.styled';

export function DropdownChevron() {
  return (
    <DropdownChevronHolder>
      <Icon name="chevron-down" size={9} />
    </DropdownChevronHolder>
  );
}
