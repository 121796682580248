import { DisappearingFlashMessageLoadable } from 'components/FlashMessage/DisappearingFlashMessageLoadable';
import {
  removeFlashMessage,
  useFlashMessages,
} from 'zustand-stores/flashMessagesStore';

export function FlashMessageContainer() {
  const { flashMessages } = useFlashMessages();

  return (
    <div id="flash-message-container">
      {flashMessages.map((flashMessage) => (
        <DisappearingFlashMessageLoadable
          key={flashMessage.id}
          flashMessage={flashMessage}
          removeMessage={() => removeFlashMessage(flashMessage.id)}
        />
      ))}
    </div>
  );
}
