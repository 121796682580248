import {
  ComponentType,
  ElementType,
  MouseEvent,
  ReactNode,
  forwardRef,
} from 'react';
import {
  BackgroundProps,
  BorderProps,
  ColorProps,
  FlexboxProps,
  GridProps,
  LayoutProps,
  PositionProps,
  ResponsiveValue,
  ShadowProps,
  SpaceProps,
  TypographyProps,
} from 'styled-system';

import { BaseBox } from './BaseBox';

type AllStyledSystemProps = FlexboxProps &
  SpaceProps &
  TypographyProps &
  BorderProps &
  PositionProps &
  ColorProps &
  LayoutProps &
  ShadowProps &
  GridProps &
  BackgroundProps;

type AdditionalStyleProps = {
  transform: ResponsiveValue<string>;
  whiteSpace: ResponsiveValue<string>;
  wordBreak: ResponsiveValue<string>;
  transition: ResponsiveValue<string>;
  willChange: ResponsiveValue<string>;
  pointerEvents: ResponsiveValue<string>;
  cursor?: string;
  textTransform?: string;
};

type BoxProps<TComponentProps> = AllStyledSystemProps &
  Partial<AdditionalStyleProps> & {
    id?: string;
    className?: string | null;
    children?: ReactNode;
    classes?: Array<UtilityClass>;
    component?: ComponentType<TComponentProps> | ElementType;
    onClick?: (event: MouseEvent) => void;
  } & TComponentProps;

function BoxWithForwardedRef<TComponentProps>({
  classes,
  ...props
}: BoxProps<TComponentProps>) {
  // eslint-disable-next-line react/jsx-props-no-spreading, @typescript-eslint/no-explicit-any
  return <BaseBox classes={classes} {...(props as any)} />;
}

/**
 * @deprecated please do not add new code using <Box> - this is a catch-all component that makes maintenance more difficult. Please use `styled.div` whenever possible
 */
// @ts-expect-error fix types
export const Box: typeof BoxWithForwardedRef = forwardRef((props, ref) => (
  <BoxWithForwardedRef
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    forwardedRef={ref}
  />
));
