import { useEffect, useRef } from 'react';
import { useRevalidator } from 'react-router-dom';

import { useUser } from 'zustand-stores/userEnvironmentStore';

export const cmsRouteRevalidation = {
  // After logging in we need to revalidate the CMS route because the contents
  // may change based on whether the user is `recognized`, `unrecognized`, `orgAdmin`, `nonOrgAdmin`.
  force: false,
};

export function useCmsPageRevalidateAfterUserAuthentication() {
  const revalidator = useRevalidator();

  const { user } = useUser();

  const isLoggedIn = Boolean(user);
  const wasLoggedInRef = useRef(isLoggedIn);

  useEffect(() => {
    if (isLoggedIn !== wasLoggedInRef.current) {
      cmsRouteRevalidation.force = true;
      revalidator.revalidate();
      wasLoggedInRef.current = isLoggedIn;
    }
  }, [isLoggedIn, revalidator, wasLoggedInRef]);

  useEffect(() => {
    if (revalidator.state === 'idle') cmsRouteRevalidation.force = false;
  }, [revalidator.state]);
}
