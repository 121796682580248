import { RouteObject } from 'react-router-dom';

import { LayoutHandleProps } from 'layouts/Layout/LayoutHandleProps';
import { CompensationSurveyLoadable } from 'modules/salaries/pages/CompensationSurvey/CompensationSurveyLoadable';
import { SalaryReportLoadable } from 'modules/salaries/pages/SalaryReport/SalaryReportLoadable';
import { loadableLoader } from 'routing/utils/loadableLoader';

import { exploreSalaryRoute, salarySurveyRoute } from './salaries.routes';

export const salariesRouteObjects = [
  {
    path: exploreSalaryRoute.fullPath,
    loader: loadableLoader(SalaryReportLoadable),
    Component: SalaryReportLoadable,
    handle: {
      noAds: true,
      defaultSearchType: 'JOB',
      hideFooterDivider: true,
      showDesktopSearch: true,
    } satisfies LayoutHandleProps,
  },
  {
    handle: { variant: 'base' },
    path: salarySurveyRoute.fullPath,
    loader: loadableLoader(CompensationSurveyLoadable),
    Component: CompensationSurveyLoadable,
  },
] satisfies RouteObject[];
