import { isDefined } from 'utils/functional/type';

export function range(
  start: number,
  stop?: number,
  step?: number,
): Array<number> {
  if (!isDefined(stop)) return range(0, start);

  if (!isDefined(step)) return range(start, stop, stop >= start ? 1 : -1);

  return Array(Math.ceil((stop - start) / step))
    .fill(null)
    .map((_, i) => i * (step || 1) + start);
}
