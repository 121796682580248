import { fontWeights, linkStyles } from 'theme/theme';

export function buttonSize(props: {
  $size?: 'tiny' | 'small' | 'medium' | 'large';
}) {
  switch (props.$size) {
    case 'tiny':
      return {
        fontSize: '12px',
        padding: '0 4px',
      };

    case 'small':
      return {
        fontSize: '14px',
        padding: '0.4em 1.2em',
      };

    case 'large':
      return {
        fontSize: '18px',
        padding: '12px 24px',
      };

    default:
      return {
        fontSize: '16px',
        padding: '8px 16px',
      };
  }
}

export const linkAppearance = linkStyles;

export const buttonAppearance = `
  border-radius: 8px;
  font-weight: ${fontWeights.normal};
  letter-spacing: 0.5px;
  line-height: 1.25;
`;
