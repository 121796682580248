import invariant from 'invariant';

export const metersToMiles = (meters: number) =>
  Math.round(meters * 0.000621371192 * 10) / 10;
export const milesToMeters = (miles: number) => Math.round(miles * 1609.344);

export function getDistanceFromLatLng({
  from,
  to,
}: {
  from: [number, number];
  to: [number, number];
}) {
  invariant(from, 'Missing "from" parameter');
  invariant(to, 'Missing "to" parameter');

  const [fromLatitude, fromLongitude] = from;
  const [toLatitude, toLongitude] = to;

  const earthRadiusInKm = 6371;
  const deg2rad = (deg: number) => deg * (Math.PI / 180);

  const dLat = deg2rad(toLatitude - fromLatitude);
  const dLon = deg2rad(toLongitude - fromLongitude);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(fromLatitude)) *
      Math.cos(deg2rad(toLatitude)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const km = Math.round(earthRadiusInKm * c);
  const miles = metersToMiles(km * 1000);

  return {
    miles,
    km,
  };
}
