import { routeLoadingClassName } from 'theme/routeLoading';

export function startRouteLoadingAnimation() {
  document.body.classList.add(routeLoadingClassName);
}

export function stopRouteLoadingAnimation() {
  document.body.classList.remove(routeLoadingClassName);
}

/**
 * Defer action to later and start showing a loading animation to reduce INP
 */
export function deferAndStartRouteLoadingAnimation(fn: () => void) {
  // Deferring and starting the loading animation can cause an infinite loading animation
  // if used incorrectly.
  //
  // INP isn't as critical on the staff app so let's just disable it.
  if (global.isStaffApp) return fn();

  startRouteLoadingAnimation();

  // Perform action on the next event loop to avoid blocking the main thread before
  // the animation is executed
  setTimeout(() => {
    fn();
  }, 1);
}

export function deferredWithRouteLoadingAnimation(
  fn: () => Promise<void> | void | undefined,
) {
  return () =>
    deferAndStartRouteLoadingAnimation(async () => {
      await fn();
      stopRouteLoadingAnimation();
    });
}
