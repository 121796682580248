import { RouteObject } from 'react-router-dom';

import { LayoutHandleProps } from 'layouts/Layout/LayoutHandleProps';
import { listingApiFetchVolop } from 'modules/listing/api/volop/listingApiFetchVolop';
import { ViewVolopPageLoadable } from 'modules/listing/pages/volops/view/ViewVolopPageLoadable';
import { ViewUnpublishedVolopPageLoadable } from 'modules/listing/pages/volops/viewUnpublished/ViewUnpublishedVolopPageLoadable';
import { NotFoundPageLoadable } from 'pages/NotFound/NotFoundPageLoadable';
import { AppRouteLoaderArgs } from 'routing/classes/types/AppRouteLoaderArgs';
import { renderLoader } from 'routing/utils/renderLoader/renderLoader';
import { getUserEnvironment } from 'zustand-stores/userEnvironmentStore';

import { volopSegmentedRoutes } from './volop.routes';

/**
 * Volop routes
 *
 * These routes are separate in their own file to facilitate maintenance, and
 * they're used on `main.route-objects.tsx`
 */
export const volopRouteObjects = volopSegmentedRoutes.map((segmentedRoute) => ({
  path: segmentedRoute.fullPath,
  ...renderLoader({
    loader: async ({ params }: AppRouteLoaderArgs<typeof segmentedRoute>) => {
      const { volopIdDashSlug = '' } = params;

      const { user } = getUserEnvironment();

      try {
        const [volop] = await Promise.all([
          listingApiFetchVolop(volopIdDashSlug.split('-')[0]),
          // It's possible this loadable won't be used if the volop is unpublished
          // but in most cases optimistically loading it is best
          ViewVolopPageLoadable.load(),
        ]);

        switch (volop.type) {
          case 'VOLOP':
            return {
              type: volop.type,
              volop,
              handle: {
                hideFooterDivider: Boolean(!user),
                urlByLocale: volop.url,
              } satisfies LayoutHandleProps,
            };
          case 'UNPUBLISHED_VOLOP':
            await ViewUnpublishedVolopPageLoadable.load();
            return {
              type: volop.type,
              unpublishedVolop: volop,
            };
        }
      } catch {
        return null;
      }
    },
    render: (data) => {
      switch (data?.type) {
        case undefined:
          return <NotFoundPageLoadable noLayout />;
        case 'VOLOP':
          return <ViewVolopPageLoadable volop={data.volop} />;
        case 'UNPUBLISHED_VOLOP':
          return (
            <ViewUnpublishedVolopPageLoadable
              unpublishedVolop={data.unpublishedVolop}
            />
          );
      }
    },
  }),

  handle: {
    defaultSearchType: 'VOLOP',
    showDesktopSearch: true,
  } satisfies LayoutHandleProps,
})) satisfies RouteObject[];
